import { Button, Space } from "antd";
import { SnippetsOutlined, SettingOutlined } from "@ant-design/icons";
import React from "react";
import './home.css'


const HeaderHome: React.FC = () => {

    return (
        <>
            <div className="titlebox_home">
                <Space >
                    <SettingOutlined />
                    <span>Cài đặt trang chủ</span>
                </Space>
                {/* <Button type="primary" icon={<RedoOutlined />}></Button> */}
            </div>
        </>
    );
};

export default HeaderHome;