
import { signOut } from "../page/auth/authSlice"
import { store } from "../redux/store";
import history from '../history'


const query = async (path : string, method: string, secure: boolean, info :any ) :  Promise<any>=> {
    let headers : HeadersInit  = {
        'Content-Type': 'application/json'
    }
    if (secure) {
        let token = localStorage.getItem('cjwt') || ''
        // console.log("middleware.query: ",path, " append: ", token)
        
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bear ${token}`,
        }
    }
    // console.log("middleware.query: ",path)
    let  options : any  = {
        method: method,
        headers: headers
    } 
    if (method != "GET") {
        options = {
            ...options,
            body: JSON.stringify(info)
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/${path}`,options )
        .then((response) => new Promise(async (resolve, reject) => {
            // console.log("service.request: response = ", response)

            if (response.status == 200) {
                resolve(response.json())
            }
            if (response) {
                response.json().then((responseData: any) => {
                    const { code, message } = responseData
                    if (code == 16) {
                        console.log("Token Error: ", message)
                        // localStorage.removeItem("cjwt");
                        console.log("Call  sigOut ")
                        store.dispatch(signOut({}))
                        history.push("/cms/login");
                    }
                    reject(`${message}`)
                }).catch((ex)=> {
                    reject(`${ex}`)
                })
            } else {
                reject("Noop. Please contact admin")
            }
        }))
        .then((responseData: any) => new Promise((resolve, reject) => {
            console.log("service.request: responseData: ", responseData, typeof (responseData))
            if (!responseData) throw ("empty response")

            const { code, message, data } = responseData
            if (code != 200 && code != 0 ) {
                reject (message)
            }

            console.log("service.request: data: ", data, typeof (data))
            resolve(data) 
        })
        .catch(error => {
            console.log("service.request:  error: ", error)
            throw(error)
        }))
}

export const queryOne = async (path : string, method: string, secure: boolean, info :any) : Promise<any> => {
    return query(path , method, secure, info).then( (response: any) => {
        const { data: items } = response
        if (items && Array.isArray(items)) {
            return items[0]
        }
        throw("Data invalid")
    } ) 
}
export const queryList = async (path : string, method: string, secure: boolean, info :any) : Promise<any> => {
    return query(path , method, secure, info).then( (response: any) => {
        const { data: items } = response
        if (items && Array.isArray(items)) {
            return items
        }
        throw("Data invalid")
    } ) 
}
export default query 