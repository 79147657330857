import { Card, Input, Form, Select, Button, Row, Col, Space, Checkbox, Divider } from "antd";
import React, { useEffect, useState } from "react";
import './news.css'
import { PlusOutlined, FileOutlined, DeleteFilled } from "@ant-design/icons";
import CustomFormItem from "../../../post/comp/item_media";

interface Props { }

const SettingNewsCard: React.FC<Props> = () => {
    const [form] = Form.useForm();

    return (
        <div style={{ width: "100%" }}  >
            <p style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: 'large' }}>Tab 01</p>
            <Divider />
            <Row gutter={24}>
                <Col span={2}>
                    <Form.Item noStyle >
                        <Checkbox>Hiển thị tab</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={4}>
                    <Form.Item noStyle>
                        <CustomFormItem />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tiêu đề (Tiếng Việt)"
                        name="title01"
                        initialValue="Tin tức"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn (Tiếng Việt)"
                        name="link01"
                        initialValue="https://epr.monre.gov.vn/vi/chuyen-muc/tin-tuc.html"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tiêu đề (Tiếng Anh)"
                        name="titleEN01"
                    >
                        <Input placeholder="Nhập tiêu đề placeholder của box" />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn (Tiếng Anh)"
                        name="linkEN01"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <p style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: 'large' }}>Tab 02</p>
            <Divider />
            <Row gutter={24}>
                <Col span={2}>
                    <Form.Item noStyle >
                        <Checkbox>Hiển thị tab</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={4}>
                    <Form.Item noStyle>
                        <CustomFormItem />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tiêu đề (Tiếng Việt)"
                        name="title02"
                        initialValue="Phân tích - Bình luận"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn (Tiếng Việt)"
                        name="link02"
                        initialValue="https://epr.monre.gov.vn/vi/chuyen-muc/phan-tich---binh-luan.html"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tiêu đề (Tiếng Anh)"
                        name="titleEN02"
                    >
                        <Input placeholder="Nhập tiêu đề placeholder của box" />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn (Tiếng Anh)"
                        name="linkEN02"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <p style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: 'large' }}>Tab 03</p>
            <Divider />
            <Row gutter={24}>
                <Col span={2}>
                    <Form.Item noStyle >
                        <Checkbox>Hiển thị tab</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={4}>
                    <Form.Item noStyle>
                        <CustomFormItem />
                        <Button icon={<DeleteFilled />} type="primary" danger> </Button>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tiêu đề (Tiếng Việt)"
                        name="title03"
                        initialValue="Sự kiện"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn (Tiếng Việt)"
                        name="link03"
                        initialValue="https://epr.monre.gov.vn/vi/chuyen-muc/su-kien.html"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Tiêu đề (Tiếng Anh)"
                        name="titleEN03"
                    >
                        <Input placeholder="Nhập tiêu đề placeholder của box" />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn (Tiếng Anh)"
                        name="linkEN03"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </div>
        // </Card>
    );
};

export default SettingNewsCard;