import React, { useEffect, useState } from 'react';
import { Divider, Spin, Table, Alert, Button, Space, Modal, Popconfirm, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs'
import { CloseOutlined, EditOutlined, MessageFilled, EditFilled, DeleteFilled, InfoCircleOutlined } from '@ant-design/icons';
import { IFAQ } from "../../types/faq"
import '../table.css'

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const { Column } = Table

interface Props {
    loadingData: boolean;
    data: any[];
    intialValues: (record: any) => void,
    handleDeleteFeedback: (id: number) => void
    total: number
    pageSize: number
    page: number
    fetchData: (page: number, pageSize: number) => void
}

const columns = (
    confirm: (id: number) => void,
    pre: (record: IFAQ) => void
): ColumnsType<IFAQ> => [
        {
            title: '#',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: 'Câu hỏi',
            dataIndex: 'question',
            width: '50%',
            key: 'question',
        },
        {
            title: 'Ngôn ngữ',
            dataIndex: 'lang',
            width: '5%',
            key: 'lang',
        },
        {
            title: 'Người hỏi',
            dataIndex: 'name',
            key: 'name',
            width: "10%",
        },
        {
            title: 'Thời gian',
            dataIndex: 'approved_time',
            width: '20%',
            key: 'approved_time',
            render: (_: any, record: IFAQ) => (
                <Space size='small' direction='horizontal'>
                    <p>Ngày tạo:{dayjs(Number(record.createdTime) * 1000).format('YYYY-MM-DD')}</p>
                    <p>Ngày cập nhật:{dayjs(Number(record.updatedTime) * 1000).format('YYYY-MM-DD')} </p>
                    <p>Ngày duyệt: {dayjs(Number(record.approvedTime) * 1000).format('YYYY-MM-DD')}</p>

                </Space>
            )
        },
        {
            title: "Trạng thái",
            dataIndex: 'status',
            key: 'status',
            width: "10%",
            render: (_: any, record: IFAQ) => (
                <div>
                    {
                        record.status && "Public"
                    }
                    {
                        !record.status && "Private"
                    }
                </div>
            )
        },
        {
            title: 'Hành động',
            width: '15%',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle" direction="horizontal" wrap align='center'>
                    {/* <Button icon={<CloseOutlined />}>Từ chối</Button> */}
                    <Button icon={<EditOutlined />} onClick={() => pre(record)}></Button>
                    <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                        onConfirm={() => confirm(record.id)}
                        // onCancel={cancel}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button danger icon={<DeleteFilled />}></Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

const TableList: React.FC<Props> = ({
    loadingData,
    data,
    handleDeleteFeedback,
    intialValues,
    total,
    pageSize,
    page,
    fetchData,
}) => {
    const [values, setValues] = useState<Partial<IFAQ>>({});

    const confirm = (id: number) => {
        if (handleDeleteFeedback) {
            console.log("delete", id)
            handleDeleteFeedback(id)
        }
    };

    const pre = (record: IFAQ) => {
        intialValues(record);
        setValues(record);
    };

    return (
        <div>
            {loadingData ? (<Spin size="large">
                <Alert
                    message="Thông báo"
                    description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                    type="info"
                />
            </Spin>) : (
                <>
                    <Divider />
                    <Table
                        dataSource={data}
                        columns={columns(confirm, pre)}
                        rowKey="id"
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                        pagination={{
                            total: total,
                            onChange: (page, pageSize) => {
                                fetchData(page, pageSize);
                            },
                            current: page,
                            pageSize: pageSize,
                            showSizeChanger: true,

                            // defaultPageSize: 10,
                            // pageSizeOptions: ['10', '20', '30']
                        }}
                    />
                </>
            )}

        </div >
    );
};

export default TableList;
