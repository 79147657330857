import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import HeaderAds from "./headerAds";
import SettingAdsCard from './formAds'
import './ads.css'
const SettingAds: React.FC = () => {

    return (
        <>
            <div className="ads_wrapper_setting">
                <div className="info_ads_setting" >
                    <HeaderAds />
                    <br></br>
                    <Card title="Banner chi tiết tin tức" headStyle={{ textAlign: "left" }}>
                        <Form>
                            <SettingAdsCard />
                        </Form>
                    </Card>

                </div>
            </div>
        </>
    );
};

export default SettingAds;