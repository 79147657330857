import React, { useEffect, useState } from "react";
import { Modal, Button, Form, message } from 'antd';
import { CopyFilled } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from "../../redux/store";

import { notification } from "antd";
import TableList from './table';
import Header from './header'
import './category.css'
import { list, create, del, update } from './categorySlice'
import dayjs from 'dayjs'
import { Array2Tree, treeOption } from "../../util/helper"
import * as XLSX from 'xlsx'
import FormCategory from "./form"
import FormUpdateCategory from "./formUpdate";

interface DataType {
    id: number;
    parentId: number;
    slug: string;
    name: string;
    nameEn: string;
    position: number;
    blocked: boolean;
    lang: string;
    postCount: number;
    deleted: boolean;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}

interface Props {

}

const Category: React.FC<Props> = ({ }) => {
    const dispatch = useAppDispatch()
    const category = useAppSelector(state => state.category)
    const [api, contextHolder] = notification.useNotification()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<DataType | null>(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    var now = dayjs()
    var nowExport = dayjs().format("DD/MM?YYYY")
    const [word, setWord] = useState<string>("");
    const [data, setData] = useState<any[]>([]);

    const [options, setOptions] = useState<any[]>([]);
    const [loadingData, setLoadingData] = useState(false);

    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm()


    useEffect(() => {
        form.setFieldValue("parentId", "0")
        fetchData(page, pageSize)
    }, [word]);

    useEffect(() => {
        if (category?.items && category?.items.length > 0) {
            console.log("category: items = ", category?.items)


            const data = Array2Tree(category?.items)

            if (category?.pagination?.total) {
                setTotal(category?.pagination?.total)
                setPage(category?.pagination?.page)
                setPageSize(category?.pagination?.size)

            } else {
                setTotal(data.length)
            }

            setData(data);
            const options = data.map((item) => treeOption(item))
            setOptions([{
                key: "0",
                value: "0",
                title: "---",
            }, ...options])

        }
    }, [category]);

    useEffect(() => {
        if (selectedItem) {
            let { parentId, name, nameEn, slug, id, updatedTime } = selectedItem;
            formUpdate.setFieldsValue({ parentId, name, nameEn, slug, id, updatedTime });
        }
    }, [selectedItem]);

    const fetchData = (page: number, pageSize: number) => {
        let pagination = {
            page: page,
            size: pageSize,
            word: { word }
        }
        setLoadingData(true);
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false);
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Lấy dữ liệu thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
    }

    const closeModalUpdate = () => {
        setIsModalUpdateOpen(false)
        formUpdate.resetFields()
    }

    const onRefresh = (value: any) => {
        console.log("Refresh data")
        fetchData(page, pageSize)
    }
    const onNew = (value: any) => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        form.resetFields();
    }

    const handleSubmitCategory = async (values: any) => {
        const formValuesCategory = {
            id: values.id,
            parentId: values.parentId,
            slug: values.slug,
            name: values.name,
            nameEn: values.nameEn,
            position: values.position,
            blocked: values.blocked,
            lang: values.lang,
            postCount: values.postCount,
            deleted: values.deleted,
            createdBy: values.createdBy,
            updatedBy: values.updatedBy,
            createdTime: now.unix(),
            updatedTime: now.unix(),
        };
        dispatch(create(formValuesCategory)).unwrap().then(() => {
            api.open({
                message: 'Create successfully',
                description: 'Create Category successfully',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Create failed',
                description: `Create Category failed, error: ${error}`,
                duration: 0,
                type: 'error'
            })
        })
        closeModal()
    };

    const handleSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setWord(values)
    };

    const initialValues = (record: DataType) => {
        setSelectedItem(record);
        setIsModalUpdateOpen(true);
    };

    const handleDelete = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap().then(() => {
            api.open({
                message: 'Thành công',
                description: 'Xóa thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Xóa thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
    };

    const handleSave = () => {
        if (selectedItem) {
            formUpdate.validateFields()
                .then((values) => {
                    const updatedRecord = { ...selectedItem, ...values };
                    dispatch(update(updatedRecord)).unwrap().then(() => {
                        api.open({
                            message: 'Thành công',
                            description: 'Cập nhật thành công',
                            duration: 0,
                            type: 'success'
                        })
                        closeModalUpdate()
                        fetchData(page, pageSize);
                    }).catch((error) => {
                        api.open({
                            message: 'Thất bại',
                            description: `Cập nhật thất bại: ${error}`,
                            duration: 0,
                            type: 'error'
                        })
                        closeModalUpdate()
                    });
                })
        }
    };
    const getMaxWidth = (property: keyof DataType, rows: DataType[], defaultValue = 10): number => {
        return rows.reduce((maxWidth, row) => Math.max(maxWidth, String(row[property]).length), defaultValue);
    };

    const onExport = () => {
        const rows = data.map(row => ({
            id: row.id,
            name: row.name,
            nameEn: row.nameEn,
            slug: row.slug,
            postCount: row.postCount,
        }));
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data')
        const header = ["ID", "Tên chuyên mục", "Tên chuyên mục tiếng Anh", "Đường dẫn", "Số bài viết"]
        XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' })
        if (!ws["!cols"]) ws["!cols"] = [];
        ws["!cols"][1] = { wch: getMaxWidth('name', data) }
        ws["!cols"][2] = { wch: getMaxWidth('nameEn', data) }
        ws["!cols"][3] = { wch: getMaxWidth('slug', data) }
        ws["!cols"][4] = { wch: getMaxWidth('postCount', data) }

        XLSX.writeFile(wb, `Category ${nowExport}.xlsx`)
    }
    const onCopySlugCategory = (slug: any) => {
        const link = `https://epr.monre.gov.vn/vi/bai-viet/chi-tiet/${slug}.html`
        navigator.clipboard.writeText(link)
            .then(() => {
                message.success(`Đã copy ${link}`);
            })
            .catch((error) => {
                console.error('Sao chép thất bại: ', error);
                message.error('Sao chép thất bại');
            });
    };
    return (
        <div className="wrapper">
            {contextHolder}
            <div className="titleBoxCategory">
                <Header
                    onSearch={handleSearch}
                    onNew={onNew}
                    onRefresh={onRefresh}
                    onExport={onExport}
                />
            </div>
            <div className="main-content">
                <div className="title">
                    <span>Danh sách {total} chuyên mục cho bài viết</span>
                </div>
                <div className="data">
                    <TableList
                        page={page}
                        pageSize={pageSize}
                        total={total}
                        fetchData={fetchData}
                        data={data}
                        loadingData={loadingData}
                        handleDelete={handleDelete}
                        initialValues={initialValues}
                        onCopySlugCategory={onCopySlugCategory} />
                </div>
            </div>
            <Modal title="Thêm mới"
                open={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button key="close" onClick={closeModal} >Đóng</Button>,
                    <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />}
                        onClick={form.submit}>Duyệt</Button>
                ]}
                mask={false}
                width={1000}
            >
                <FormCategory form={form} option_category={options} handleSubmitCategory={handleSubmitCategory} />
            </Modal >
            <Modal
                title="Chỉnh sửa"
                open={isModalUpdateOpen}
                onCancel={() => { setIsModalUpdateOpen(false) }}
                width={1000}
                footer={[
                    <Button key="close" onClick={() => { setIsModalUpdateOpen(false) }}>
                        Đóng
                    </Button>,
                    <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />} onClick={formUpdate.submit}>Lưu lại</Button>

                ]}
            >
                <FormUpdateCategory form={formUpdate} initialValues={initialValues} handleUpdate={handleSave} />
            </Modal>
        </div>
    )
}

export default Category
