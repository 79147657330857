import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Form, Input, FormInstance } from 'antd';

import TextArea from "antd/es/input/TextArea";
import { slugify } from "../../../util/helper";
import Editor from "./editor";

interface FillInSectionProps {
    form?: FormInstance<any>
    lang?: string
}

const FillInSection: React.FC<FillInSectionProps> = ({ lang, form }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });

    const onChangeTitle = () => {
        let title = form?.getFieldValue("title")
        if (title) {
            let slug = slugify(title)
            form?.setFieldValue("slug", slug)
        }
    }
    return (
        <div className="form-post" >
            <Form.Item
                className="vertical-item"
                label={t('title')}
                name='title'
                rules={[{ required: true, message: t('mesTitle') }]}
            >
                <Input onChange={onChangeTitle} />
            </Form.Item>
            <Form.Item
                className="vertical-item"
                label={t('slug')}
                name='slug'
                help={t('helpSlug')}
                rules={[{ required: true, message: t('mesSlug') }]}    >
                <Input />
            </Form.Item>
            <Form.Item
                className="vertical-item"
                label={t('des')}
                name='excerpt'
                help={t('helpDes')}
            >
                <TextArea />
            </Form.Item>
            <Form.Item
                className="vertical-item"
                label={t('contentPost')}
                name='content'
                rules={[{ required: true, message: t('mesContent') }]}>
                <Editor />
            </Form.Item>
        </div >


    )
}

export default FillInSection;