import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

import { Space, Tree, Divider } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';

type Props = {
    options: any[]
    value?: string[]
    onChange?: (value: string[]) => void
};

const CategoryFormItem: React.FC<Props> = (props: any) => {
    const { options, value, onChange } = props
    // const [checkedKeys, setCheckedKeys] = useState<any[]>([]);

    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });

    // console.log('CustomFormItem', value);

    // useEffect(() => {
    //     console.log("options: ", options)
    // }, [value])

    const onCheck: TreeProps['onCheck'] = (keys, info) => {
        // console.log('onCheck', keys, info);
        if (Array.isArray(keys)) {
            console.log("Select keys: ", keys)
            // setCheckedKeys(keys)
        }
        const { checkedNodesPositions } = info
        // console.log('checkedNodesPositions', checkedNodesPositions);
        let values = checkedNodesPositions?.map((item: any) => {
            const { node, pos } = item
            return node?.value
        })

        if (typeof onChange === 'function') {
            if (values) {
                onChange(values);
            }
        }
    };

    return <Tree
        checkable
        // onSelect={onSelect}
        checkedKeys={value}
        onCheck={onCheck}
        treeData={options}
        defaultExpandAll={true} />;
}

export default CategoryFormItem