import React from "react";
import HeaderDetailDoc from "./headerDetailDoc";
import TableDetailDoc from "./tableDetailDoc";

interface FormUpdateDetailDoc {
    loadingData: boolean;
    data: any[];
    onNew?: () => void;
    onSearch?: (values: any) => void;
    onRefresh?: (values: any) => void
}

const FormUpdateDetailDoc: React.FC<FormUpdateDetailDoc> = ({ loadingData, data, onNew, onSearch, onRefresh }) => {
    return (
        <>

            <HeaderDetailDoc onNew={onNew} />
            <TableDetailDoc data={data} loadingData={loadingData} />
        </>
    )
}

export default FormUpdateDetailDoc