import React, { useState, useEffect } from "react";
import { Form } from 'antd';
import dayjs from "dayjs";

import { useTranslation } from 'react-i18next'

import { Button, message, Space } from 'antd';

import FormContent from "../comp/content";
import FormKeyword from "../comp/keyword";
import FormOption from "../comp/option";
import FormThumbNails from "../comp/thumbnails";
import FormCategories from "../comp/categories";
import { sanitizeFormValue } from "../../../util/helper";

const dataFormat = "DD/MM/YYYY HH:mm"

const FormArticle = (props: any) => {
    const { lang, opt_keyword, opt_keywordEn } = props
    const [messageApi, contextHolder] = message.useMessage();

    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });
    const [form] = Form.useForm();


    const { handleSave, handlePublish, options } = props

    useEffect(() => {
        let defaultTime = dayjs()
        form.setFieldValue("publicDate", defaultTime)
        form.setFieldValue("finishDate", defaultTime)
        form.setFieldValue("showOnBanner", "0")
        form.setFieldValue("commentStatus", "private")
        form.setFieldValue("status", "private")

    }, [])

    const onSave = () => {
        if (handleSave) {
            console.log("[log form checked]", form.getFieldValue("showOnBanner"))
            handleSave(sanitizeFormValue(form.getFieldsValue()))
        }
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
        const { errorFields } = errorInfo
        errorFields.map((item: any) => {
            const { name, errors } = item
            if (name[0] && errors[0]) {
                let msg = `Field ${name[0]} error: ${errors[0]}`
                messageApi.open({
                    type: 'error',
                    content: msg,
                });
            }
        })
    };

    const handleSubmit = (values: any) => {
        if (handlePublish) {
            handlePublish(sanitizeFormValue(form.getFieldsValue()))
        }
    }
    const onNew = () => {
        form.resetFields()
    }
    return (
        <Form
            className="form-wrapper"
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}

        >
            {contextHolder}

            <div className="left-side">
                <FormContent
                    lang={lang}
                    form={form}
                />
                <br></br>
                <FormKeyword
                    lang={lang}
                    form={form}
                    opt_keyword={opt_keyword}
                />
            </div>
            <div className="right-side">
                <div className="option-wrapper" >
                    <FormOption lang={lang} form={form} />
                    <Space className="button" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onNew}>{t('new')}</Button>
                        <Button onClick={onSave}>{t('save')}</Button>
                        <Button type="primary" htmlType="submit" > {t('publish')}</Button>
                    </Space>
                </div>
                <br />
                <FormThumbNails lang={lang} form={form} />
                <br />
                <FormCategories lang={lang} options={options} form={form} />
            </div>
        </Form>
    )
}
export default FormArticle 