import { Card, Input, Form, Select, Button, Row, Col, Space } from "antd";
import React, { useEffect, useState } from "react";
import './home.css'
import { PlusOutlined, FileOutlined } from "@ant-design/icons";
import TableHomeBoxNews from './tableBoxNews'


interface Props {

}


const SettingBannerCard: React.FC = () => {
    const [form] = Form.useForm();

    return (
        <Card
            title="Box tin trang chủ"
            className="content_home"
            actions={[<Button type="primary" icon={<FileOutlined />}>Lưu lại</Button>]}
            headStyle={{ textAlign: "left" }}
        >
            <p style={{ textAlign: 'left', fontWeight: 'lighter', fontSize: 'small' }}>(Lưu ý: Cần nhấn nút "Lưu lại" để lưu các cài đặt bạn đã thêm)</p>

            <Form
                style={{ width: '100%' }}
                form={form}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Tiêu đề box (Tiếng Việt)"
                            name="box_title"
                            help="Được sử dụng hiển thị trên giao diện trang chủ khi chọn ngôn ngữ tiếng Việt"
                            initialValue='KÊ KHAI, NỘP TIỀN XỬ LÝ CHẤT THẢI'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Tiêu đề box (Tiếng Anh)"
                            name="box_titleEN"
                            help=" Được sử dụng hiển thị trên giao diện trang chủ khi chọn ngôn ngữ tiếng Anh"
                        >
                            <Input placeholder="Nhập tiêu đề placeholder của box" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Link video youtube (Tiếng Việt)"
                            name="link"
                            help="Được sử dụng hiển thị trên giao diện trang chủ khi chọn ngôn ngữ tiếng Việt"
                            initialValue='https://www.youtube.com/embed/UuvivNB6kMk'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Link video youtube (Tiếng Anh)"
                            name="linkEn"
                            help=" Được sử dụng hiển thị trên giao diện trang chủ khi chọn ngôn ngữ tiếng Anh"
                        >
                            <Input placeholder="Link video youtube" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Danh sách bài viết (Tiếng Việt)"
                            name="post"
                        >
                            <Select placeholder="Chọn bài viết" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Danh sách bài viết (Tiếng Anh)"
                            name="postEn"
                        >
                            <Select placeholder="Chọn bài viết" />

                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <TableHomeBoxNews />
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default SettingBannerCard;