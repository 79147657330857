import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Space, Divider, Form } from 'antd';
import ModalSection from "../../component/modal_section";
import { useMediaContext } from "../../hooks/useMediaContext";
import { FormInstance } from 'antd/lib/form';

type CustomFormItemProps = {
    value?: any;
    onChange?: (value: any) => void;
};

export const CustomFormItem: React.FC<CustomFormItemProps> = ({ value, onChange }) => {
    const { files } = useMediaContext();
    const {
        selectedFile,
        setSelectedFile
    } = useMediaContext()

    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(selectedFile);
        }
    }, [selectedFile, onChange]);

    const findUrlbyThumbnail = (id: number) => {
        const findUrl = files.find((file) => file.id === id);
        if (findUrl) {
            return findUrl?.url;
        }
    };

    let valueUrl = findUrlbyThumbnail(parseInt(value, 10));

    const handleSelectedImage = (file: any) => {
        setSelectedFile(file?.id);
        if (typeof onChange === 'function') {
            onChange(file.url);
        }
    };

    return (
        <>
            <div>
                <ModalSection handleSelectedImage={handleSelectedImage} value={valueUrl} />
            </div>
        </>
    );
};

interface MediaSectionProps {
    form?: FormInstance<any>;
    lang?: string;
}

const MediaSection: React.FC<MediaSectionProps> = ({ form, lang }) => {
    let value = form?.getFieldValue('fileId')
    const [thumbnailId, setThumbnailId] = useState<any>(value);
    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });
    const {
        selectedFile,
    } = useMediaContext()

    useEffect(() => {
        setThumbnailId(value)
    }, [value])

    useEffect(() => {
        form?.setFieldValue("fileId", selectedFile)
        console.log("[selectedFile]", selectedFile)
    }, [selectedFile])

    return (
        <div className="option-wrapper" >
            <Form.Item
                name='fileId'
                className="post-option"
                initialValue={selectedFile}
            >
                <Divider />
                <CustomFormItem
                    value={thumbnailId}
                />
            </Form.Item>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            </Space>
        </div>
    );
};

export default MediaSection;
