import React from "react";
import { Input, Form, InputNumber } from 'antd';
import './category.css'
import { slugify } from "../../util/helper";


interface UpdateCategory {
    form: any,
    handleUpdate: (values: any) => void
    initialValues: any
}

const FormUpdateCategory: React.FC<UpdateCategory> = (props) => {
    const { form, handleUpdate, initialValues } = props
    const onFinish = (values: any) => {
        if (handleUpdate) {
            handleUpdate(values)
        }
    }

    const onChangeName = (e: any) => {
        let slug = slugify(e.target.value)
        form.setFieldValue("slug", slug)
    }

    const { TextArea } = Input
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={initialValues}
        >
            <Form.Item
                name='parentId'
                label="Chuyên mục cha"
                help="Chuyên mục khác với thẻ, bạn có thể sử dụng nhiều cấp chuyên mục. Ví dụ: Trong chuyên mục nhạc, bạn có chuyên mục con là nhạc Pop, nhạc Jazz. Việc này hoàn toàn là tùy theo ý bạn. Hỗ trợ tối đa 3 cấp cha-con">
                <Input disabled />
            </Form.Item>
            <Form.Item
                name='name'
                label="Tên chuyên mục"
                rules={[{ required: true, message: 'Tên chuyên mục không được để trống.' }]}
                help="Tên riêng sẽ hiển thị trên website">
                <Input onChange={onChangeName} />
            </Form.Item>
            <Form.Item
                name='nameEn'
                label="Tên chuyên mục tiếng Anh"
                rules={[{ required: true, message: 'Tên chuyên mục không được để trống.' }]}
                help="Tên riêng sẽ hiển thị trên website">
                <Input />
            </Form.Item>
            <Form.Item
                name='slug'
                label="Đường dẫn "
                rules={[{ required: true, message: 'Đường dẫn không được để trống.' }]}
                help="Chuỗi cho đường dẫn tĩnh là phiên bản của tên hợp chuẩn với Đường dẫn (URL). Chuỗi này bao gồm chữ cái thường, số và dấu gạch ngang (-).">
                <Input />
            </Form.Item>
            <Form.Item
                label="Thứ tự"
                name='id'>
                <InputNumber style={{ width: '500px' }} />
            </Form.Item>
            {/* <Form.Item
                label="Mô tả"
                name='description'
                help="Mô tả không được sử dụng trên website">
                <TextArea style={{ width: '500px' }} />
            </Form.Item> */}
        </Form>
    )
}

export default FormUpdateCategory 