import {IVideo} from "../types/video"

import fetch, {queryOne,  queryList } from "./request"

export const List = async (props:any): Promise<any> => {
    const {page, size} = props
    console.log("Video list: ", props )
    return fetch(`api/doc-video?page=${page}&size=${size}`, 'GET', true, {})
}


export const Create = async (info:any): Promise<IVideo> => {
    return queryOne(`api/doc-video` , 'POST',true, info)
}

export const Filter = async (info: any): Promise<any> => {

    await new Promise((resolve) => setTimeout(resolve, 100));

    const responseData = {
        code: '200',
        message: 'Success',
        data: [
            {
                id: 5,
                userName: ' Ngo van hieu',
                userPhone: 376017229,
                userEmail: 'hieungo13../../gmail.com',
                content: 'This is fake data for API filter',
            },
            {
                id: 6,
                userName: ' Ngo van hieu',
                userPhone: 376017229,
                userEmail: 'hieungo13../../gmail.com',
                content: 'This is fake data for API filter',
            },
            {
                id: 7,
                userName: ' Ngo van hieu',
                userPhone: 376017229,
                userEmail: 'hieungo13../../gmail.com',
                content: 'This is fake data for API filter',
            },
        ],

    };

    if (responseData.code === '200') {
        return responseData.data;
    } else {
        throw new Error(responseData.message);
    }
};

export const Delete = async (info: any): Promise<IVideo> => {
    return queryOne(`api/doc-video`,'DELETE',true, info)
}

export const Update = async (info: any): Promise<IVideo> => {
    return queryOne(`api/doc-video` , 'PUT', true, info)
}

