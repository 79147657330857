import React, { useState } from "react";
import { Input, Form, Typography, Divider, Checkbox } from 'antd';
import './comment.css'


interface UpdateVideo {
    form?: any,
}
const { Title } = Typography
const { TextArea } = Input
const FormRepComment: React.FC<UpdateVideo> = (props) => {
    const { form } = props
    const [componentDisabled, setComponentDisabled] = useState<boolean>(true);

    return (
        <>
            <Title level={4}>Thêm mới</Title>
            <Divider />
            <Checkbox
                checked={componentDisabled}
                onChange={(e) => setComponentDisabled(e.target.checked)}
            >
                Admin trả lời
            </Checkbox>
            <Form
                layout="vertical"
                disabled={componentDisabled}
                style={{ width: '100%' }}
                form={form}
            >
                <Form.Item
                    name="userName"
                    label="Họ và tên "
                    rules={[{ required: true }]}
                    initialValue='Admin'>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="userPhone"
                    label="Số điện thoại"
                    rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="userEmail"
                    label="Email"
                    rules={[{ required: true }]}
                    initialValue='epr../../monre.gov.vn'
                >
                    <Input />
                </Form.Item>
            </Form>
            <Form
                layout="vertical"
                disabled
                form={form}
            >
                <Form.Item
                    name="content"
                    label="Bình luận gốc"
                >
                    <TextArea disabled autoSize={{ minRows: 2, maxRows: 6 }} />
                </Form.Item>
            </Form>
            <Form
                layout="vertical"
            >
                <Form.Item
                    name="contentRep"
                    label="Nội dung bình luận "
                    rules={[{ required: true, message: 'Nội dung bình luận không được để trống.' }]}
                >
                    <TextArea />
                </Form.Item>
            </Form>
        </>
    );
}

export default FormRepComment 