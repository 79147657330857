import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Settings_layout from "../../siderSetting";
import TableSystem from './table'
import HeaderGeneral from "./headerGeneral";
import './generalSetting.css'
import { Header } from "antd/es/layout/layout";


const SettingGeneral: React.FC = () => {

    return (
        <>
            <div className="general_wrapper_setting">
                {/* <Settings_layout /> */}
                <div className="info-general_setting">
                    <HeaderGeneral />
                    <div className="content_general">
                        <div className="title-content">
                            <span style={{ fontSize: "large" }}>Danh sách 32 tham số hệ thống</span>
                            <Divider />
                        </div>
                        <TableSystem />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingGeneral;