import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Space, Input } from 'antd';
import { CopyFilled } from '@ant-design/icons';

import Header from "./header";
import Table from "./table";
import { list, create, del, importComment, get_target_id } from './commentSlice'
import { list as listPost } from '../post/postSlice'
import './comment.css'
import * as XLSX from 'xlsx'

import FormComment from "./form";
import FormPreviewComment from "./formPreview";
import FormAnswer from "./formAdminReply";
import FormRepComment from "./modalRepComment";
import FormDeny from "./formDeny";
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { notification } from "antd";
import { ArrayPost2Title } from "../../util/helper";
import { IComment as DataTypeComment } from "../../types/comment";
import { error } from "console";
interface Props {
    style?: React.CSSProperties;
    targetIdFromPost?: any

}

const Comment: React.FC<Props> = ({ style, targetIdFromPost }) => {
    const [api, contextHolder] = notification.useNotification()
    const dispatch = useAppDispatch()

    const comment = useAppSelector(state => state.comment)
    const commentOfPost = useAppSelector(state => state.post)
    const [data, setData] = useState<any[]>([]);

    const [optionTitle, setOptionTitle] = useState<any[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalDenyOpen, setIsModalDenyOpen] = useState(false)
    const [isModalPreOpen, setIsModalPreOpen] = useState(false)
    const [isModalRepCmt, setIsModalRepCmt] = useState(false)

    const [loadingData, setLoadingData] = useState(false);
    const [isModalReply, setIsModalReply] = useState(false);
    const [selectedItem, setSelectedItem] = useState<DataTypeComment | null>(null);
    const [word, setWord] = useState<string>("");

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    const now = dayjs();
    const nowExport = dayjs().format("DD/MM/YYYY")
    const [form] = Form.useForm()
    const [formPre] = Form.useForm()
    const [formRep] = Form.useForm()
    const [formDeny] = Form.useForm()

    const postItems = commentOfPost.items

    useEffect(() => {
        if (commentOfPost.status != "succeeded") {
            setLoadingData(true)
            const pagination = {
                page: 0,
                size: 100,
                word: word,
            };
            dispatch(listPost(pagination)).unwrap().then(() => {
                fetchData(page, pageSize)
                setLoadingData(false)
            }
            );
        } else {
            fetchData(page, pageSize)
        }
    }, [word]);

    const findTitleByTargetId = (targetId: number) => {
        const findPost = postItems?.find((postItems) => postItems.id === targetId)
        return findPost?.title
    }

    useEffect(() => {
        const commentItems = comment?.items;
        if (commentItems) {
            const tranferCommentItems = commentItems.map(item => ({
                ...item,
                titleByTargetId: findTitleByTargetId(item.targetId)
            }))

            if (comment?.pagination?.total) {
                setTotal(comment?.pagination?.total)
                setPage(comment?.pagination?.page)
                setPageSize(comment?.pagination?.size)

            } else {
                setTotal(tranferCommentItems.length)
            }

            setData(tranferCommentItems);
        }
    }, [comment?.items]);


    useEffect(() => {
        if (selectedItem) {
            let { userName, userPhone, userEmail, content, titleByTargetId } = selectedItem;
            formPre.setFieldsValue({ userName, userPhone, titleByTargetId, userEmail, content });
            formRep.setFieldsValue({ content })
        }
    }, [selectedItem]);

    useEffect(() => {
        if (commentOfPost?.items) {
            const optionPost = ArrayPost2Title(commentOfPost?.items)
            setOptionTitle(optionPost)
        }
    }, [commentOfPost?.items])

    const fetchData = (page: number, pageSize: number) => {
        setLoadingData(true);
        const pagination = {
            page: page,
            size: pageSize,
            word: { word },
        };
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false);
        }).catch((error) => {
            console.error("[Error] tableList: ", error);
            setLoadingData(false);
        })
    }

    const handleSubmitComment = async (values: any) => {
        const formValuesComment = {
            id: values.id,
            userName: values.userName,
            userPhone: values.userPhone,
            userEmail: values.userEmail,
            targetId: values.targetId,
            content: values.content,
            canelTime: now.unix(),
            approvedTime: now.unix(),
            createdTime: now.unix(),
            updatedTime: now.unix(),
        };
        setLoadingData(true);
        dispatch(create(formValuesComment)).unwrap().then(() => {
            setLoadingData(false)
            api.open({
                message: 'Thành công',
                description: 'Tạo thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Tạo thất bại: ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
        console.log('[data push]', formValuesComment)
        closeModal()
    };

    const getCmtByTargetId = async (targetIdFromPost: any) => {

        setLoadingData(true);
        try {
            const datacmt = await dispatch(get_target_id(targetIdFromPost));
            setData(datacmt.payload);
            setLoadingData(false);
        } catch (error) {
            console.log('[Error CMT table]', error, targetIdFromPost);
        }
    };

    const handleDeleteComment = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap().then(() => {
            api.open({
                message: 'Thành công',
                description: 'Xóa thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Xóa thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
    };

    const onRefresh = () => {
        // console.log("Refresh data")
        fetchData(page, pageSize)
    }

    const handleSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setWord(values)
    };

    const onNew = (value: any) => {
        setIsModalOpen(true)
    }

    const onNewRep = (value: any) => {
        setIsModalRepCmt(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        form.resetFields();
    }
    const onNewModalDeny = (id: number) => {
        setIsModalDenyOpen(true)
    }

    const onAdminReply = (record: DataTypeComment) => {
        setSelectedItem(record)
        setIsModalReply(true)
    }
    const initialValues = (record: DataTypeComment) => {
        setSelectedItem(record);
        setIsModalPreOpen(true);
    };

    const getMaxWidth = (property: keyof DataTypeComment, rows: DataTypeComment[], defaultValue = 10): number =>
        rows.reduce((maxWidth, row) => Math.max(maxWidth, String(row[property]).length), defaultValue);

    const header: (keyof DataTypeComment)[] = ["id", "type", "targetId", "parentId", "content", "cancelTime", "approvedTime", "cancelNote",
        "userName", "userId", "userEmail", "userPhone", "adminReply", "deleted", "updatedBy", "createdTime", "updatedTime"];

    const exportData = () => {

        const rows: DataTypeComment[] = data.map(row =>
            header.reduce((acc: any, key) => {
                acc[key] = row[key];
                return acc;
            }, {} as DataTypeComment)
        );

        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');

        XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });

        if (!ws["!cols"]) {
            ws["!cols"] = [];
        }

        header.forEach((property, index) => {
            if (ws["!cols"]) {
                ws["!cols"][index] = { wch: getMaxWidth(property, rows) };
            }
        });

        XLSX.writeFile(wb, `Video ${nowExport}.xlsx`);
    };

    const arraysEqual = (a: any[], b: any[]): boolean => {
        return JSON.stringify(a) === JSON.stringify(b);
    };


    const importData = (file: File) => {

        const reader = new FileReader();
        reader.onload = (e: any) => {
            const dataFromXLSX = new Uint8Array(e.target.result);
            const wb = XLSX.read(dataFromXLSX, { type: 'array' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const jsonData: any[][] = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const headerOfXLSX = jsonData[0]
            const convertExcelDataToDataTypeComment = (excelData: any[][]): any[] => {
                const arrayOfObjects = excelData.slice(1).map(array => {
                    return array.reduce((obj: any, value, index) => {
                        obj[header[index]] = value;
                        return obj;
                    }, {});
                });

                return arrayOfObjects;
            };

            if (!arraysEqual(header, headerOfXLSX)) {
                api.open({
                    message: 'Import failed',
                    type: 'error',
                    description: `Excel Import ${file.name} is not in the correct format`
                })
                console.log('falied', headerOfXLSX)
                return data
            } else {
                const dataImport = convertExcelDataToDataTypeComment(jsonData);
                dispatch(importComment(dataImport))
                api.open({
                    message: "Import success",
                    type: 'success'
                })
                console.log('success', headerOfXLSX)

            }
        };
        reader.readAsArrayBuffer(file)
    };
    const onImport = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            importData(file);
        }
    };

    return (
        <div className="wrapper" style={style}>
            {contextHolder}
            <div className="titleBox">
                <Header
                    onNew={onNew}
                    onSearch={handleSearch}
                    onRefresh={onRefresh}
                    onExport={exportData}
                    onImport={onImport}
                    total={total}
                    awaitAccept={total}
                />
            </div>
            <div className="main-content">
                <div className="title">
                    <span>Danh sách {total} bình luận</span>
                </div>
                <div className="data">
                    <Table
                        page={page}
                        pageSize={pageSize}
                        total={total}
                        fetchData={fetchData}
                        data={data}
                        loadingData={loadingData}
                        handleDeleteComment={handleDeleteComment}
                        onNew={onNewModalDeny}
                        initialValues={initialValues}
                        onReply={onAdminReply}
                    />
                </div>
            </div>
            <Modal title="Thêm mới"
                open={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button key="close" onClick={closeModal} >Đóng</Button>,
                    <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />}
                        onClick={form.submit}>Duyệt</Button>
                ]}
                mask={false}
                width={1000}
            >
                <FormComment form={form} handleSubmitComment={handleSubmitComment} optionTitle={optionTitle} />
            </Modal >
            <Modal
                title="Từ chối "
                open={isModalDenyOpen}
                onCancel={() => setIsModalDenyOpen(false)}
                footer={[
                    <Button key="close" onClick={() => setIsModalDenyOpen(false)}>OK</Button>,
                    <Button key="submit" htmlType="submit" type="primary" onClick={() => setIsModalDenyOpen(false)}>Cancel</Button>
                ]}
            >

                <FormDeny form={formDeny} />
            </Modal>
            <Modal
                title="Duyệt"
                open={isModalPreOpen}
                onCancel={() => setIsModalPreOpen(false)}
                width={1000}
                footer={[
                    <Button key="close" onClick={() => setIsModalPreOpen(false)}>
                        Đóng
                    </Button>,
                    <Button key="submit" htmlType="submit" type="primary" onClick={() => setIsModalPreOpen(false)}>
                        OK
                    </Button>
                ]}
            >
                <FormPreviewComment form={formPre} />
            </Modal>
            <Modal
                title="Trả lời "
                open={isModalReply}
                onCancel={() => setIsModalReply(false)}
                width={1000}
                footer={[
                    <Button key="close" onClick={() => setIsModalReply(false)}>
                        Đóng
                    </Button>,
                    <Button key="submit" onClick={() => setIsModalReply(false)}>
                        OK
                    </Button>
                ]}>
                <FormAnswer form={formRep} onNew={onNewRep} onRefresh={onRefresh} onSearch={handleSearch} />
                <Modal
                    open={isModalRepCmt}
                    onCancel={() => setIsModalRepCmt(false)}
                    width={1000}
                    footer={[
                        <Button key="close" onClick={() => setIsModalRepCmt(false)}>
                            Đóng
                        </Button>,
                        <Button key="submit" htmlType="submit" type="primary" onClick={() => setIsModalRepCmt(false)}>
                            OK
                        </Button>
                    ]}>
                    <FormRepComment form={formRep} />
                </Modal>
            </Modal>


        </div>
    );
};

export default Comment;