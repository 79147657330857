
import React, { useEffect, useState } from "react";
import { MediaProvider, useMediaContext } from "../../hooks/useMediaContext";
import { Button, Divider, Modal, Row, Image, Space } from "antd";
import type { DataNode } from 'antd/es/tree';
import type { UploadFile } from 'antd/es/upload/interface';

import FolderList from "../../component/modal_section/FolderList";
import ImageList from "../../component/modal_section/ImageList";
import DetailsInfomation from "../../component/modal_section/DetailsInfomation";
import { convertData } from "../../component/modal_section/helper"

import { File, Folder } from "../../types/folder";

interface Props {
    multiple?: boolean
    crop?: boolean
}


const MediaList: React.FC<Props> = (props: Props) => {
    const { multiple, crop } = props;
    const {
        loading,
        data,
        loadData,
        files,
        uploadFile,
        selectedFile,
        setSelectedFile,
        createFolder,
        editFolder,
        deleteFolder,
        selectedFolder,
        setSelectedFolder,
    } = useMediaContext()

    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [images, setImages] = useState<UploadFile[]>([]);
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined)

    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)

    useEffect(() => {
        try {
            loadData()
        } catch (ex) {
            // message.error("Error in loading data", ex)
            console.log("Error in loadData", ex)
        }
    }, [])
    useEffect(() => {
        let treeData = convertData(data)
        console.log("Render:treeData  ", treeData)
        setTreeData(treeData)
    }, [data])

    useEffect(() => {
        console.log("model-section: files ", files)

        const images = files.map((file: File) => {
            // console.log("file url: ", file.url)
            return {
                id: file.id,
                uid: "" + file.id,
                name: file.name,
                status: 'done',
                url: file.url,
            } as UploadFile
        })
        // console.log("model-section: images ", images)

        setImages(images)
    }, [files])

    const handleCreateFolder = (name: string) => {
        console.log("handleCreateFolder: ", name)
        createFolder(name)
    }
    const handleEditFolder = (id: number, name: string) => {
        console.log("handleEditFolder: ", name)
        editFolder(id, name)
    }

    const handleDeleteNode = (id: number) => {
        console.log("handleDeleteNode: ", id)
        deleteFolder(id)
    }

    const handleSelectNode = (id: string) => {
        setSelectedFolder(Number(id))
        setImageUrl(undefined)
    }


    const handleUploadFile = async (options: any) => {
        console.log("model_section.handleUploadFile: options = ", options)
        await uploadFile(options)
        const { onError } = options
        setTimeout(() => {
            const error = new Error("Some error");
            onError({ error });
        }, 100);
    };


    const onSelectedImage = (file: any) => {
        setSelectedImage(file)
        // setSelectedFile(file)
        setImageUrl(file.url)

    }


    const onSubmit = () => {
        console.log("onSubmit")
    }
    return <>
        <div className="epr-media-content-container">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ minHeight: "100vh" }}>
                <FolderList
                    treeData={treeData}
                    nfile={data.length}
                    selectedFolder={selectedFolder}
                    handleCreateFolder={handleCreateFolder}
                    handleEditFolder={handleEditFolder}
                    handleDeleteFolder={handleDeleteNode}
                    handleSelectNode={handleSelectNode}
                />
                <ImageList
                    loading={loading}
                    uploadFile={handleUploadFile}
                    images={images}
                    selectedFolder={selectedFolder}
                    selectedImage={selectedImage}
                    setSelectedImage={onSelectedImage}
                    multiple={true}
                    crop={true}
                />

                {selectedImage &&
                    <DetailsInfomation
                        image={selectedImage}
                        onSubmit={onSubmit}
                    />}
            </Row>
        </div>
    </>
}

export default MediaList 