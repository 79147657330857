import React from 'react';
import { Space, Table, Tag, Card, Button, Col } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, SettingOutlined, GroupOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

interface DataType {
    id: any;
    logo: any;
    name: string;
    index: string;
    status: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Logo',
        dataIndex: 'logo',
        key: 'logo',
        width: '10%'
    },
    {
        title: 'Tên đối tác',
        dataIndex: 'name',
        key: 'name',
        width: '30%'
    },
    {
        title: 'Thứ tự hiển thị',
        dataIndex: 'index',
        key: 'created_time',
        width: '20%'
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        render: (_, { status }) => (
            <span style={{ fontStyle: 'italic', color: 'green' }}>{status}</span>
        )
    },
    {
        title: 'Hành động',
        key: 'action',
        dataIndex: 'action',
        width: '20%',
        render: () => (
            <>
                <Button icon={<SettingOutlined />}>Cài đặt</Button>
                <Button icon={<GroupOutlined />}>Danh mục</Button>
                <Button icon={<DeleteOutlined />} danger></Button >
            </>
        ),
    }
];

const data: DataType[] = [
    {
        id: '1',
        logo: '',
        name: 'IUCN',
        index: '1',
        status: '	Hoạt động'
    },
    {
        id: '2',
        logo: '',
        name: 'IUCN',
        index: '2',
        status: '	Hoạt động'
    }
];

export const TablePartner: React.FC = () => {

    return (
        <Card
            title="Danh sách 6 đối tác"
            headStyle={{ textAlign: 'left' }}>
            <Table columns={columns} dataSource={data} />
        </Card>
    )
}
export default TablePartner;