import React, { useEffect, useState } from 'react';
import { Divider, Table, Button, Space, Popconfirm, message, Spin, Alert } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs'
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import '../table.css'

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const { Column } = Table

interface DataType {
    id: number;
    title: string;
    slug: string;
    excerpt: string;
    author: string;
    blocked: boolean;
    index: number;
    deleted: boolean;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}
interface Props {
    loadingData: boolean;
    data: any[];
    handleDelete: (id: number) => void
}

const columns = (
    confirm: (id: number) => void
): ColumnsType<DataType> => [
        {
            title: '#',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: 'Mã hình ảnh',
            dataIndex: 'id',
            width: '30%',
            key: 'id',
            render: (_: any, record: DataType) => (
                <Space direction='vertical' size='small'>
                    <p>{record.id} </p>
                </Space>
            )
        },
        {
            title: 'Tác giả',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: '50%'
        },
        {
            title: 'Năm xuất bản',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: "10%",
        },
        {
            title: 'Hành động',
            width: '10%',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle" direction="horizontal" wrap align='center'>
                    <Button icon={<EditFilled />}></Button>
                    <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                        onConfirm={() => confirm(record.id)}
                        // onCancel={cancel}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button danger icon={<DeleteFilled />}></Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

const TableList: React.FC<Props> = ({ loadingData, data, handleDelete }) => {

    const confirm = (id: number) => {
        if (handleDelete) {
            console.log("delete", id)
            handleDelete(id)
        }
    };
    const cancel = () => {
        message.error('Click on No');
    };
    return (
        <div>
            {loadingData ? (<Spin size="large">
                <Alert
                    message="Thông báo"
                    description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                    type="info"
                />
            </Spin>) : (
                <>
                    <Divider />
                    <Table dataSource={data} columns={columns(confirm)} rowKey="id"
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'} />
                </>
            )}

        </div >
    );
};

export default TableList;
