import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Divider, Select } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import localeValues from "antd/locale/vi_VN";
import './ads.css'
interface HeaderComment {
}
const { Search, TextArea } = Input

const HeaderAds: React.FC<HeaderComment> = (props) => {
    const { } = props

    const [form] = Form.useForm();


    return (
        <Form className="titlebox_ads" form={form}>
            <Space className="title">
                <FileImageOutlined />
                <span>Cài đặt quảng cáo</span>
            </Space>
        </Form >


    )
}

export default HeaderAds
