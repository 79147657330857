import history from '../history'
import { IPost } from '../types/post';
import fetch, {queryOne,  queryList } from "./request"


export const List = async (props:any): Promise<any> => { 
    const {page, size, word } = props
    if (word) {
        let keyword = JSON.stringify(word)
        return fetch(`api/post?page=${page}&size=${size}&keyword=${keyword}`, 'GET', true, {})
    }
    return fetch(`api/post?page=${page}&size=${size}`, 'GET', true, {})
}
export const GetByID = async (id:number): Promise<IPost> => { 
    return queryOne(`api/post/get?id=${id}`, 'GET', true, {})
}

export const Create = async (info: any): Promise<IPost> => {
    return queryOne(`api/post`, 'POST', true, info)
}


export const Delete = async (info: any): Promise<IPost> => {
    return queryOne(`api/post`, 'DELETE', true, info)
}
export const Update = async (info: any): Promise<IPost> => {
    return queryOne(`api/post`, 'PUT', true, info)
}
export const Filter = async (info: any): Promise<any> => {
    await new Promise((resolve) => setTimeout(resolve, 100));

    const responseData = {
        code: '200',
        message: 'Success',
        data: [
            {
                id: 200,
                title: 'fake filter',
                content: 'This is fake data for API filter',
            },
            {
                id: 201,
                title: 'fake filter 2',
                content: 'fake 2 for filter',
            },
            {
                id: 202,
                title: 'fake filter 3',
                content: 'fake 3 for filter',
            },
        ],
    };

    if (responseData.code === '200') {
        return responseData.data;
    } else {
        throw new Error(responseData.message);
    }
};
