import { Card, Input, Form, Select, Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import './home.css'
import { PlusOutlined, FileOutlined } from "@ant-design/icons";
import TableLinkBox from "./tableLink";


interface Props {

}


const SettingLinkCard: React.FC = () => {
    const [form] = Form.useForm();
    const { TextArea } = Input
    return (
        <Card
            title="Box liên kết nhanh"
            className="content_home"
            actions={[<Button type="primary" icon={<FileOutlined />}>Lưu lại</Button>]}
            headStyle={{ textAlign: "left" }}
        >
            <p style={{ textAlign: 'left', fontWeight: 'lighter', fontSize: 'small' }}>(Lưu ý: Cần nhấn nút "Lưu lại" để lưu các cài đặt bạn đã thêm)</p>
            <Form
                style={{ width: '100%' }}
                form={form}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <TableLinkBox />
                    </Col>
                </Row>
            </Form>
        </Card >
    );
};

export default SettingLinkCard;