import React, { useEffect, useState } from "react";
import { Layout, Modal, Button, Form, Divider } from 'antd';
import './keyword.css';
import HeaderKeywords from "./header";
import TableList from './table';
import { list, create, del, update, importKeyword } from "./keywordSlice";
import dayjs from 'dayjs';
import { CopyFilled } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import FormKeyword from "./formKeyword";
import FormUpdateKeyword from "./formUpdate";
import { notification } from "antd";

interface Props {
}

interface DataType {
    id: number;
    slug: string;
    name: string;
    blocked: boolean;
    postCount: number;
    type: string;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}

const Keyword: React.FC<Props> = ({ }) => {
    const [api, contextHolder] = notification.useNotification()
    const dispatch = useAppDispatch()
    const keyword = useAppSelector(state => state.keyword)

    const [data, setData] = useState<any[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<DataType | null>(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [loadingData, setLoadingData] = useState(false);

    const [word, setWord] = useState<string>("");
    const now = dayjs();
    const nowExport = dayjs().format("DD/MM/YYYYS")
    const [form] = Form.useForm()
    const [formUpdate] = Form.useForm()

    useEffect(() => {
        fetchData(page, pageSize);
    }, [word]);

    useEffect(() => {
        const keywordItems = keyword?.items;
        if (keywordItems) {
            if (keyword?.pagination?.total) {
                setTotal(keyword?.pagination?.total)
                setPage(keyword?.pagination?.page)
                setPageSize(keyword?.pagination?.size)

            } else {
                setTotal(keywordItems.length)
            }
            setData(keywordItems)
        }
    }, [keyword?.items])

    useEffect(() => {
        if (selectedItem) {
            let { type, name, slug, blocked } = selectedItem;
            formUpdate.setFieldsValue({ type, name, slug, blocked });
        }
    }, [selectedItem]);

    const closeModal = () => {
        setIsModalOpen(false)
        form.resetFields();
    }

    const closeModalUpdate = () => {
        setIsModalUpdateOpen(false)
        formUpdate.resetFields()
    }


    const onNew = () => {
        setIsModalOpen(true)
    }

    const initialValues = (record: DataType) => {
        setSelectedItem(record);
        setIsModalUpdateOpen(true);
    };

    const fetchData = (page: number, pageSize: number) => {
        let pagination = {
            page: page,
            size: pageSize,
            word: { word }
        }
        setLoadingData(true);
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false);
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Lấy dữ liệu thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
        setLoadingData(false)
    }
    const onRefresh = () => {
        console.log("Refresh data")
        fetchData(page, pageSize);
    }


    const handleSubmitKeywords = async (values: any) => {
        const formValues = {
            id: values.id,
            slug: values.slug,
            name: values.name,
            blocked: values.blocked,
            postCount: values.postCount,
            type: values.type,
            createdBy: values.createdBy,
            updatedBy: values.updatedBy,
            createdTime: now.unix(),
            updatedTime: now.unix()
        };
        setLoadingData(true);
        dispatch(create(formValues)).unwrap()
            .then(() => {
                setLoadingData(false)
                api.open({
                    message: 'Thành công',
                    description: 'Tạo thành công',
                    duration: 0,
                    type: 'success'
                })
            }).catch((error) => {
                api.open({
                    message: 'Thất bại',
                    description: `Tạo thất bại: ${error}`,
                    duration: 0,
                    type: 'error'
                })
                setLoadingData(false)
            })
        closeModal()
    };

    const handleSave = () => {
        if (selectedItem) {
            formUpdate.validateFields()
                .then((values) => {
                    const updatedRecord = { ...selectedItem, ...values };
                    dispatch(update(updatedRecord)).unwrap().then(() => {
                        api.open({
                            message: 'Thành công',
                            description: 'Cập nhật thành công',
                            duration: 0,
                            type: 'success'
                        })
                    }).catch((error) => {
                        api.open({
                            message: 'Thất bại',
                            description: `Cập nhật thất bại: ${error}`,
                            duration: 0,
                            type: 'error'
                        })
                        closeModalUpdate()
                        fetchData(page, pageSize);
                    });
                })
        }
    };

    const handleSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setWord(values)
    };

    const handleDeleteKeywords = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap()
            .then(() => {
                // setLoadingData(false);
                api.open({
                    message: 'Thành công',
                    description: 'Xóa thành công',
                    duration: 0,
                    type: 'success'
                })
            }).catch((error) => {
                api.open({
                    message: 'Thất bại',
                    description: `Xóa thất bại ${error}`,
                    duration: 0,
                    type: 'error'
                })
                // setLoadingData(false)
            })
    };
    const getMaxWidth = (property: keyof DataType, rows: DataType[], defaultValue = 10): number => {
        return rows.reduce((maxWidth, row) => Math.max(maxWidth, String(row[property]).length), defaultValue);
    };

    const headerA: (keyof DataType)[] = ["id", "slug", "name", "blocked", "postCount", "type", "createdBy", "updatedBy",
        "createdTime", "updatedTime"
    ];

    const exportData = () => {
        const rows: DataType[] = data.map(row =>
            headerA.reduce((acc: any, key) => {
                acc[key] = row[key];
                return acc;
            }, {} as DataType)
        );

        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');

        XLSX.utils.sheet_add_aoa(ws, [headerA], { origin: 'A1' });

        if (!ws["!cols"]) {
            ws["!cols"] = [];
        }

        headerA.forEach((property, index) => {
            if (ws["!cols"]) {
                ws["!cols"][index] = { wch: getMaxWidth(property, rows) };
            }
        });
        XLSX.writeFile(wb, `Keyword ${nowExport}.xlsx`);
    };

    const arraysEqual = (a: any[], b: any[]): boolean => {
        return JSON.stringify(a) === JSON.stringify(b);
    };

    const importData = (file: File) => {

        const reader = new FileReader();
        reader.onload = (e: any) => {
            const dataFromXLSX = new Uint8Array(e.target.result);
            const wb = XLSX.read(dataFromXLSX, { type: 'array' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const jsonData: any[][] = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const headerOfXLSX = jsonData[0]
            const convertExcelDataToDataType = (excelData: any[][]): any[] => {
                const arrayOfObjects = excelData.slice(1).map(array => {
                    return array.reduce((obj: any, value, index) => {
                        obj[headerA[index]] = value;
                        return obj;
                    }, {});
                });

                return arrayOfObjects;
            };

            if (!arraysEqual(headerA, headerOfXLSX)) {
                api.open({
                    message: 'Import failed',
                    type: 'error',
                    description: `Excel Import ${file.name} is not in the correct format`
                })
                console.log('falied', headerOfXLSX)
                return data
            } else {
                const dataImport = convertExcelDataToDataType(jsonData);
                dispatch(importKeyword(dataImport))
                api.open({
                    message: "Import success",
                    type: 'success'
                })
                console.log('success', headerOfXLSX)

            }
        };
        reader.readAsArrayBuffer(file)
    };
    const onImport = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            importData(file)
        }
    }

    return (

        <div className="wrapper">
            {contextHolder}
            <div className="titleBox">
                <HeaderKeywords
                    onNew={onNew}
                    onSearch={handleSearch}
                    onRefresh={onRefresh}
                    onExport={exportData}
                    onImport={onImport} />
            </div>
            <div className="main-content">
                <div className="title">
                    <span>Danh sách {total} thẻ cho bài viết</span>
                </div>
                <div className="data">
                    <TableList
                        page={page}
                        pageSize={pageSize}
                        total={total}
                        fetchData={fetchData}
                        data={data}
                        loadingData={loadingData}
                        handleDelete={handleDeleteKeywords}
                        initialValues={initialValues} />
                </div>
            </div>
            <Modal title="Thêm mới"
                open={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button key="close" onClick={closeModal} >Đóng</Button>,
                    <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />}
                        onClick={form.submit}>Duyệt</Button>
                ]}
                mask={false}
                width={1000}
            >
                <FormKeyword form={form} handleSubmitKeyword={handleSubmitKeywords} />
            </Modal >
            <Modal
                title="Chỉnh sửa"
                open={isModalUpdateOpen}
                onCancel={() => { setIsModalUpdateOpen(false) }}
                width={1000}
                footer={[
                    <Button key="close" onClick={() => { setIsModalUpdateOpen(false) }}>
                        Đóng
                    </Button>,
                    <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />} onClick={formUpdate.submit}>Lưu lại</Button>

                ]}
            >
                <Divider />
                <FormUpdateKeyword form={formUpdate} handleUpdate={handleSave} />
            </Modal>

        </div>


    )
}

export default Keyword
