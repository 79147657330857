import { SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { File, Folder, FNode } from "../types/folder";
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

import {
    load_folder_structure,
    create_folder,
    edit_folder,
    delete_folder,
    get_folder,
    list_files,
    upload_file
} from "../services/media"

const findNode: any = (items: FNode[], id: number) => {
    for (var item of items) {
        if (item.id == id) {
            return item
        }
        if (item.children) {
            let node = findNode(item.children, id)
            if (node) {
                return node
            }
        }
    }
    return null
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });



type MediaContext = {
    loading: boolean;
    data: FNode[];
    loadData: () => void;
    files: File[];
    findUrlById: (id: number) => string | undefined;
    createFolder: (name: string) => void;
    editFolder: (id: number, name: string) => void;
    deleteFolder: (id: number) => void;
    selectedFolder: Folder | undefined;
    setSelectedFolder: (id: number) => void;
    selectedFile: File | undefined;
    setSelectedFile: React.Dispatch<SetStateAction<File | undefined>>;
    uploadFile: (options: any) => void;
};

const MediaContext = createContext<MediaContext>(null!);

export function MediaProvider(props: any) {
    const { children } = props
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<FNode[]>([]);
    const [progress, setProgress] = useState(0);

    const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>(undefined);

    const [files, setFiles] = useState<File[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

    const deleteNode = (id: number) => {
        console.log("delete node: ", id)
        delete_folder(id).then((data) => {
            console.log("delete_folder  successfully")
            loadData()
        }).catch((ex) => {
            console.log("Error delete folder: ", ex)
        })
    }
    const createFolder = (name: string) => {
        console.log("Loading data")
        let parent_id = selectedFolder ? selectedFolder.id : 0
        create_folder(name, parent_id)
            .then((folder) => {
                loadData()
            })
    }
    const editFolder = (id: number, name: string) => {
        console.log("editFolder node: ", id)
        edit_folder(id, name).then((data) => {
            console.log("editFolder successfully")
            loadData()
        }).catch((ex) => {
            console.log("Error delete folder: ", ex)
        })
    }
    const loadData = () => {
        load_folder_structure()
            .then((data) => {
                setData(data);
                if (!selectedFolder) {
                    selectFolder(2)
                }
            })
            .catch((ex) => {
                console.log("loadData failed: ", ex);
            });
    }

    const findUrlById = (id: number) => {
        const file = files.find((file) => file.id === id)
        return file ? file.url : undefined
    }
    const selectFolder = (id: number) => {
        console.log("selectFolder:", id)
        setFiles([])
        setLoading(true)
        get_folder(id).then((folder) => {
            setSelectedFolder(folder)
            // console.log("useMediaContext: list_files ", id)
            list_files(id).then((data) => {
                const { pagination, results } = data;
                // console.log("useMediaContext: list_files =>  files:  ", results)
                setFiles(results)
                setLoading(false)
            }).catch((ex) => {
                console.log("Cannot load files", ex)
                setLoading(false)
            })
        }).catch((ex) => {
            console.log("Cannot load foler", ex)
            setLoading(false)
        })
    }
    const uploadFile = async (options: any) => {
        let folderId = selectedFolder ? "" + selectedFolder.id : "0"
        upload_file(options, folderId).then((res: any) => {
            console.log("hooks.uploadFile: upload successfully: ", res)
            const { code, message, data } = res
            console.log("hooks.uploadFile: add file to list", data)
            let nfiles = [...files, data]
            setFiles(nfiles)
        })
    }
    // const selectFile = (id: number) => {

    // }
    return (
        <MediaContext.Provider value={{
            loading,
            data,
            loadData,
            files,
            findUrlById,
            createFolder,
            editFolder,
            deleteFolder: deleteNode,
            selectedFolder,
            setSelectedFolder: selectFolder,
            selectedFile,
            setSelectedFile,
            uploadFile,
        }}
        >
            {children}
        </MediaContext.Provider>
    )
}

export function useMediaContext() {
    const mediaContext = useContext(MediaContext)
    if (!mediaContext) {
        throw new Error("mediaContext has to be used within <MediaContext.Provider>")
    }
    return mediaContext
}