import React from 'react';
import { Space, Table, Tag, Card, Button, Col } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, SettingOutlined, GroupOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

interface DataType {
    id: any;
    name: string;
    type: string;
    created_time: any // should be dayjs.Dayjs;
    updated_time?: any // should be dayjs.Dayjs;

}

const columns: ColumnsType<DataType> = [
    {
        title: 'Phân loại',
        dataIndex: 'type',
        key: 'type',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Tên menu điều hướng',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Thời gian',
        dataIndex: 'created_time',
        key: 'created_time',
    },
    {
        title: 'Hành động',
        key: 'action',
        dataIndex: 'action',
        width: '10%',
        render: () => (
            <>
                <Button icon={<SettingOutlined />}>Cài đặt</Button>
                <Button icon={<GroupOutlined />}>Danh mục</Button>
                <Button icon={<DeleteOutlined />} danger>Delelte</Button >
            </>
        ),
    }
];

const data: DataType[] = [
    {
        id: '1',
        type: 'Tiếng Việt',
        name: 'Tiếng Việt',
        created_time: 22222
    },
    {
        id: '2',
        type: 'Tiếng Anh',
        name: 'Tiếng Anh',
        created_time: 22222

    }
];

export const TableNav: React.FC = () => {

    return (
        <Card
            title="Danh sách 2 menu điều hướng"
            headStyle={{ textAlign: 'left' }}>
            <Table columns={columns} dataSource={data} />
        </Card>
    )
}
export default TableNav;