import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Space, Divider, Radio, Checkbox, DatePicker, Input } from 'antd';
import { Layout, Form, FormInstance } from 'antd';

const { Header, Content, Sider } = Layout;

const dataFormat = "DD/MM/YYYY HH:mm"

interface LanguageAndTimeSectionProps {
    form?: FormInstance<any>
    lang?: string
}

const LanguageAndTimeSection: React.FC<LanguageAndTimeSectionProps> = ({ lang, form }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });
    // const [langs, setLang] = useState("vi")
    // const [cmtStatus, setCmtStatus] = useState("private")
    // const [cmt, setCmt] = useState("private")
    // const [showOnBanner, setShowOnBanner] = useState(showOnBannerValue)

    return (
        <div className="wrapper-option" >
            <Space className="title-post-content" align="start" size={"small"}>
                <span>{t('post')}</span>
            </Space>
            <Divider />
            <Form.Item className="option-element"
                label="Hiển thị trên banner trang tin"
                name="showOnBanner"
            >
                <Radio.Group className="option-radio" style={{ display: 'flex' }}>
                    <Radio value={'1'}>Có</Radio>
                    <Radio value={'0'}>Không</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item className="option-element"
                label={t('cmtPost')}
                name='commentStatus'
            >
                <Radio.Group className="option-radio" style={{ display: "flex" }}
                >
                    <Radio value={'public'}>{t('public')}</Radio>
                    <Radio value={'private'}>{t('private')}</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item className="option-element"
                label={t('lang')}
                name='lang'
                style={{ display: "none" }}
                initialValue={lang}
            >
                <Input type="hidden" />
                {/* <Radio.Group className="option-radio" style={{ display: "flex" }}
                >
                    <Radio value={'vi'}>{t('vi')}</Radio>
                    <Radio value={'en'}>{t('en')}</Radio>
                </Radio.Group> */}
            </Form.Item>
            <Form.Item className="option-element"
                label={t('postStatus')}
                name='status'
            >
                <Radio.Group className="option-radio"
                    style={{ display: "flex" }}
                >
                    <Radio value={'private'}>{t('private')}</Radio>
                    <Radio value={'public'}>{t('public')}</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                className="option-element"
                label={t('publicDate')}
                style={{ display: "flex" }}
                name='publicDate'
                rules={[{ required: true, message: t('mesPublicdate') }]}>
                <DatePicker
                    className="datepicker"
                    picker="date"
                    format={dataFormat}
                />
            </Form.Item>
            <Form.Item
                className="option-element"
                label='Ngày hết hạn sự kiện'
                name='finishDate'
                rules={[{ required: true, message: t('mesFinishdate') }]}>
                <DatePicker
                    className="datepicker"
                    picker="date"
                    format={dataFormat}
                />
            </Form.Item>
        </div >
    )
}

export default LanguageAndTimeSection;