import React, { useState } from 'react';
import { Divider, Spin, Table, Alert, Button, Space, Popconfirm, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs'
import { EyeOutlined, EditFilled, DeleteFilled, CopyOutlined } from '@ant-design/icons';
import '../table.css'

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const { Column } = Table

interface DataType {
    id: number;
    parentId: number;
    slug: string;
    name: string;
    nameEn: string;
    description: string;
    position: number;
    blocked: boolean;
    lang: string;
    postCount: number;
    deleted: boolean;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}
interface Props {
    loadingData: boolean;
    data: any[];
    handleDelete: (id: number) => void;
    initialValues: (record: any) => void;
    onCopySlugCategory: (slug: string) => void;
    total: number
    pageSize: number
    page: number
    fetchData: (page: number, pageSize: number) => void
}

const columns = (
    confirm: (id: number) => void,
    pre: (record: DataType) => void,
    copy: (slug: string) => void
): ColumnsType<DataType> => [
        {
            title: '#',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: '#Parent',
            dataIndex: 'parentId',
            width: '5%',
            key: 'parentId',
        },
        {
            title: 'Tên chuyên mục ',
            dataIndex: 'name',
            width: '25%',
            key: 'name',
        },
        {
            title: 'Tên chuyên mục tiếng Anh',
            dataIndex: 'nameEn',
            width: '25%',
            key: 'title'
        },
        {
            title: 'Đường dẫn',
            dataIndex: 'slug',
            key: 'slug',
            width: '10%',
        },
        {
            title: 'Số bài viết',
            dataIndex: 'postCount',
            key: 'postCount',
            width: "10%",
        },
        {
            title: 'Hành động',
            width: '10%',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle" direction="horizontal" wrap align='center'>
                    <Button onClick={() => copy(record.slug)} icon={<CopyOutlined />} />
                    <a href={`https://epr.monre.gov.vn/vi/bai-viet/chi-tiet/${record.slug}.html`} target="_blank" rel="noopener noreferrer">
                        <Button icon={<EyeOutlined />} />
                    </a>
                    <Button icon={<EditFilled />} onClick={() => pre(record)}></Button>
                    <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                        onConfirm={() => confirm(record.id)}
                        // onCancel={cancel}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button danger icon={<DeleteFilled />}></Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

const TableList: React.FC<Props> = ({
    loadingData,
    data,
    handleDelete,
    initialValues,
    onCopySlugCategory,
    total,
    pageSize,
    page,
    fetchData,
}) => {
    const [values, setValues] = useState<Partial<DataType>>({});

    const confirm = (id: number) => {
        if (handleDelete) {
            console.log("delete", id)
            handleDelete(id)
        }
    };

    const pre = (record: DataType) => {
        initialValues(record);
        setValues(record);
    };

    const copy = (slug: string) => {
        onCopySlugCategory(slug)
    }
    return (
        <div>
            {loadingData ? (<Spin size="large">
                <Alert
                    message="Thông báo"
                    description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                    type="info"
                />
            </Spin>) : (
                <>
                    <Divider />
                    <Table
                        dataSource={data}
                        columns={columns(confirm, pre, copy)}
                        rowKey="id"
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                        pagination={{
                            total: total,
                            onChange: (page, pageSize) => {
                                fetchData(page, pageSize);
                            },
                            showSizeChanger: true,
                            current: page,
                            pageSize: pageSize,
                        }}
                    />
                </>
            )}

        </div >
    );
};

export default TableList;
