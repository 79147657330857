import { Button, Space } from "antd";
import { SnippetsOutlined, RedoOutlined } from "@ant-design/icons";
import React from "react";
import './generalSetting.css'


const HeaderGeneral: React.FC = () => {

    return (
        <>
            <div className="titlebox_general">
                <Space >
                    <SnippetsOutlined />
                    <span>Tham số hệ thống</span>
                </Space>
                <Button type="primary" icon={<RedoOutlined />}></Button>
            </div>
        </>
    );
};

export default HeaderGeneral;