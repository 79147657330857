import React from "react";
import { Button, Space, Input, Form, UploadProps, message, Upload } from 'antd';
import { PlusOutlined, VideoCameraOutlined, RedoOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import './video.css'
interface HeaderVideo {
    onNew: (values: any) => void
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
    onExport: (values: any) => void
    onImport: (values: any) => void
    total: any
}
const { Search } = Input

const HeaderVideo: React.FC<HeaderVideo> = (props) => {
    const { onExport, onNew, onSearch, onImport, onRefresh, total } = props
    const [form] = Form.useForm();

    return (
        <div>
            <Form
                form={form}
                name="control-hooks"
                style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            >
                <Space className="title">
                    <VideoCameraOutlined />
                    <span>Video</span>
                </Space>
                <Space className="option-box" style={{ width: '80%' }}>
                    <Form.Item
                        noStyle
                        name='all'>
                        <Button type="primary" size="middle">Tất cả ({total})</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='waiting'>
                        <Button type="default" size="middle">Hoạt động ({total})</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='accept'>
                        <Button type="default" size="middle">Tạm dừng (0)</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='searchVideo'>
                        <Search
                            onSearch={onSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>Thêm mới</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" icon={<RedoOutlined />} onClick={onRefresh} size="middle" />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={onExport} size="middle">Export</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <input accept=".xlsx, .csv" type="file" onChange={onImport} />
                    </Form.Item>
                </Space>
            </Form>

        </div>


    )
}

export default HeaderVideo
