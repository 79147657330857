import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Settings_layout from "../../siderSetting";
import HeaderPartner from "./headerPartner";
import TablePartner from './table'
import './partner.css'
const SettingPartner: React.FC = () => {

    return (
        <>
            <div className="nav_wrapper_setting">
                <div className="info_nav_setting" >
                    <HeaderPartner />
                    <br></br>
                    <TablePartner />
                </div>
            </div>
        </>
    );
};

export default SettingPartner;