import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useState } from 'react';
import { Table, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';

interface DataType {
    key: string;
    title: string;
    status: string;
}
const columns: ColumnsType<DataType> = [
    {
        // title: 'abc',
        dataIndex: 'title',
        width: '60%'
    },
    {
        // title: 'abc',
        dataIndex: 'status',
        width: '30%'
    },
    {
        // title: 'abc',
        dataIndex: 'action',
        render: () => (
            <>
                <Button icon={<DeleteOutlined />} danger></Button >
            </>
        ),
        width: '10%'

    },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row = (props: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        cursor: 'move',
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const TableConsultanBox: React.FC = () => {
    const [dataSource, setDataSource] = useState([
        {
            key: '1',
            title: 'Đề xuất và góp ý cho dự thảo Quyết định của Thủ tướng Chính phủ ban hành Fs',
            status: 'Công khai '
        },
        {
            key: '2',
            title: ' Đề xuất và góp ý cho dự thảo Thông tư ban hành Quy chế quản lý, sử dụng tiền đóng góp tài chính của nhà sản xuất, nhập khẩu vào Quỹ Bảo vệ môi trường Việt Nam để hỗ trợ tái chế, xử lý chất thải',
            status: 'Công khai '
        },
    ]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDataSource((prev) => {
                const activeIndex = prev.findIndex((i) => i.key === active.id);
                const overIndex = prev.findIndex((i) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    return (
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                // rowKey array
                items={dataSource.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
            >
                <Table
                    components={{
                        body: {
                            row: Row,
                        },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={dataSource}
                />
            </SortableContext>
        </DndContext>
    );
};

export default TableConsultanBox;