import React, { useEffect, useState } from "react";
import { Button, Space, Input, Form } from 'antd';
import { PlusOutlined, CopyOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import './document.css'

interface HeaderDoc {
    onNew?: (values: any) => void
    onSearch?: (values: any) => void
    onRefresh?: (values: any) => void
}
const { Search, TextArea } = Input

const HeaderDetailDoc: React.FC<HeaderDoc> = (props) => {
    const { onNew, onSearch, onRefresh } = props
    const [form] = Form.useForm();
    return (
        <div className="titleBox" >
            <Form
                // {...layout}
                form={form}
                name="control-hooks"
                style={{ width: ' 100% ', display: 'flex', justifyContent: 'space-between' }}
            >
                <Space className="title">
                    <CopyOutlined />
                    <span>Tài liệu</span>
                </Space>
                <Space className="option-box" style={{ width: '60%' }}>
                    <Form.Item
                        noStyle
                        name='keyword'>
                        <Search onSearch={onSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>Thêm mới</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" icon={<RedoOutlined />} size="middle" onClick={onRefresh} />
                    </Form.Item>
                </Space>
            </Form>
        </div>


    )
}

export default HeaderDetailDoc
