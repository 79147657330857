import React, { useState } from "react";
import { Input, Form, Checkbox, Select, FormInstance } from 'antd';
import { TreeSelect } from 'antd';
import { option_lang } from "../../data/data";
import './keyword.css'
import { slugify } from "../../util/helper";

const { Search, TextArea } = Input

interface HeaderKeyword {
    form: FormInstance<any>,
    handleSubmitKeyword: (values: any) => void
}

const FormKeyword: React.FC<HeaderKeyword> = (props) => {
    const { form, handleSubmitKeyword } = props
    const onFinish = (values: any) => {
        console.log("Form.onFinish: ", values)
        if (handleSubmitKeyword) {
            handleSubmitKeyword(values)
        }
    }

    const onChangeSlug = () => {
        let title = form?.getFieldValue("name")
        if (title) {
            let slug = slugify(title)
            form?.setFieldValue("slug", slug)
        }
    }

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='type'
                label="Loại thẻ"
                rules={[{ required: true, message: 'Tên người bình luận không được để trống.' }]}>
                <Select options={option_lang} />
            </Form.Item>
            <Form.Item
                name='name'
                label="Tên thẻ "
                help='Tên riêng sẽ hiển thị trên website của bạn'
                rules={[{ required: true, message: 'Tên thẻ không được để trống.' }]}>
                <Input onChange={onChangeSlug} />
            </Form.Item>
            <Form.Item
                name='slug'
                label="Đường dẫn"
                help="Chuỗi cho đường dẫn tĩnh là phiên bản của tên hợp chuẩn với Đường dẫn (URL). Chuỗi này bao gồm chữ cái thường, số và dấu gạch ngang (-).">
                <Input />
            </Form.Item>
            <Form.Item
                name='blocked'>
                <Checkbox> Tạm ẩn trên website</Checkbox>
            </Form.Item>
        </Form>
    )
}

export default FormKeyword 