import React, { useEffect, useState } from "react";
import { Space, Divider, Form, Button, Input, Radio, Image } from 'antd';

const ImageItem = (props: any) => {
    const [imageUrl, setImageUrl] = useState("");
    const { value, onChange } = props;

    useEffect(() => {
        setImageUrl(value)
    }, [value])

    const onInputChange = (e: any) => {
        console.log("onInputChange: imageUrl = ", e.target.value)
        let url = e.target.value
        setImageUrl(decodeURI(url))
        if (typeof onChange === 'function') {
            onChange(decodeURI(url));
        }
    }

    return (
        <>
            <Input onChange={onInputChange} value={imageUrl || ""} />
            {
                imageUrl != "" && <Image preview={false} src={imageUrl} />
            }
        </>
    )
}
export default ImageItem
