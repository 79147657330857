import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDoc } from "../../types/doc";
import {List, Create, Delete, Update} from '../../services/doc'
import { RootState } from '../../redux/store';
import { IPagination } from "../../types/pagination";


export interface docState {
    status : 'idle' | 'loading' | 'succeeded' | 'failed';
    items ?: IDoc[];
    pagination?: IPagination;
    error?: string ;
}   

const initialState: docState = {
    status: 'idle',
    items: []
}

export const docSlice = createSlice ({
    name: 'doc',
    initialState,
    reducers: {
        importDoc: (state, action) => {
            state.items = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(list.pending, (state,action) => {
                state.status = 'loading'
            })
            .addCase(list.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const { data, extra } = action.payload
                state.items = data
                state.pagination = extra
            })
            .addCase(list.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message + " with error: " + action.payload
            })
            .addCase(create.fulfilled, (state, action) => {
                state.status = 'succeeded'
                let item = action.payload
                state.items?.unshift(item)
              })
            .addCase(del.fulfilled, (state,action) => {
                state.status = 'succeeded'
                let item = action.payload
                state.items = state.items?.filter((el) => el.id != item.id)
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                let updatedItem = action.payload;
                let updatedItemId = updatedItem.id;
                state.items = state.items?.map((item: any) => {
                    if (item.id === updatedItemId) {
                        return updatedItem
                    } 
                    else {
                        return item;
                    }
                });
            });
    }
})

export const list = createAsyncThunk('doc/list', async (info: any, { rejectWithValue }) => {
    try {
      const response = await List(info)
      return response
    } catch (ex) {
      return rejectWithValue(ex)
    }
  })

export const create  = createAsyncThunk('doc/create', async (info:any, {rejectWithValue}) => {
    try {
        const response = await Create(info)
        return response
    } catch (ex) {
        return rejectWithValue(ex)
    }
})

export const del = createAsyncThunk('doc/delete', async (info:any, {rejectWithValue}) => {
    try {
        const response = await Delete (info)
        return response
    } catch (ex) {
        return rejectWithValue(ex)
    }
})
export const update = createAsyncThunk('video/update', async(info:any, {rejectWithValue}) => {
    try {
        const response = await Update(info)
        return response 
    } catch (ex) {
        return rejectWithValue(ex)
    }
})
export const { importDoc } = docSlice.actions 

export const selectDoc = (state: RootState) => state.doc

export default docSlice.reducer