import React from "react";
import { Input, Form, InputNumber, Checkbox } from 'antd';
import './video.css'



const FormUpdateVideo = (props: any) => {
    const { form, handleUpdate, initialValues } = props
    const onFinish = (values: any) => {
        if (handleUpdate) {
            handleUpdate(values)
        }
    }
    const { TextArea } = Input
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={initialValues}
        >
            <Form.Item
                name='title'
                label="Tiêu đề "
                rules={[{ required: true, message: 'Tiêu đề không được để trống.' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name='slug'
                label="Đường dẫn Youtube "
                rules={[{ required: true, message: 'Đường dẫn Youtube không được để trống.' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name='excerpt'
                label="Mô tả ngắn"
                style={{
                    height: 'auto'
                }}>
                <TextArea />
            </Form.Item>
            <Form.Item
                label="Thứ tự hiển thị"
                name='index'
            >
                <InputNumber style={{ width: '500px' }} />
            </Form.Item>
            <Form.Item
                name='blocked'>
                <Checkbox checked={true}>Tạm khoá</Checkbox>
            </Form.Item>
        </Form>
    )
}

export default FormUpdateVideo 