import React, { useEffect, useState } from "react";
import { Input, Form, Select, Radio, DatePicker, Row, Col, Button } from 'antd';
import './document.css'
import Editor from '../post/comp/editor'
import { option_doc, option_lang, option_effectStatus } from "../../data/data";
import MediaSection from "./formUpdateThumnail";
import dayjs from 'dayjs'
interface UpdateDoc {
    form?: any,
    handleUpdate?: (values: any) => void
}

const FormUpdateDoc: React.FC<UpdateDoc> = (props) => {

    const [selectTypeDoc, setSelectTypeDoc] = useState(null)
    const { form, handleUpdate } = props;
    const [fileId, setFileId] = useState<any>()

    useEffect(() => {
        const typeValue = form.getFieldValue("type");
        setSelectTypeDoc(typeValue);
    }, [form.getFieldValue('type')]);

    const onFinish = (values: any) => {
        if (handleUpdate) {
            handleUpdate(values)
        }
    }

    const handleSelectedType = (value: any) => {
        setSelectTypeDoc(value);
    }
    const { TextArea } = Input
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='title'
                label="Tiêu đề"
            >
                <TextArea placeholder="Nhập tiêu đề tài liệu của bạn" />
            </Form.Item>
            <Form.Item
                name='type'
                label="Loại tài liệu"
                rules={[{ required: true, message: 'Loại tài liệu không được để trống.' }]}
            >
                <Select options={option_doc} onChange={handleSelectedType} />
            </Form.Item>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="lang"
                        label="Ngôn ngữ">
                        <Select options={option_lang} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="publicDate"
                        label="Ngày lên website">
                        <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Form.Item
                    name="status"
                    label="Trạng thái bài">
                    <Radio.Group>
                        <Radio
                            value={"draft"}>
                            Bản nháp
                        </Radio>
                        <Radio value={"public"}>
                            Công khai
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </Row>
            {selectTypeDoc === "van-ban-phap-luat" && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="symbol"
                            label="Số ký hiệu">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="effectStatus"
                            label="Trạng thái hiệu lực">
                            <Select options={option_effectStatus} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="agencyIssued"
                            label="Cơ quan ban hành">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}
                    >
                        <Form.Item
                            name="issuedDate"
                            label="Ngày ban hành"
                        >
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
            )
            }
            {selectTypeDoc === "hoi-thao-epr" && (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="excerpt"
                            label="Mô tả ngắn"
                            help="Mô tả ngắn là một đoạn mô tả về nội dung mà bạn tự nhập bằng tay, có thể được sử dụng để hiển thị trong giao diện của bạn"
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="content"
                            label=" Nội dung">
                            <Editor />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <MediaSection form={form} />
                    </Col>
                </Row>
            )
            }
            {selectTypeDoc === "nghien-cuu-bao-cao" && (
                <>
                    <MediaSection form={form} />
                </>
            )}
        </Form >
    )
}

export default FormUpdateDoc 