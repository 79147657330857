export const treeOption = (item: any) => {
    if (item.children) {
        let childs = item.children.map((child: any) => treeOption(child))
        return {
            key: item.id,
            value: item.id,
            title: item.name,
            children: childs,
        }
    }
    return {
        key: item.id,
        value: item.id,
        title: item.name,
    }
}
export const slugify = (text: string) =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')

export const string_2_slug = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const Array2Option = (lang: string, items: any[]) => {
    const list = items.filter(item => item.type === lang)
    const option = items.map(item => ({
        key: item.id,
        value: item.id,
        label: item.name
    })
    )
    return option
}

export const ArrayPost2Title = (items: any[]) => {
    const optionTitle = items.map(item => ({
        label: item.title,
        value: item.id,
        key: item.id,
    }))
    return optionTitle
}

export const Array2Tree = (items: any[]) => {
    const newNode: any = (item: any) => {
        let children = items.filter((el) => el.parentId == item.id).map((item, index) => newNode(item))
        if (children && children.length > 0) {
            return {
                ...item,
                children: children
            }
        }
        return item
    }
    let roots = items.filter((item, index) => item?.parentId == "0")
    return roots.map((item, index) => {
        return newNode(item)
    })
}
export const toCurrency = (money: number, tipe: 'VND') => {
    money = Math.ceil(money)
    switch (tipe) {
        case 'VND':
            const config = { style: 'currency', currency: 'VND', maximumFractionDigits: 9 }
            const formated = new Intl.NumberFormat('vi-VN', config).format(money);
            const rs = formated.replaceAll('.', ',')
            return rs
        default:
            return -1
    }
}

export const getYear = (option: "current" | "previous") => {
    switch (option) {
        case "current":
            return new Date().getFullYear()
        case "previous":
            return new Date().getFullYear() - 1
    }
}

export const getMonth = (option: "current") => {
    switch (option) {
        case "current":
            const m = new Date().getMonth() + 1
            return m.toString().length === 1 ? '0' + m : '' + m
        default:
            return ''
    }
}

export const getDay = (option: "current") => {
    switch (option) {
        case "current":
            const d = new Date().getDate()
            return d.toString().length === 1 ? '0' + d : '' + d
        default:
            return ''
    }
}

export const generateProductId = () => {
    const min = 1;
    const max = Number.MAX_SAFE_INTEGER;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


export const generateFormId = (ieKey: string, ppKey: string) => {
    return ieKey + "-" + ppKey
}

// prevent cross site scripting
export function escapeHtmlEntities(inputString: string) {
    if (!inputString) return ''
    return inputString.replace(/[&<>"'`]/g, function (match) {
        const htmlEntities: { [key: string]: string } = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '`': '&#x60;'
        };
        return htmlEntities[match];
    });
}

// remove html tags
export function removeTags(str: string) {
    if (!str)
        return "";
    else
        str = str.toString();
 
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
}

export const sanitizeFormValue = (formValues: any) => {
    if (!formValues) return {}
    return {
        ...formValues,
        title: removeTags(formValues.title),
        excerpt: removeTags(formValues.excerpt),
    }
}