import React, { useEffect, useState } from 'react';
import { Divider, Spin, Table, Alert, Button, Space, Modal, Popconfirm, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs'
import { EyeFilled, SettingOutlined, ContainerOutlined, EditFilled, DeleteFilled, InfoCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import '../table.css'

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const { Column } = Table

interface DataType {
    id: number;
    fileId: number;
    slug: string;
    title: string;
    excerpt: string;
    content: string;
    status: string;
    effectStatus: string;
    viewCount: number;
    downloadCount: number;
    lang: string;
    type: string;
    symbol: string;
    agencyIssued: string;
    issuedDate: dayjs.Dayjs;
    publicDate: dayjs.Dayjs;
    deleted: boolean;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}
interface Props {
    loadingData: boolean;
    data: any[];
    // handleDelete: (id: number) => void
    // initialValues: (record: any) => void
}

const columns = (
    // confirm: (id: number) => void,
    // pre: (record: DataType) => void
): ColumnsType<DataType> => [

        {
            title: '#',
            dataIndex: 'key',
            width: '5%',
            key: 'key',
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            width: '60%',
            key: 'title',
            // render: (_: any, record: DataType) => (
            //     <Space direction='vertical' size='small'>
            //         <p>{record.title} </p>
            //         <span>Loại tài liệu: {record.type}</span>
            //     </Space>
            // )
        },
        {
            title: 'Hành động',
            width: '15%',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle" direction="horizontal" wrap align='center'>
                    <Button icon={<EditOutlined />} ></Button>
                    {/* <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                        onConfirm={() => confirm(record.id)}
                        okText="Có"
                        cancelText="Không"
                    > */}
                    <Button danger icon={<DeleteOutlined />}></Button>
                    {/* </Popconfirm> */}
                </Space>
            )
        },
    ];

const TableList: React.FC<Props> = ({ loadingData, data }) => {
    const [values, setValues] = useState<Partial<DataType>>({});

    // const confirm = (id: number) => {
    //     if (handleDelete) {
    //         console.log("delete", id)
    //         handleDelete(id)
    //     }
    // };

    // const pre = (record: DataType) => {
    //     initialValues(record);
    //     setValues(record);
    // };

    return (
        <div>
            {loadingData ? (<Spin size="large">
                <Alert
                    message="Thông báo"
                    description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                    type="info"
                />
            </Spin>) : (
                <>
                    <Divider />
                    <Table dataSource={data} columns={columns()} rowKey="id"
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'} />
                </>
            )}

        </div >
    );
};

export default TableList;
