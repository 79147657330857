import { Card, Input, Form, Select, Button, Row, Col, Image } from "antd";
import React, { useEffect, useState } from "react";
import './about.css'
import { PlusOutlined, FileOutlined, DeleteOutlined } from "@ant-design/icons";
import TableAboutPost from "./tableAboutPost";

interface Props {

}


const SettingPostCard: React.FC = () => {
    const [form] = Form.useForm();

    return (
        <>
            <Card
                title="Các bài viết giới thiệu EPR (Bài đầu tiên là giới thiệu chính,tối đa 4 bài)"
                className="content_about"
                actions={[<Button type="primary" icon={<FileOutlined />}>Lưu lại</Button>]}
                headStyle={{ textAlign: "left" }}
            >
                <p style={{ textAlign: 'left', fontWeight: 'lighter', fontSize: 'small' }}>(Lưu ý: Cần nhấn nút "Lưu lại" để lưu các cài đặt bạn đã thêm)</p>

                <Form
                    style={{ width: '100%' }}
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Mô tả (Tiếng Việt)"
                                name="des"
                                help="Được hiển thị ngay trên tiêu đề bài viết giới giới thiệu khi chọn ngôn ngữ tiếng Việt"
                                initialValue='Giới thiệu chung về EPR'
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Mô tả (Tiếng Anh)"
                                name="desEN"
                                help="Được hiển thị ngay trên tiêu đề bài viết giới giới thiệu khi chọn ngôn ngữ tiếng Việt"
                                initialValue='What is EPR?'
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Danh sách bài viết (Tiếng Việt)"
                                name="post"
                            >
                                <Select placeholder="Chọn bài viết" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Danh sách bài viết (Tiếng Anh)"
                                name="postEn"
                            >
                                <Select placeholder="Chọn bài viết" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TableAboutPost />
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
};

export default SettingPostCard;