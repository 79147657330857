import React, { useState, useEffect } from "react";

import Header from "./header";
import Table from "./table";

import { useAppDispatch, useAppSelector } from "../../../redux/store";

import { list, del, fetchThumbnails } from '../postSlice';
import { list as listCategory } from '../../category/categorySlice';

import { Spin, Alert, Form, message, Button } from 'antd';
import { MediaProvider } from "../../../hooks/useMediaContext";
import { notification, Modal } from "antd";
import './postlist.css';
import dayjs from 'dayjs'
import { IComment } from "../../../types/comment";
import PostComment from "../../comment";
import { Array2Tree, treeOption, Array2Option } from "../../../util/helper"
import { IThumbnail } from "../../../types/image";
import { DataType } from "./type";

interface Props {
}

const PostList: React.FC<Props> = () => {
    const dispatch = useAppDispatch()

    const category = useAppSelector(state => state.category)
    const post = useAppSelector(state => state.post)
    const cmtPost = useAppSelector(state => state.comment)

    const [api, contextHolder] = notification.useNotification()

    const [data, setData] = useState<any[]>([]);
    const [thumbnails, setThumbnails] = useState<IThumbnail[]>([]);

    const [selectedItem, setSelectedItem] = useState<DataType | null>(null);
    const [selectedItemCmt, setSelectedItemCmt] = useState<IComment | null>(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingDataCmt, setLoadingDataCmt] = useState(false);

    const [options, setOptions] = useState<any[]>([]);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [filter, setFilter] = useState<any>({});
    //modal
    const [isModalCmtPost, setIsModalCmtPost] = useState(false)
    const [targetId, setTargetId] = useState<any>()
    const [formCmt] = Form.useForm();
    const [formCreateCmt] = Form.useForm();
    const [formPreviewCmt] = Form.useForm();
    const now = dayjs();

    useEffect(() => {

        // Get list of category 
        let pagination = {
            page: 0,
            size: 10,
        }
        dispatch(listCategory(pagination)).unwrap().then(() => {

        }).catch((error) => {
            console.error("[Error] tableList: ", error);
        })
    }, []);

    useEffect(() => {
        if (category?.items) {
            const data = Array2Tree(category?.items)
            const options = data.map((item) => treeOption(item))
            setOptions(options)
        }
    }, [category]);

    useEffect(() => {
        fetchData(page, pageSize, filter)
    }, [filter])

    const postItems = post?.items;
    useEffect(() => {
        if (postItems) {
            if (post?.pagination?.total) {
                setTotal(post?.pagination?.total)
                setPage(post?.pagination?.page)
                setPageSize(post?.pagination?.size)

            } else {
                setTotal(postItems.length)
            }
            setData(postItems)

            //update thumbnails 
            let ids = postItems.map((item: any) => item.thumbnail).filter((id: number) => id > 0)
            console.log("post_list: ids: ", ids)
            dispatch(fetchThumbnails(ids)).unwrap().then(() => {
                console.log("fetchThumbnails: ok")
            }).catch((err) => {
                console.log("fetchThumbnails: failed: ", err)
            })
        }
    }, [post?.items])
    useEffect(() => {
        if (post?.thumbnails) {
            setThumbnails(post?.thumbnails)
        }
    }, [post?.thumbnails])

    useEffect(() => {
        if (selectedItem) {
            let { title } = selectedItem
            formCmt.setFieldsValue({ title })
            formCreateCmt.setFieldsValue({ title })

        }
    }, [selectedItem])
    useEffect(() => {
        if (selectedItemCmt) {
            let { userName, userPhone, userEmail, titleByTargetId, content } = selectedItemCmt
            formPreviewCmt.setFieldsValue({ userName, userPhone, userEmail, titleByTargetId, content })
        }
    }, [selectedItemCmt])

    // const findTitleByTargetId = (targetId: number) => {
    //     const findPost = postItems?.find((postItems) => postItems.id === targetId)
    //     return findPost?.title
    // }
    const fetchData = (page: number, pageSize: number, filter: any) => {
        let pagination = {
            page: page,
            size: pageSize,
            word: filter
        }

        setLoadingData(true);
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false)
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Lấy dữ liệu thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
    }
    const onModalCmtPost = (record: DataType) => {
        setSelectedItem(record)
        setIsModalCmtPost(true)
        let targetIdCmt = {
            targetId: record.id
        };
        setTargetId(targetIdCmt)
    }

    const handleOk = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap().then(() => {
            setLoadingData(false);

            api.open({
                message: 'Thành công',
                description: 'Xóa thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {

            setLoadingData(false)
            api.open({
                message: 'Thất bại',
                description: `Xóa thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
    }

    const onSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setFilter(values)
        fetchData(page, pageSize, filter)
    };


    const onCopySLugPost = (slug: any) => {
        const link = `https://epr.monre.gov.vn/vi/bai-viet/chi-tiet/${slug}.html`
        navigator.clipboard.writeText(link)
            .then(() => {
                message.success(`Đã copy ${link}`);
            })
            .catch((error) => {
                console.error('Sao chép thất bại: ', error);
                message.error('Sao chép thất bại');
            });
    };

    const onRefresh = (values: any) => {
        console.log("Query data with filter ", values)
        setFilter(values)
        fetchData(page, pageSize, filter)
    }
    const onFetchData = (page: number, pageSize: number) => {
        fetchData(page, pageSize, filter)
    }
    return (
        <MediaProvider >
            <div className="wrapper">
                {contextHolder}
                <div className="header-list">
                    <Header
                        options={options}
                        onRefresh={onRefresh}
                        onSearch={onSearch} />
                </div>
                <div className="main-content">
                    <div className="title">
                        <span>Danh sách {total} bài viết </span>
                    </div>
                    <div className="data">
                        <Table
                            page={page}
                            pageSize={pageSize}
                            total={total}
                            fetchData={onFetchData}
                            loadingData={loadingData}
                            data={data}
                            handleDelete={handleOk}
                            onModalCmtPost={onModalCmtPost}
                            onCopySLugPost={onCopySLugPost}
                            thumbnails={thumbnails}
                        />
                    </div>
                    <Modal
                        open={isModalCmtPost}
                        onCancel={() => { setIsModalCmtPost(false) }}
                        width={1500}
                    >
                        <PostComment style={{ padding: "0px 0px 0px 0px" }} targetIdFromPost={targetId} />
                    </Modal>
                </div>
            </div>
        </MediaProvider>
    )
}

export default PostList
