import history from '../history'
import {ICategory} from "../types/category"

import fetch, {queryOne,  queryList } from "./request"

export const List = async (props: any ): Promise<any> => {
    const { page, size } = props 
    return fetch(`api/post-category?page=${page}&size=${size}`, "GET",true,{})
}


export const Create = async (info:any): Promise<ICategory> => {
    return queryOne("api/post-category", 'POST', true, info)
}

export const Delete = async (info: any): Promise<ICategory> => {
    return queryOne(`api/post-category`, 'DELETE', true, info)
}

export const Update = async (info: any): Promise<ICategory> => {
    return queryOne(`api/post-category`, 'PUT', true, info)
}

