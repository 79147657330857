import React, { useEffect, useState } from "react";
import { Select, Space, message } from 'antd';
import { useNavigate } from "react-router-dom"
import { list, create, del, update } from "./imageSlice";
import { Layout } from 'antd';
import './image.css'
import dayjs from 'dayjs'
import HeaderImage from "./header";
import TableList from "./table";
import { notification } from "antd";

import { useAppDispatch, useAppSelector } from "../../redux/store";

const { Header, Content, Sider } = Layout;

interface LayoutVideo {

}
const Image: React.FC<LayoutVideo> = ({ }) => {
    const [api, contextHolder] = notification.useNotification()
    const image = useAppSelector(state => state.image)

    const dispatch = useAppDispatch()

    const now = dayjs()
    const [data, setData] = useState<any[]>([]);

    const navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(false);
    const [modalVisible, setModalVisible] = useState(false)

    const [word, setWord] = useState<string>("");
    const [totalImage, setTotalImage] = useState<number | null>(null)


    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const imageItems = image?.items;
        if (imageItems) {
            setTotalImage(imageItems.length)
            setData(imageItems)
        }
    }, [image?.items])


    const getData = async () => {
        let pagination = {
            page: 0,
            size: 10,
            word: { word }
        }
        try {
            setLoadingData(true);
            dispatch(list(pagination)).unwrap().then(() => {
                setLoadingData(false);
            }).catch((error) => {
                api.open({
                    message: 'Thất bại',
                    description: `Lấy dữ liệu thất bại ${error}`,
                    duration: 0,
                    type: 'error'
                })
                setLoadingData(false)
            })

        } catch (error) {
            console.error("[Error] tableList: ", error);
        } finally {
            setLoadingData(false);
        }
    };

    const handleSubmitImage = async (values: any) => {
        const formValues = {
            id: values.id,
            title: values.title,
            slug: values.slug,
            excerpt: values.excerpt,
            author: values.author,
            blocked: values.blocked,
            index: values.index,
            deleted: values.deleted,
            createdBy: values.createdBy,
            updatedBy: values.updatedBy,
            createdTime: now.unix(),
            updatedTime: now.unix()
        }
        setLoadingData(true);
        dispatch(update(formValues)).unwrap().then(() => {
            api.open({
                message: 'Thành công',
                description: 'Cập nhật thành công',
                duration: 0,
                type: 'success'
            })
            // closeModalUpdate()
            getData();
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Cập nhật thất bại: ${error}`,
                duration: 0,
                type: 'error'
            })
            // closeModalUpdate()
        });

    };
    const handleSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setWord(values)

        // try {
        //     setData([]);
        //     const searchData = await Filter(values);
        //     setData(searchData);
        // } catch (error) {
        //     console.log("[ERROR] handleSearch: ", error);
        // }
    };
    const handleDeleteImage = async (id: number) => {
        if (id) {
            dispatch(del({
                id: id,
            })).unwrap().then(() => {
                api.open({
                    message: 'Thành công',
                    description: 'Xóa thành công',
                    duration: 0,
                    type: 'success'
                })
            }).catch((error) => {
                api.open({
                    message: 'Thất bại',
                    description: `Xóa thất bại ${error}`,
                    duration: 0,
                    type: 'error'
                })
            })
        }
    };

    return (
        // <ConfigProvider locale={locale}>
        <div className="wrapper">
            <div className="titleBox">
                <div>
                    <HeaderImage handleSubmitVideo={handleSubmitImage} handleSearch={handleSearch} refreshData={getData} />
                </div>
            </div>
            <div className="main-content">
                <div className="title">
                    <span>Danh sách</span>
                </div>
                <div className="data">
                    <TableList data={data} loadingData={loadingData} handleDelete={handleDeleteImage} />
                </div>
            </div>

        </div>
        // </ConfigProvider >
    )
}

export default Image
