import { Card, Input, Form, Select, Button, Row, Col, Space, Checkbox, Divider } from "antd";
import React, { } from "react";
import './ads.css'
import { PlusOutlined, FileOutlined, DeleteFilled } from "@ant-design/icons";
import CustomFormItem from "../../../post/comp/item_media";

interface Props { }

const SettingAdsCard: React.FC<Props> = () => {
    const [form] = Form.useForm();
    const { TextArea } = Input
    return (
        <div style={{ width: "100%" }}  >
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item noStyle>
                        <CustomFormItem />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Nội dung (Tiếng Việt)"
                        name="title01"
                        initialValue="Các hoạt động hình ảnh <br/> về môi trường"
                        help="Sử dụng <br/> để xuống dòng"
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn banner 01"
                        name="link_banner01"
                        initialValue="https://www.iucn.org/sites/dev/files/content/documents/epr_brochure_-_vn_-_final.pdf"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Nội dung (Tiếng Anh)"
                        name="titleEN01"
                        initialValue='cac HĐ hinh anh <br/> ve moi truong'
                        help="Sử dụng <br/> để xuống dòng"
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        name="status_display01"
                    >
                        <Checkbox>Hiển thị banner 01</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item noStyle>
                        <CustomFormItem />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Nội dung (Tiếng Việt)"
                        name="title02"
                        initialValue="CÁC HÌNH ẢNH HOẠT ĐỘNG <br/> VỀ MÔI TRƯỜNG"
                        help="Sử dụng <br/> để xuống dòng"
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn banner 02"
                        name="link_banner02"
                        initialValue="https://zingnews.vn/"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Nội dung (Tiếng Anh)"
                        name="titleEN02"
                        initialValue='CAC HINH ANH HOAT DONG <br/>  VE MOI TRUONG'
                        help="Sử dụng <br/> để xuống dòng"
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        name="status_display02"
                    >
                        <Checkbox>Hiển thị banner 02</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default SettingAdsCard;