import React, { useEffect, useState } from "react";
import { Space, Image, Select, Spin, DatePicker, DatePickerProps, Dropdown, Button } from 'antd';
import { ConfigProvider } from 'antd';
import { matchRoutes, useLocation, useNavigate, Outlet, Link } from "react-router-dom"
import { RightOutlined, CopyOutlined, UndoOutlined, StopOutlined, BookFilled, PoweroffOutlined, QuestionCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import './header.css'
import viVN from 'antd/locale/vi_VN';
import type { MenuProps } from 'antd';
import UserInfo from "./user_info"

import logo from "../../assets/image/erp-logo.png"
const { Header, Content, Sider } = Layout;
interface LayoutProps {
    menuData: any[]
}
const HeaderErp: React.FC<LayoutProps> = ({ menuData }) => {
    const [locale, setLocale] = useState(viVN)


    //   = '#2C3D83'#2C3D83
    return (
        <ConfigProvider locale={locale} >
            {/* <MainMenu setLocale={setLocale}></MainMenu> */}
            <Header className="header" >
                <div className="title" style={{ color: 'white', fontSize: "20px" }}>
                    <img src={logo} alt="Cổng thông tin EPR" />
                </div>
                <Menu mode="horizontal" items={menuData} className="menu" />
                <UserInfo />
            </Header>
        </ConfigProvider >
    )
}
export default HeaderErp
