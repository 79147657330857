import React from 'react'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs'

interface DataType {
    id: number,
    ip: number,
    last_request_time: number,
    create_time: dayjs.Dayjs;
    staff_id: number,
    token: number,
    ua: any
}
interface Props {

}

const columns: ColumnsType<DataType> = [
    {
        dataIndex: 'id',
        key: 'id',
        width: '0%'
    },
    {
        title: 'Thiết bị',
        dataIndex: 'ua',
        width: '25%',
        key: 'ua',
        render: (_: any, record: any) => (
            <>
                <span>Linux x86_64, Chrome</span>
            </>
        )
    },
    {
        title: 'Địa chỉ IP',
        key: 'ip',
        width: '25%',
        dataIndex: 'ip',
        render: (_: any, record: any) => (
            <>
                <span>	210.245.52.67</span>
            </>
        )
    },
    {
        title: 'Đăng nhập lúc',
        key: 'create_time',
        width: '25%',
        dataIndex: 'create_time',
        render: (_: any, record: any) => (
            <>
                <span>13:40:50 23/10/2023</span>
            </>
        )
    },
    {
        title: 'Hoạt động cuối lúc	',
        key: 'last_request_time',
        width: '25%',
        dataIndex: 'last_request_time',
        render: (_: any, record: any) => (
            <>
                <span>14:51:23 23/10/2023</span>
            </>
        )
    }
]
const TableActiveDevice: React.FC = () => {
    return (
        <Table columns={columns} rowKey='id' />
    )
}

export default TableActiveDevice