import React, { useEffect, useState } from "react";
import { Button, Modal, Divider, Form } from 'antd';
import { list, create, del, update, updateFromImportVideo } from "./videoSlice";
import './video.css'
import FormUpdateVideo from './formUpdateVideo';

import dayjs from 'dayjs'
import HeaderVideo from "./header";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import TableList from "./table";
import FormVideo from "./formVideo";
import { CopyFilled } from '@ant-design/icons';
import * as XLSX from 'xlsx'
import { notification } from "antd";

interface LayoutVideo {
}

interface DataType {
    id: number;
    title: string;
    slug: string;
    excerpt: string;
    author: string;
    blocked: boolean;
    index: number;
    deleted: boolean;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;

}

const Video: React.FC<LayoutVideo> = () => {
    const [api, contextHolder] = notification.useNotification()
    const dispatch = useAppDispatch()
    const video = useAppSelector(state => state.video)

    const [data, setData] = useState<any[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<DataType | null>(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    const [loadingData, setLoadingData] = useState(false);

    const [word, setWord] = useState<string>("");

    const now = dayjs();
    const nowExport = dayjs().format('DD/MM/YYYY')

    const [form] = Form.useForm()

    const [formUpdate] = Form.useForm()

    useEffect(() => {
        fetchData(page, pageSize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [word]);

    useEffect(() => {
        const videoItems = video?.items;
        if (videoItems) {

            if (video?.pagination?.total) {
                setTotal(video?.pagination?.total)
                setPage(video?.pagination?.page)
                setPageSize(video?.pagination?.size)

            } else {
                setTotal(videoItems.length)
            }
            setData(videoItems)
        }
    }, [video?.items])

    useEffect(() => {
        if (selectedItem) {
            let { title, slug, excerpt, index, updatedTime, blocked } = selectedItem;
            formUpdate.setFieldsValue({ title, slug, excerpt, index, updatedTime, blocked });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    const fetchData = (page: number, pageSize: number) => {
        let pagination = {
            page: page,
            size: pageSize,
            word: { word }
        }
        setLoadingData(true);
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false);
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Lấy dữ liệu thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
    }

    const closeModal = () => {
        setIsModalOpen(false)
        form.resetFields();
    }

    const closeModalUpdate = () => {
        setIsModalUpdateOpen(false)
        formUpdate.resetFields()
    }

    const onRefresh = (value: any) => {

        console.log("Refresh data")
        fetchData(page, pageSize)
    }

    const onNew = (value: any) => {

        setIsModalOpen(true)
    }

    const initialValues = (record: DataType) => {

        setSelectedItem(record);
        setIsModalUpdateOpen(true);
    };

    const handleSubmitVideo = async (values: any) => {
        const formValues = {
            id: values.id,
            title: values.title,
            slug: values.slug,
            excerpt: values.excerpt,
            author: values.author,
            blocked: values.blocked,
            index: values.index,
            deleted: values.deleted,
            createdBy: values.createdBy,
            updatedBy: values.updatedBy,
            createdTime: now.unix(),
            updatedTime: now.unix()
        }
        setLoadingData(true);
        dispatch(create(formValues)).unwrap()
            .then(() => {
                setLoadingData(false)
                api.open({
                    message: 'Thành công',
                    description: 'Tạo thành công',
                    duration: 0,
                    type: 'success'
                })
            }).catch((error) => {
                api.open({
                    message: 'Thất bại',
                    description: `Tạo thất bại: ${error}`,
                    duration: 0,
                    type: 'error'
                })
                setLoadingData(false)
            })
        closeModal()
    };



    const handleSearch = async (values: any) => {

        console.log("handleSearch: word ", values)
        setWord(values)
    };

    const handleDeleteVideo = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap().then(() => {
            api.open({
                message: 'Thành công',
                description: 'Xóa thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Xóa thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
    };

    const handleSave = () => {
        if (selectedItem) {
            formUpdate.validateFields()
                .then((values) => {
                    const updatedRecord = { ...selectedItem, ...values };
                    dispatch(update(updatedRecord)).unwrap().then(() => {
                        api.open({
                            message: 'Thành công',
                            description: 'Cập nhật thành công',
                            duration: 0,
                            type: 'success'
                        })
                        closeModalUpdate()
                        fetchData(page, pageSize)
                    }).catch((error) => {
                        api.open({
                            message: 'Thất bại',
                            description: `Cập nhật thất bại: ${error}`,
                            duration: 0,
                            type: 'error'
                        })
                        closeModalUpdate()
                    });
                })
        }
    };
    const getMaxWidth = (property: keyof DataType, rows: DataType[], defaultValue = 10): number =>
        rows.reduce((maxWidth, row) => Math.max(maxWidth, String(row[property]).length), defaultValue);

    const header: (keyof DataType)[] = ["id", "title", "slug", "excerpt", "author", "index", "blocked", "deleted",
        "createdBy", "updatedBy", "createdTime", "updatedTime"];

    const exportData = () => {
        const rows: DataType[] = data.map(row =>
            header.reduce((acc: any, key) => {
                acc[key] = row[key];
                return acc;
            }, {} as DataType)
        );

        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');

        XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });

        if (!ws["!cols"]) {
            ws["!cols"] = [];
        }

        header.forEach((property, index) => {
            if (ws["!cols"]) {
                ws["!cols"][index] = { wch: getMaxWidth(property, rows) };
            }
        });

        XLSX.writeFile(wb, `Video ${nowExport}.xlsx`);
    };


    const arraysEqual = (a: any[], b: any[]): boolean => {
        return JSON.stringify(a) === JSON.stringify(b);
    };


    const importData = (file: File) => {

        const reader = new FileReader();
        reader.onload = (e: any) => {
            const dataFromXLSX = new Uint8Array(e.target.result);
            const wb = XLSX.read(dataFromXLSX, { type: 'array' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const jsonData: any[][] = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const headerOfXLSX = jsonData[0]
            const convertExcelDataToDataType = (excelData: any[][]): any[] => {
                const arrayOfObjects = excelData.slice(1).map(array => {
                    return array.reduce((obj: any, value, index) => {
                        obj[header[index]] = value;
                        return obj;
                    }, {});
                });

                return arrayOfObjects;
            };

            if (!arraysEqual(header, headerOfXLSX)) {
                api.open({
                    message: 'Import failed',
                    type: 'error',
                    description: `Excel Import ${file.name} is not in the correct format`
                })
                console.log('falied', headerOfXLSX)
                return data
            } else {
                const dataImport = convertExcelDataToDataType(jsonData);
                dispatch(updateFromImportVideo(dataImport))
                api.open({
                    message: "Import success",
                    type: 'success'
                })
                console.log('success', headerOfXLSX)

            }
        };
        reader.readAsArrayBuffer(file)
    };

    const onImport = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            importData(file)
        }
    }
    return (

        <div className="wrapper">
            {contextHolder}
            <div className="titleBox">
                <div >
                    <HeaderVideo
                        onExport={exportData}
                        onNew={onNew}
                        onSearch={handleSearch}
                        onRefresh={onRefresh}
                        onImport={onImport}
                        total={total} />
                </div>
            </div>
            <div className="main-content">
                <div className="title">
                    <span>Danh sách {total} video</span>
                </div>
                <div className="data">
                    <TableList
                        page={page}
                        pageSize={pageSize}
                        total={total}
                        fetchData={fetchData}
                        data={data}
                        loadingData={loadingData}
                        handleDelete={handleDeleteVideo}
                        initialValues={initialValues}
                    />
                </div>
            </div>
            <Modal title="Thêm mới"
                open={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button
                        onClick={closeModal}>Đóng</Button>,
                    <Button
                        key="submit"
                        htmlType="submit"
                        type="primary"
                        icon={<CopyFilled />}
                        onClick={form.submit}>Lưu lại</Button>
                ]}>
                <Divider />
                <FormVideo
                    handleSubmitVideo={handleSubmitVideo}
                    form={form} />
            </Modal>
            <Modal
                title="Chỉnh sửa"
                open={isModalUpdateOpen}
                onCancel={() => { setIsModalUpdateOpen(false) }}
                width={1000}
                footer={[
                    <Button
                        key="close"
                        onClick={() => { setIsModalUpdateOpen(false) }}>
                        Đóng
                    </Button>,
                    <Button key="submit"
                        htmlType="submit"
                        type="primary"
                        icon={<CopyFilled />}
                        onClick={formUpdate.submit}>Lưu lại</Button>

                ]}
            >
                <Divider />
                <FormUpdateVideo
                    form={formUpdate}
                    handleUpdate={handleSave} />
            </Modal>
        </div>
    )
}

export default Video
