import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';

import { Col, Image, Row, Popconfirm, Checkbox } from 'antd';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Alert, Space, Spin, Button, Card } from 'antd'; // DONT PUSH ME
import type { UploadProps } from 'antd';
const { Dragger } = Upload;


const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader?.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

const ImageList = (props: any) => {
    const {
        loading,
        setLoading,
        uploadFile,
        images,
        selectedImage,
        setSelectedImage,
        selectedFolder,
        multiple,
        crop,
        aspect
    } = props
    const [fileList, setFileList] = useState<any[]>([]);
    const [/* fileListBase64 */, setFileListBase64] = useState<any[]>([]);
    const uploadButton = (
        <div style={{ width: "100%" }}>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onSelect = (event: any, file: any) => {
        // console.log("onSelect: event ", event)
        // console.log("onSelect: event.target ", event?.target)
        let nimgs = images.filter((item: any) => item.uid == file.uid)
        if (setSelectedImage && nimgs?.length > 0) {
            // console.log("Set select image: ", nimgs[0])
            setSelectedImage(nimgs[0])

        }
    }
    const handleDelete = (file: any) => {
        console.log("Handle delete file", file)
    }
    const CustomImage = (props: any) => {
        const { file } = props
        return (
            <div className="epr-cms-image-section-container">
                <div className="epr-cms-image-container"
                    onClick={(event: any) => { onSelect(event, file) }}
                >
                    <Image
                        width={60}
                        height={60}
                        src={file.url}
                        className='image'
                        preview={false}
                    />
                </div>
                <div className="epr-cms-image-title">
                    <span>{file.name}</span>
                </div>
            </div>)
    }
    const propsUpload: UploadProps = {
        name: 'file',
        listType: "picture-card",
        fileList: images,
        // onChange={handleChange}
        customRequest: uploadFile,
        itemRender: (originNode, file, currFileList) => {
            return (
                <label>
                    <CustomImage file={file} />
                </label>
            )
        }
    }
    const propsDrag: UploadProps = {
        name: 'file',
        multiple: true,
        customRequest: uploadFile,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log("multiple: uploading", info.file, info.fileList);
            }
            if (status === 'done') {
                console.log("multiple: done", info.file, info.fileList);

                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log("multiple: failed", info.file, info.fileList);

                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        // directory: true,
        fileList: fileList,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const ListImages = () => {
        return (
            <div style={{display: "flex", flexWrap: "wrap", gap: "8px"}}>
                {
                    images.map((item: any, index: number) => {
                        return (
                            <div key={index} >
                                <CustomImage file={item} />
                            </div>
                        )
                    })
                }
            </div>
        // <Flex wrap="wrap" gap="small" >
        //     {
        //         images.map((item: any, index: number) => {
        //             return (
        //                 <div key={index} >
        //                     <CustomImage file={item} />
        //                 </div>
        //             )
        //         })
        //     }
        // </Flex>
        )
    }
    return (
        <Col className="gutter-row" span={selectedImage ? 14 : 19}>
            <Row style={{ width: "100%", backgroundColor: "#c5c6d0", padding: "8px" }}>
                <div style={{}}># Media </div>
            </Row>
            <div >
                {
                    loading && <Space direction="vertical" style={{ width: '100%' }}>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </Space>
                }
                {
                    !multiple && crop && (
                        <ImgCrop aspect={aspect ? aspect : 1} rotationSlider>
                            <Upload {...propsUpload}
                            >
                                {uploadButton}
                            </Upload>
                        </ImgCrop>
                    )
                }
                {
                    !multiple && !crop && (
                        <Upload
                            {...propsUpload}
                        >

                            {uploadButton}
                        </Upload>
                    )
                }
                {
                    multiple && !crop && (
                        <Card>
                            <ListImages />
                            <ImgCrop aspect={aspect ? aspect : 1} rotationSlider>
                                <Dragger {...propsDrag}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </ImgCrop>
                        </Card>
                    )
                }
                {
                    multiple && crop && (
                        <Card>
                            <ListImages />
                            <Dragger {...propsDrag}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>

                            </Dragger>
                        </Card>
                    )
                }

            </div >
        </Col >
    );
};

export default ImageList;