import { Card, Input, Form, Select, Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import './home.css'
import { PlusOutlined, FileOutlined } from "@ant-design/icons";
import TableConsultanBox from "./tableConsultan";


interface Props {

}


const SettingConsultationCard: React.FC = () => {
    const [form] = Form.useForm();
    const { TextArea } = Input
    return (
        <Card
            title="Box tin tham vấn và góp ý"
            className="content_home"
            actions={[<Button type="primary" icon={<FileOutlined />}>Lưu lại</Button>]}
            headStyle={{ textAlign: "left" }}
        >
            <Form
                style={{ width: '100%' }}
                form={form}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Danh sách bài viết (Tiếng Việt)"
                            name="post"
                        >
                            <Select placeholder="Chọn bài viết" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Danh sách bài viết (Tiếng Anh)"
                            name="postEn"
                        >
                            <Select placeholder="Chọn bài viết" />

                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <TableConsultanBox />
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default SettingConsultationCard;