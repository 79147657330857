import React, { useRef, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { useTranslation } from 'react-i18next'

type EditorFormItemProps = {
    value?: string
    onChange?: (value: string) => void
};


const EditorFormItem: React.FC<EditorFormItemProps> = ({ value, onChange }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });
    const [editorHtml, setEditorHtml] = useState('');
    const editorRef = useRef(null);

    useEffect(() => {
        if (value && value != editorHtml) {
            setEditorHtml(value)
        }
    }, [value])


    return <Editor
        apiKey="guty43qdrsah6uhdublyngx2sxmnsisrcaee5ly06tfcm3gg"
        onInit={(evt: any, editor: any) => editorRef.current = editor}
        value={editorHtml}
        init={{
            height: 500,
            menubar: false,
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | code | ' +
                "table  image media  wordcount |" +
                'removeformat | help fullscreen',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        onEditorChange={(newValue, editor) => {
            setEditorHtml(newValue);
            // setText(editor.getContent({ format: 'html' }));
            if (onChange) {
                onChange(newValue)
            }
        }}
    />;

}

export default EditorFormItem