import type { MenuProps } from 'antd';
import { RightOutlined, CopyOutlined, UndoOutlined, StopOutlined, BookFilled, PoweroffOutlined, QuestionCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import { Link } from "react-router-dom"


export const dropMenuItems: MenuProps['items'] = [
    {
        key: '5.1',
        label: 'Thông tin cá nhân',
        icon: <UserOutlined />
    },
    {
        key: '5.2',
        label: 'Cập nhật hệ thống',
        icon: <UndoOutlined />
    },
    {
        key: '5.3',
        label: 'Hỗ trợ báo lỗi',
        icon: <StopOutlined />
    },
    {
        key: '5.4',
        label: 'Hướng dẫn sử dụng',
        icon: <BookFilled />
    },
    {
        key: '5.5',
        label: <Link to={'/login'} >Đăng xuất</Link>,
        icon: <PoweroffOutlined />
    },
] as MenuProps['items']

const UserInfo = () => {

    return (<Dropdown menu={{ items: dropMenuItems }} placement="bottom" >
        <Button type="text" icon={<UserOutlined style={{ color: 'white' }} />}>
            <span style={{ color: 'white' }}>ERP Admin</span>
        </Button>
    </Dropdown>)
}
export default UserInfo