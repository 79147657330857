import React, { useState, useEffect } from "react";

import Header from "./header";
import Table from "./table";

import { useAppDispatch, useAppSelector } from "../../../redux/store";

import { list, del, fetchThumbnails } from '../articleSlice';
import { Spin, Alert, Form, message, Button } from 'antd';
import { MediaProvider } from "../../../hooks/useMediaContext";
import { notification, Modal } from "antd";
import { DataType } from "./type";
import dayjs from 'dayjs'

import { Array2Tree, treeOption, Array2Option } from "../../../util/helper"
import { IThumbnail } from "../../../types/image";

import './index.css';

interface Props {
}

const ArticleList: React.FC<Props> = () => {
    const dispatch = useAppDispatch()

    const article = useAppSelector(state => state.article)

    const [api, contextHolder] = notification.useNotification()

    const [data, setData] = useState<any[]>([]);
    const [thumbnails, setThumbnails] = useState<IThumbnail[]>([]);

    const [selectedItem, setSelectedItem] = useState<DataType | null>(null);
    const [loadingData, setLoadingData] = useState(false);

    const [options, setOptions] = useState<any[]>([]);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [filter, setFilter] = useState<any>({});
    //modal
    const [targetId, setTargetId] = useState<any>()
    const [formCmt] = Form.useForm();
    const [formCreateCmt] = Form.useForm();
    const now = dayjs();

    // useEffect(() => {
    // }, []);

    useEffect(() => {
        fetchData(page, pageSize, filter)
    }, [filter])

    useEffect(() => {
        if (article?.items) {
            if (article?.pagination?.total) {
                setTotal(article?.pagination?.total)
                setPage(article?.pagination?.page)
                setPageSize(article?.pagination?.size)

            } else {
                setTotal(article.items?.length)
            }
            setData(article.items)

            //update thumbnails 
            let ids = article.items.map((item: any) => item.thumbnail).filter((id: number) => id > 0)
            console.log("article: ids: ", ids)
            dispatch(fetchThumbnails(ids)).unwrap().then(() => {
                console.log("fetchThumbnails: ok")
            }).catch((err) => {
                console.log("fetchThumbnails: failed: ", err)
            })

        }
    }, [article?.items])

    useEffect(() => {
        if (article?.thumbnails) {
            setThumbnails(article?.thumbnails)
        }
    }, [article?.thumbnails])

    useEffect(() => {
        if (selectedItem) {
            let { title } = selectedItem
            formCmt.setFieldsValue({ title })
            formCreateCmt.setFieldsValue({ title })

        }
    }, [selectedItem])



    const fetchData = (page: number, pageSize: number, filter: any) => {
        let pagination = {
            page: page,
            size: pageSize,
            word: filter
        }
        setLoadingData(true);
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false)
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Lấy dữ liệu thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
    }
    const handleNew = (record: DataType) => {
        setSelectedItem(record)
        let targetIdCmt = {
            targetId: record.id
        };
        setTargetId(targetIdCmt)
    }

    const handleOk = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap().then(() => {
            setLoadingData(false);

            api.open({
                message: 'Thành công',
                description: 'Xóa thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {

            setLoadingData(false)
            api.open({
                message: 'Thất bại',
                description: `Xóa thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
    }

    const onSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setFilter(values)
        fetchData(page, pageSize, filter)
    };


    const handleCopy = (link: any) => {
        // const link = `https://epr.monre.gov.vn/vi/bai-viet/chi-tiet/${slug}.html`
        navigator.clipboard.writeText(link)
            .then(() => {
                message.success(`Đã copy ${link}`);
            })
            .catch((error) => {
                console.error('Sao chép thất bại: ', error);
                message.error('Sao chép thất bại');
            });
    };

    const onRefresh = (values: any) => {
        console.log("Query data with filter ", values)
        setFilter(values)
        fetchData(page, pageSize, filter)
    }
    const onFetchData = (page: number, pageSize: number) => {
        fetchData(page, pageSize, filter)
    }
    return (
        <MediaProvider >
            <div className="wrapper">
                {contextHolder}
                <div className="header-list">
                    <Header
                        options={options}
                        onRefresh={onRefresh}
                        onSearch={onSearch} />
                </div>
                <div className="main-content">
                    <div className="title">
                        <span>Danh sách {total} bài báo </span>
                    </div>
                    <div className="data">
                        <Table
                            page={page}
                            pageSize={pageSize}
                            total={total}
                            fetchData={onFetchData}
                            loadingData={loadingData}
                            data={data}
                            handleDelete={handleOk}
                            handleNew={handleNew}
                            handleCopy={handleCopy}
                            thumbnails={thumbnails}
                        />
                    </div>

                </div>
            </div>
        </MediaProvider>
    )
}

export default ArticleList
