import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICategory  } from '../../types/category';
import { IPagination  } from '../../types/pagination';

import { List, Create,  Delete, Update } from "../../services/category"
import { RootState } from '../../redux/store';

export interface CategoryState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  items?: ICategory[];
  pagination?: IPagination;
  error?: string;
}

const initialState: CategoryState = {
  status: 'idle',
  items: []
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(list.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(list.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { data, extra } = action.payload
        state.items = data
        state.pagination = extra
      })
      .addCase(list.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message + " with error: " + action.payload
      })
      .addCase(create.fulfilled, (state, action) => {
        state.status = 'succeeded'
        let item = action.payload
        if (item) {
          state.items?.unshift(item)
        }
      })
      .addCase(del.fulfilled, (state, action) => {
        state.status = 'succeeded'
        let item = action.payload
        state.items = state.items?.filter((el)=> el.id != item.id)
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'succeeded';
        let updatedItem = action.payload;
        let updatedItemId = updatedItem.id;
        state.items = state.items?.map((item: any) => {
            if (item.id === updatedItemId) {
                return updatedItem
            } 
            else {
                return item;
            }
        });
    });
  }
})

export const list = createAsyncThunk('category/list', async (info: any, { rejectWithValue }) => {
  try {
    const response = await List(info)
    return response
  } catch (ex) {
    return rejectWithValue(ex)
  }
})
export const create = createAsyncThunk('category/create', async (info: any, { rejectWithValue }) => {
  try {
    const response = await Create(info)
    return response
  } catch (ex) {
    return rejectWithValue(ex)
  }
})

export const del = createAsyncThunk('category/delete', async (info: any, { rejectWithValue }) => {
  try {
    const response = await Delete(info)
    return response
  } catch (ex) {
    return rejectWithValue(ex)
  }
})

export const update = createAsyncThunk('video/update', async(info:any, {rejectWithValue}) => {
  try {
      const response = await Update(info)
      return response 
  } catch (ex) {
      return rejectWithValue(ex)
  }
})

export const selectCategory = (state: RootState) => state.category

export default categorySlice.reducer