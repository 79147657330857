import React from "react";
import HeaderDetailDoc from "./headerDetailDoc";
import TableDetailDoc from "./tableDetailDoc";

interface FormDetailDoc {
    loadingData: boolean;
    data: any[];
    onSearch?: (values: any) => void;
    onNew?: (values: any) => void;
    onRefresh?: (values: any) => void
}

const FormDetailDoc: React.FC<FormDetailDoc> = ({ loadingData, data, onRefresh, onNew, onSearch }) => {
    return (
        <>
            <HeaderDetailDoc onRefresh={onRefresh} onNew={onNew} onSearch={onSearch} />
            <TableDetailDoc data={data} loadingData={loadingData} />
        </>
    )
}

export default FormDetailDoc