import React, { useEffect, useState } from 'react';
import { Divider, Tag, Table, Button, Space, Popconfirm, Spin, Alert, Input } from 'antd';
import dayjs from 'dayjs';
import { EditFilled, DeleteFilled, CommentOutlined, CopyOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import '../../table.css';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import { useMediaContext } from '../../../hooks/useMediaContext';
import { update as UpdatePriority } from '../articleSlice'
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { notification } from 'antd';
import { IThumbnail } from '../../../types/image';

import { columns } from "./columns"
import { DataType } from "./type"

dayjs.extend(relativeTime);

interface Props {
    loadingData: boolean;
    data: DataType[];
    handleDelete: (id: number) => void;
    handleNew: (record: DataType) => void
    handleCopy: (url: string) => void
    total: number
    pageSize: number
    page: number
    fetchData: (page: number, pageSize: number) => void,
    thumbnails: IThumbnail[]
}


const TableList: React.FC<Props> = ({
    data,
    handleDelete,
    handleNew,
    handleCopy,
    loadingData,
    total,
    pageSize,
    page,
    fetchData,
    thumbnails,
    // pageSize, currentPage, handlePageChange
}) => {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    // const paginatedData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize)

    const onNew = (record: DataType) => {
        if (handleNew) {
            handleNew(record)
        }
    }

    const confirm = (id: number) => {
        if (handleDelete) {
            console.log('delete', id);
            handleDelete(id);
        }
    };


    const copy = (slug: string) => {
        if (handleCopy) {
            handleCopy(slug)
        }
    }
    const findUrlbyThumbnail = (id: number, url: string) => {
        if (url && url != "") {
            return <img
                src={url}
                alt="Thumbnail"
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />;
        }
        const findUrl = thumbnails.find((file: IThumbnail) => file.id === id);
        if (findUrl) {
            return <img
                src={findUrl?.url}
                alt="Thumbnail"
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />;
        }
        return (
            <img
                src='https://thenounproject.com/api/private/icons/741653/edit/?backgroundShape=SQUARE&backgroundShapeColor=%23000000&backgroundShapeOpacity=0&exportSize=752&flipX=false&flipY=false&foregroundColor=%23000000&foregroundOpacity=1&imageFormat=png&rotation=0'
                alt="Thumbnail"
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
        );
    };

    const update = (record: any) => {
        navigate(`/article/update/${record}`);
    };
    const updatePriority = (id: number, value: number) => {
        console.log(`Update priority of record: ${id} to ${value} `)
        const updateValues = {
            id: id,
            priority: value
        }
        dispatch(UpdatePriority(updateValues)).
            unwrap().
            then(() => {
                api.open({
                    message: `Cập nhật ưu tiên thành công`,
                    description: 'Thành công',
                    duration: 0,
                    type: 'success',
                });
            })
            .catch((error: any) => {
                api.open({
                    message: 'Thất bại',
                    description: `Cập nhật thất bại: ${error}`,
                    duration: 0,
                    type: 'error'
                })
            })
    }
    return (
        <div>

            {loadingData ? (<Spin size="large">
                <Alert
                    message="Thông báo"
                    description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                    type="info"
                />
            </Spin>) : (
                <>
                    <Divider />
                    {contextHolder}
                    <Table
                        dataSource={data}
                        columns={columns(confirm, update, onNew, findUrlbyThumbnail, copy, updatePriority)}
                        rowKey="id"
                        rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
                        pagination={{
                            total: total,
                            onChange: (page, pageSize) => {
                                fetchData(page, pageSize);
                            },
                            current: page,
                            pageSize: pageSize,
                            // defaultPageSize: 10,
                            // showSizeChanger: true,
                            // pageSizeOptions: ['10', '20', '30']
                        }}
                    />
                </>
            )}


        </div>
    );
};

export default TableList;
