import React from "react";
import { Input, Form, FormInstance, Select, Checkbox } from 'antd';
import './keyword.css'
import { option_lang } from "../../data/data";
import { slugify } from "../../util/helper";


interface UpdateKeyword {
    form: FormInstance<any>,
    handleUpdate: (values: any) => void
}

const FormUpdateKeyword: React.FC<UpdateKeyword> = (props) => {
    const { form, handleUpdate } = props
    const onFinish = (values: any) => {
        if (handleUpdate) {
            handleUpdate(values)
        }
    }

    const onChangeSlug = () => {
        let title = form?.getFieldValue("name")
        if (title) {
            let slug = slugify(title)
            form?.setFieldValue("slug", slug)
        }
    }

    const { TextArea } = Input
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='type'
                label="Loại thẻ"
                help="Chuyên mục khác với thẻ, bạn có thể sử dụng nhiều cấp chuyên mục. Ví dụ: Trong chuyên mục nhạc, bạn có chuyên mục con là nhạc Pop, nhạc Jazz. Việc này hoàn toàn là tùy theo ý bạn. Hỗ trợ tối đa 3 cấp cha-con">
                <Select options={option_lang} />
            </Form.Item>
            <Form.Item
                name='name'
                label="Tên thẻ"
                rules={[{ required: true, message: 'Tên chuyên mục không được để trống.' }]}
                help="Tên riêng sẽ hiển thị trên website">
                <Input onChange={onChangeSlug} />
            </Form.Item>
            <Form.Item
                name='slug'
                label="Đường dẫn "
                rules={[{ required: true, message: 'Đường dẫn không được để trống.' }]}
                help="Chuỗi cho đường dẫn tĩnh là phiên bản của tên hợp chuẩn với Đường dẫn (URL). Chuỗi này bao gồm chữ cái thường, số và dấu gạch ngang (-).">
                <Input />
            </Form.Item>
            <Form.Item
                name='blocked'>
                <Checkbox> Tạm ẩn trên website</Checkbox>
            </Form.Item>
        </Form>
    )
}

export default FormUpdateKeyword