import React, { useEffect, useState } from 'react';
import { Divider, Spin, Table, Alert, Button, Space, Modal, Popconfirm, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs'
import { EyeFilled, CopyFilled, MessageFilled, EditFilled, DeleteFilled, InfoCircleOutlined } from '@ant-design/icons';
import '../table.css'

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const { Column } = Table

interface DataType {
    id: number;
    slug: string;
    name: string;
    blocked: boolean;
    postCount: number;
    type: string;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}
interface Props {
    loadingData: boolean;
    data: any[];
    handleDelete: (id: number) => void;
    initialValues: (record: any) => void
    total: number
    pageSize: number
    page: number
    fetchData: (page: number, pageSize: number) => void
}

const columns = (
    confirm: (id: number) => void,
    pre: (record: DataType) => void

): ColumnsType<DataType> => [
        {
            title: '#',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: 'Tên thẻ',
            dataIndex: 'name',
            width: '25%',
            key: 'name',
        },
        {
            title: 'Đường dẫn',
            dataIndex: 'slug',
            width: '50%',
            key: 'slug',
        },
        {
            title: 'Số bài viết',
            dataIndex: 'postCount',
            key: 'postCount',
            width: "10%",
        },
        {
            title: 'Hành động',
            width: '15%',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle" direction="horizontal" wrap align='center'>
                    <Button icon={<EditFilled />} onClick={() => pre(record)}></Button>
                    <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                        onConfirm={() => confirm(record.id)}
                        // onCancel={cancel}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button danger icon={<DeleteFilled />}></Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

const TableList: React.FC<Props> = ({
    loadingData,
    data,
    handleDelete,
    initialValues,
    total,
    pageSize,
    page,
    fetchData,
}) => {

    const confirm = (id: number) => {
        if (handleDelete) {
            console.log("delete", id)
            handleDelete(id)
        }
    };

    const pre = (record: DataType) => {
        initialValues(record);
    };

    return (
        <div>
            {loadingData ? (<Spin size="large">
                <Alert
                    message="Thông báo"
                    description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                    type="info"
                />
            </Spin>) : (
                <>
                    <Divider />
                    <Table
                        dataSource={data}
                        columns={columns(confirm, pre)} rowKey="id"
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}

                        pagination={{
                            total: total,
                            onChange: (page, pageSize) => {
                                fetchData(page, pageSize);
                            },
                            current: page,
                            pageSize: pageSize,
                            // defaultPageSize: 10,
                            showSizeChanger: true,
                            // pageSizeOptions: ['10', '20', '30']
                        }}

                    />

                </>
            )}

        </div >
    );
};

export default TableList;
