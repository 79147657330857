import React, { useEffect, useState } from "react";
import { Space, Image, Select, Spin, DatePicker, DatePickerProps } from 'antd';
import { ConfigProvider } from 'antd';
import { Layout, Menu, theme } from 'antd';
import './sider.css'

import viVN from 'antd/locale/vi_VN';

const { Sider } = Layout;

interface LayoutProps {
    items: any[]
    selectedKeys?: string[]
}
const SiderErp: React.FC<LayoutProps> = ({ items, selectedKeys }) => {
    const [locale, setLocale] = useState(viVN)
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <ConfigProvider locale={locale}>
            {/* <MainMenu setLocale={setLocale}></MainMenu> */}
            <Sider className="sider" width={'11%'} style={{ background: colorBgContainer }}>
                <Menu
                    //  className="menuSider"
                    mode="inline"
                    selectedKeys={selectedKeys}
                    style={{ height: '100vh', borderRight: 0, textAlign: 'left' }}
                    items={items}
                />
            </Sider>
        </ConfigProvider >
    )
}

export default SiderErp
