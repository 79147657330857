import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';

import { Col, Image, Row, Popconfirm, Checkbox, List, Avatar } from 'antd';
import { PlusOutlined, InboxOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Alert, Space, Spin, Button, Card } from 'antd'; // DONT PUSH ME
import type { UploadProps } from 'antd';
import { formatTime } from './helper';
const { Dragger } = Upload;


const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader?.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

const FileList = (props: any) => {
    const {
        loading,
        setLoading,
        uploadFile,
        fileDocs,
        selectedFileDocs,
        setSelectedFileDocs,
        multiple,
    } = props
    const [fileList, setFileList] = useState<any[]>([]);
    const [/* fileListBase64 */, setFileListBase64] = useState<any[]>([]);

    const onSelect = (event: any, file: any) => {
        event.preventDefault();
        // console.log("onSelect: event ", event)
        // console.log("onSelect: event.target ", event?.target)
        let nimgs = fileDocs.filter((item: any) => item.uid == file.uid)
        if (setSelectedFileDocs && nimgs?.length > 0) {
            // console.log("Set select file: ", nimgs[0])
            setSelectedFileDocs(nimgs[0])

        }
    }
    const handleDelete = (file: any) => {
        console.log("Handle delete file", file)
    }
    const propsDrag: UploadProps = {
        name: 'file',
        multiple: true,
        listType: "text",
        customRequest: uploadFile,
        accept: ".txt, .pdf, application/pdf",
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log("multiple: uploading", info.file, info.fileList);
            }
            if (status === 'done') {
                console.log("multiple: done", info.file, info.fileList);

                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log("multiple: failed", info.file, info.fileList);

                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        fileList: fileList,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const ListImages = () => {
        const FileIcon = () => {
            return (
                <span style={{color: "black", fontSize: "2em"}}><FilePdfOutlined /></span>
            )
        }
        const FileDescription = (props: any) => {
            const {url, size, createdAt, updateAt} = props
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <span>{url}</span>
                    {/* <span>size: {size}</span> */}
                    <span>created at: {formatTime(createdAt)}</span>
                    {/* <span>updated at: {formatTime(updateAt)}</span> */}
                </div>
            )
        }
 
        return (
            <List 
                itemLayout='horizontal'
                dataSource={fileDocs}
                renderItem={(item: any, index: number) => (
                    <List.Item>
                        <List.Item.Meta
                        avatar={<FileIcon />}
                        title={<a onClick={(event) => onSelect(event, item)}>{item.name}</a>}
                        description={<FileDescription
                                url={item.url}
                                size={item.size}
                                createdAt={item.createdAt}
                                updateAt={item.updateAt}
                            />}
                        />
                    </List.Item>
                )}
            />
        )
    }
    return (
        <Col className="gutter-row" span={selectedFileDocs ? 14 : 19}>
            <Row style={{ width: "100%", backgroundColor: "#c5c6d0", padding: "8px" }}>
                <div style={{}}># File </div>
            </Row>
            <div >
                {
                    loading && <Space direction="vertical" style={{ width: '100%' }}>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </Space>
                }
                {
                    multiple && (
                        <Card>
                                <Dragger {...propsDrag}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            <ListImages />
                        </Card>
                    )
                }

            </div >
        </Col >
    );
};

export default FileList;