import React, { useState } from 'react';
import { Divider, Spin, Table, Alert, Button, Space, Popconfirm, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs'
import { EditOutlined, ContainerOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import '../table.css'
import { IDoc } from '../../types/doc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);


interface Props {
    loadingData: boolean;
    data: any[];
    handleDelete: (id: number) => void;
    initialValues: (record: any) => void;
    createDoc: (record: any) => void;
    total: number
    pageSize: number
    page: number
    fetchData: (page: number, pageSize: number) => void
}

const columns = (
    confirm: (id: number) => void,
    pre: (record: IDoc) => void,
    detail: (record: IDoc) => void

): ColumnsType<IDoc> => [

        {
            title: '#',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            width: '60%',
            key: 'title',
            render: (_: any, record: IDoc) => (
                <Space direction='vertical' size='small'>
                    <p>{record.title} </p>
                    <span>Loại tài liệu: {record.type}</span>
                </Space>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (_: any, record: any) => {
                if (record.status === "public" || record.status === "Công khai") {
                    return (
                        <Tag color='green'>
                            Công khai
                        </Tag>
                    )
                } else {
                    return (
                        <Tag color='gray'>Bản nháp</Tag>
                    )
                }

            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'publicDate',
            key: 'publicDate',
            width: "10%",
            render: (_: any, record: IDoc) => (
                <>
                    <p>
                        <span style={{ fontWeight: 'lighter', fontSize: '12px' }}>
                            Ngày lên website:
                        </span>
                        {dayjs((Number(record.publicDate) * 1000)).format('HH:MM DD/MM/YYYY')}
                    </p>
                </>
            )
        },
        {
            title: 'Thống kê',
            dataIndex: 'viewCount',
            key: 'viewCount',
            render: (_: any, record: any) => (
                <>
                    <p>Lượt xem: {record.viewCount}</p>
                    <p>Lượt tải: {record.downloadCount}</p>
                </>
            )
        },
        {
            title: 'Hành động',
            width: '10%',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle" direction="horizontal" wrap align='center'>
                    <a href={`https://epr.monre.gov.vn/vi/thu-vien/${record.type}.html`} target="_blank" rel="noopener noreferrer">
                        <Button icon={<EyeOutlined />}></Button>
                    </a>
                    <Button icon={<ContainerOutlined />} onClick={() => detail(record.id)}></Button>
                    <Button icon={<EditOutlined />} onClick={() => pre(record)}></Button>
                    <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                        onConfirm={() => confirm(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button danger icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

const TableList: React.FC<Props> = ({
    loadingData,
    data,
    handleDelete,
    initialValues,
    createDoc,
    total,
    pageSize,
    page,
    fetchData, }) => {
    const [values, setValues] = useState<Partial<IDoc>>({});

    const confirm = (id: number) => {
        if (handleDelete) {
            console.log("delete", id)
            handleDelete(id)
        }
    };

    const pre = (record: IDoc) => {
        initialValues(record);
    };
    const detail = (record: IDoc) => {
        createDoc(record)
    }

    return (
        <div>
            {loadingData ? (<Spin size="large">
                <Alert
                    message="Thông báo"
                    description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                    type="info"
                />
            </Spin>) : (
                <>
                    <Divider />
                    <Table
                        dataSource={data}
                        columns={columns(confirm, pre, detail)}
                        rowKey="id"
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                        pagination={{
                            total: total,
                            onChange: (page, pageSize) => {
                                fetchData(page, pageSize);
                            },
                            current: page,
                            pageSize: pageSize,
                            showSizeChanger: true,
                        }}
                    />
                </>
            )}

        </div >
    );
};

export default TableList;
