import React from 'react';
import { Divider, Spin, Table, Alert, Button, Space, Popconfirm, Typography } from 'antd';
import dayjs from 'dayjs'
import { DeleteFilled } from '@ant-design/icons';
import '../table.css'
import type { ColumnsType } from 'antd/es/table';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAppSelector } from '../../redux/store';
import { IComment as DataTypeComment } from "../../types/comment";
dayjs.extend(relativeTime);

interface Props {
    data: DataTypeComment[];
    loadingData: boolean;
    handleDeleteComment: (id: number) => void;
    onNew: (id: number) => void;
    onReply: (record: DataTypeComment) => void;
    initialValues: (record: DataTypeComment) => void;
    total: number
    pageSize: number
    page: number
    fetchData: (page: number, pageSize: number) => void
}


const columns = (
    confirm: (id: number) => void,
    initialValues: (record: DataTypeComment) => void,
    onNew: (id: number) => void,
    onReply: (record: DataTypeComment) => void,
): ColumnsType<DataTypeComment> => [
        {
            title: '#',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: "Bình luận",
            dataIndex: "content",
            width: '70%',
            key: 'title',
            render: (_: any, record: DataTypeComment) => (
                <Space direction='vertical' size='small'>
                    <p>{record.content}</p>
                    <Typography>Bài viết: {record.titleByTargetId}</Typography>
                    <p>Tác giả: {record.userName}</p>
                    <p>Tổng số trả lời: {record.adminReply}</p>
                </Space>
            )
        },
        {
            title: "Thời gian",
            dataIndex: "createdTime",
            key: "createdTime",
            width: '70%',
            render: (_: any, record: DataTypeComment) => (
                <>
                    <p>
                        <span style={{ fontWeight: 'lighter', fontSize: '12px' }}>
                            Ngày lên website: &nbsp;
                        </span>
                        {dayjs(Number(record.createdTime) * 1000).format('HH:mm DD/MM/YYYY')}
                    </p>
                </>
            )
        },
        {
            title: 'Hành động',
            width: '20%',
            key: 'action',
            dataIndex: 'action',
            render: (_: any, record: DataTypeComment) => (
                <Space size="middle" wrap align='center'>
                    <Button onClick={() => onReply(record)}>Trả lời </Button>
                    <Button onClick={() => onNew(record.id)}>Từ chối </Button>
                    <Button onClick={() => initialValues(record)}>Duyệt</Button>
                    {/* <Button onClick={() => console.log("record", record)}></Button> */}

                    <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                        onConfirm={() => confirm(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button danger icon={<DeleteFilled />}></Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];
const TableComment: React.FC<Props> = ({
    data,
    loadingData,
    handleDeleteComment,
    onNew,
    initialValues,
    onReply,
    total,
    pageSize,
    page,
    fetchData,
}) => {
    const post = useAppSelector(state => state.post)

    const confirm = (id: number) => {
        if (handleDeleteComment) {
            handleDeleteComment(id);
        }
    }
    const postItems = post?.items;

    return (
        <div>
            {loadingData ? (
                <Spin size="large">
                    <Alert
                        message="Thông báo"
                        description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                        type="info"
                    />
                </Spin>
            ) : (
                <>
                    <Divider />
                    {/* <Button onClick={handleExport}>export </Button> */}
                    <Table
                        dataSource={data}
                        columns={columns(confirm, initialValues, onNew, onReply)}
                        rowKey="id"
                        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                        pagination={{
                            total: total,
                            onChange: (page, pageSize) => {
                                fetchData(page, pageSize);
                            },
                            current: page,
                            pageSize: pageSize,
                            showSizeChanger: true,
                        }}
                    />
                </>
            )}

        </div>
    );
};

export default TableComment;