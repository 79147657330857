import React from "react";
import { Input, Form, InputNumber, Radio, DatePicker, Select } from 'antd';
import './faq.css'
import Editor from '../post/comp/editor'

const dateFormat = "DD-MM-YYYY";

export const option_status = [
    {
        value: 'waiting',
        label: 'Chờ duyệt'
    },
    {
        value: 'approved',
        label: 'Đã duyệt'
    },
    {
        value: 'deny',
        label: 'Từ chối'
    },
    {
        value: 'public',
        label: 'Công bố'
    },
]
interface UpdateFeedback {
    form: any,
    handleUpdate: (values: any) => void
}

const FormUpdateFeedback: React.FC<UpdateFeedback> = (props) => {
    const { form, handleUpdate } = props
    const onFinish = (values: any) => {
        if (handleUpdate) {
            handleUpdate(values)
        }
    }
    const { TextArea } = Input
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='question'
                label="Câu hỏi"
                rules={[{ required: true, message: 'Câu hỏi không được để trống.' }]}
            >
                {/* <Input /> */}
                <Editor />
            </Form.Item>
            <Form.Item
                name='answer'
                label="Câu trả lời"
                rules={[{ required: true, message: 'Câu trả lời không được để trống.' }]}
                help="Tên riêng sẽ hiển thị trên website">
                <Editor />
            </Form.Item>
            <Form.Item
                name='lang'
                label="Ngôn ngữ"
            >
                <Radio.Group>
                    <Radio value={'vi'}>
                        Tiếng Việt
                    </Radio>
                    <Radio value={'en'}>
                        Tiếng Anh
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name='status'
                label="Trạng thái"
            >
                <Select
                    style={{ width: 120 }}
                    options={option_status}
                />
            </Form.Item>
            <Form.Item
                name='approve_time'
                label='Ngày duyệt'>
                <DatePicker
                    format={dateFormat}

                />
            </Form.Item>
        </Form>
    )
}

export default FormUpdateFeedback 