import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Settings_layout from "../../siderSetting";
import HeaderNav from "./headerNav";
import TableNav from './tableNavMenu'
import './nav.css'
const SettingNav: React.FC = () => {

    return (
        <>
            <div className="nav_wrapper_setting">
                <div className="info_nav_setting" >
                    <HeaderNav />
                    <br></br>
                    <TableNav />
                </div>
            </div>
        </>
    );
};

export default SettingNav;