import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import HeaderAbout from "./header";
import './about.css'
import SettingImageCard from './formAboutImage'
import SettingPostCard from "./formAboutPost";
import SettingInsitutiontCard from "./formInsitutuionPost";

const SettingAbout: React.FC = () => {

    return (
        <>
            <div className="about_wrapper_setting">
                <div className="info_about_setting" >
                    <HeaderAbout />
                    <SettingImageCard />
                    <SettingPostCard />
                    <SettingInsitutiontCard />
                </div>
            </div>
        </>
    );
};

export default SettingAbout;