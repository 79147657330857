import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { TagsFilled, GoldOutlined, SettingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Settings_layout from "../../siderSetting";
import './infoDevice.css'
import TableActiveDevice from './table'
import DeviceInfoCard from "./activeDeviceInfo";

const SettingDevice: React.FC = () => {
    const userAgent = navigator.userAgent;
    const regex = /\(([^)]+)\)/;
    const matches = regex.exec(userAgent);
    let info;
    if (matches && matches.length > 1) {
        info = matches[1];
    }

    const [ip, setIp] = useState("");

    const fetchIpDevice = async () => {
        try {
            const response = await fetch('https://api.ipify.org/?format=json');
            const ipDevice = await response.json();
            setIp(ipDevice.ip);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchIpDevice();
    }, []);

    return (
        <>
            <div className="info_device_wrapper">
                {/* <Settings_layout /> */}
                <div className="info_device_content">
                    <div className="titlebox_device">
                        <span>Thiết bị của tôi</span>
                    </div>
                    <Divider />
                    <div className="form_profile">
                        <Space className="title-content" align="start" >
                            <span>Thiết bị đang hoạt động</span>
                            <span>Đây là thông tin thiết bị bạn đang dùng để đăng nhập vào tài khoản trên hệ thống</span>
                        </Space>
                        <DeviceInfoCard cardTitle={userAgent} ipAddress={ip} infoDevice={info} />
                    </div>

                </div>
                <div className="info_device_content">
                    <div className="titlebox_device">
                        <span>Lịch sử thiết bị</span>
                    </div>
                    <Divider />
                    <div className="form_profile">
                        <Space className="title-content" align="start" >
                            <span>Đây là lịch sử các thiết bị bạn đã dùng để đăng nhập. Bạn có thể xem mình đã đăng nhập vào hệ thống bằng thiết bị nào, khi nào và ở đâu. Nếu bạn nhận thấy có bất kì hoạt động bất thường ở thiết bị nào, hãy xoá thiết bị đó và đổi mật khẩu</span>
                            <span>Vì lý do an ninh, những thông tin này cũng được hiển thị cho quản trị viên</span>
                        </Space>
                        <TableActiveDevice />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingDevice;