import { Layout, Menu, Button, Divider, Form, Input } from "antd";
import { TagsFilled, GoldOutlined, SettingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import React from "react";
import Settings_layout from "../../siderSetting";
import './profileSetting.css'

const SettingProfile: React.FC = () => {
    return (
        <>
            <div className="profile_setting_wrapper">
                {/* <Settings_layout /> */}
                <div className="profile_setting_content">
                    <div className="titlebox_profile">
                        <span>Thông tin cá nhân</span>
                    </div>
                    <Divider />
                    <div className="form_profile">
                        <Form>
                            <Form.Item
                                label="Tên"
                                name={'profileName'}
                                initialValue={'Epr Admin'}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name={'profileEmail'}
                                initialValue={'epr../../gmail.com'}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Điện thoại"
                                name={'profileTel'}>
                                <Input />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SettingProfile;