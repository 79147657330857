import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Space, Divider, Form, Button } from 'antd';
import ModalSection from "../../../component/modal_section";
import { useMediaContext } from "../../../hooks/useMediaContext";
import { FormInstance } from 'antd/lib/form';
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { any } from "prop-types";

type Props = {
    value?: any;
    onChange?: (value: any) => void;
    thumbnails?: any[];
};

const findUrlbyThumbnail = (id: number, files: any[], thumbnails?: any[]) => {
    console.log("findUrlbyThumbnail: files: ", files)
    const findUrl = files.find((file) => file.id === id);
    if (findUrl) {
        return findUrl?.url;
    }
    const findUrlImage = thumbnails?.find((file) => file.id === id);
    if (findUrlImage) {
        return findUrlImage?.url;
    }

    return ""
};


const MediaFormItem: React.FC<Props> = ({ value, onChange, thumbnails }) => {
    const [imageUrl, setImageUrl] = useState("");

    const { files } = useMediaContext();


    useEffect(() => {
        console.log("CustomFormItem: value = ", value)

        let valueUrl = findUrlbyThumbnail(parseInt(value, 10), files, thumbnails);
        console.log("Thumbnail: ", valueUrl)
        if (valueUrl) {
            setImageUrl(valueUrl)
        }
    }, [value]);


    const handleSelectedImage = (file: any) => {
        if (typeof onChange === 'function') {
            onChange(file.id);
        }
    };

    return (
        <>
            <div>
                <ModalSection
                    handleSelectedImage={handleSelectedImage}
                    value={imageUrl} />
            </div>
        </>
    );
};

export default MediaFormItem