import React, { useEffect, useState } from "react";
import { Select, Button, Space, Input, DatePicker, Form, Modal, Divider, Radio } from 'antd';
import { PlusOutlined, QuestionCircleFilled, RedoOutlined, SearchOutlined, CopyFilled } from '@ant-design/icons';

import 'react-quill/dist/quill.snow.css';
import './faq.css'
import type { RadioChangeEvent } from "antd";
import { option_lang, option_status_FAQ } from "../../data/data";

interface HeaderFeedback {
    onNew: (values: any) => void
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
    onExport: (values: any) => void
    onImport: (values: any) => void
}
const { Search } = Input

const HeaderFeedback: React.FC<HeaderFeedback> = (props) => {
    const { onNew, onSearch, onRefresh, onExport, onImport } = props
    const [form] = Form.useForm();

    const handleRefresh = () => {
        let values = form.getFieldsValue()
        console.log("FAQ.handleRefresh: ", values)
        if (onRefresh) {
            onRefresh(values)
        }

    }
    const handleSearch = () => {
        let values = form.getFieldsValue()
        console.log("FAQ.handleSearch: ", values)
        if (onSearch) {
            onSearch(values)
        }
    }
    return (
        <div>
            <div className="title-option" >
                <Form
                    // {...layout}
                    form={form}
                    name="control-hooks"
                    // onFinish={onFinish}
                    style={{ width: ' 100% ', display: 'flex', justifyContent: 'space-between' }}
                >
                    <Space className="title">
                        <QuestionCircleFilled />
                        <span>FAQ</span>
                    </Space>
                    <Space className="option-box" style={{ width: '80%' }} >
                        <Form.Item
                            name='keyword'
                            className="form-item"
                            noStyle>
                            <Search onSearch={handleSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            name='status' className="form-item" initialValue={'all'}>
                            <Select options={option_status_FAQ} style={{ width: 180 }} />
                        </Form.Item>
                        <Form.Item

                            noStyle name='lang' className="form-item" initialValue={'all'} style={{ width: 180 }}>
                            <Select options={option_lang} />

                        </Form.Item>
                        <Form.Item noStyle
                            name='filterTime' className="form-item">
                            <DatePicker picker="date" placeholder="Chọn thời điểm" />
                        </Form.Item>
                        <Form.Item className="form-item" noStyle>
                            <Button type="primary" icon={<PlusOutlined />} onClick={onNew} size="middle">Thêm mới</Button>
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button type="primary" icon={<RedoOutlined />} size="middle" onClick={handleRefresh} />
                        </Form.Item>
                        <Form.Item className="form-item" noStyle>
                            <Button type="primary" onClick={onExport} size="middle">Export</Button>
                        </Form.Item>
                        <Form.Item noStyle>
                            <input type="file" accept=".xlsx, .csv" onChange={onImport} />
                        </Form.Item>
                    </Space>
                </Form>
            </div>
        </div>


    )
}

export default HeaderFeedback
