import React, { useState, useEffect } from "react";

import { Form, Space } from 'antd';
import { useTranslation } from 'react-i18next'

import { Button, message } from 'antd';

import FormContent from "../comp/content";
import FormOption from "../comp/option";
import FormThumbNails from "../comp/thumbnails";

import { useNavigate } from 'react-router-dom';
import { sanitizeFormValue } from "../../../util/helper";

interface Props {
    form: any,
    lang: any,
    handleUpdate: (values: any) => void,
    options: any[],
    thumbnails?: any[],
}

const FormArticle: React.FC<Props> = (props: any) => {
    const navigate = useNavigate();

    const [messageApi, contextHolder] = message.useMessage();

    const { t } = useTranslation('translation', { keyPrefix: 'pages.article-create' });

    const { handleUpdate, options, lang, form, thumbnails } = props

    useEffect(() => {
        form.setFieldValue("lang", lang)
    }, [])
    const onFinishFailed = (errorInfo: any) => {

        console.log("Failed:", errorInfo);
        const { errorFields } = errorInfo
        errorFields.map((item: any) => {
            const { name, errors } = item
            if (name[0] && errors[0]) {
                let msg = `Field ${name[0]} error: ${errors[0]}`
                messageApi.open({
                    type: 'error',
                    content: msg,
                });
            }
        })
    };
    const handleSubmit = (values: any) => {
        console.log("lang: ", lang)
        if (handleUpdate) {
            handleUpdate(sanitizeFormValue(values))
        }
    }
    return (
        <Form
            className="form-wrapper"
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
        >
            {contextHolder}

            <div className="left-side">
                <FormContent lang={lang} form={form} />
                <br></br>
            </div>
            <div className="right-side">
                <div className="option-wrapper" >
                    <FormOption lang={lang} form={form} />
                    <Space className="button" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => navigate('/')}>Đóng</Button>
                        <Button type="primary" htmlType="submit" > Update</Button>
                    </Space>
                </div>
                <br />
                <FormThumbNails
                    lang={lang}
                    form={form}
                    thumbnails={thumbnails}
                />
                <br />
            </div>
        </Form>)
}
export default FormArticle 