import React from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

interface DataType {
    created_by: number;
    created_time: number;
    id: number;
    key: string;
    label: string;
    options: null;
    type: string;
    updated_by: number;
    updated_time: number;
    value: string;
}
interface Props {

}

const columns: ColumnsType<DataType> = [
    {
        dataIndex: 'id',
        key: 'id',
        width: '0%'
    },
    {
        title: 'Tham số',
        dataIndex: 'key',
        width: '25%',
        key: 'key',
        render: (_: any, record: any) => (
            <>
                {/* <span>Linux x86_64, Chrome</span> */}
            </>
        )
    },
    {
        title: 'Giá trị',
        key: 'value',
        width: '25%',
        dataIndex: 'value',
        render: (_: any, record: any) => (
            <>
                {/* <span>	210.245.52.67</span> */}
            </>
        )
    },
    {
        title: 'Cập nhật lần cuối',
        key: 'update_time',
        width: '25%',
        dataIndex: 'update_time',
        render: (_: any, record: any) => (
            <>
                {/* <span>13:40:50 23/10/2023</span> */}
            </>
        )
    },
    {
        title: 'Hành động',
        key: 'action',
        width: '25%',
        dataIndex: 'action',
        render: (_: any, record: any) => (
            <>
                {/* <span>14:51:23 23/10/2023</span> */}
                <EditOutlined />
            </>
        )
    }
]
const TableSystem: React.FC = () => {
    return (
        <Table columns={columns} rowKey='id' />
    )
}

export default TableSystem