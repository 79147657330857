import React from "react";
import { Button, Space, Input, DatePicker, Form, Upload, message } from 'antd';
import { PlusOutlined, CommentOutlined, RedoOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from "antd";

import './comment.css'
interface HeaderComment {
    onNew: (values: any) => void
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
    onExport: (values: any) => void
    onImport: (values: any) => void
    total: number
    awaitAccept: number
    accept?: number
}
const { Search } = Input

const HeaderComment: React.FC<HeaderComment> = (props) => {
    const { onNew, onSearch, onRefresh, onExport, onImport, total, awaitAccept, accept } = props
    const [form] = Form.useForm();
    return (
        <div >
            <Form
                form={form}
                name="control-hooks"
                style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            >
                <Space className="title">
                    <CommentOutlined />
                    <span>Bình luận cho bài viết</span>
                </Space>
                <Space className="option-box" style={{ width: '80%' }}>
                    <Form.Item
                        name='all'
                        noStyle>
                        <Button type="primary" size="middle">Tất cả ({total})</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='waiting'>
                        <Button type="default" size="middle">Chờ duyệt ({awaitAccept})</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='accept'>
                        <Button type="default" size="middle">Đã duyệt (0)</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='denied'>
                        <Button type="default" size="middle">Từ chối (0)</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='searchComment'>
                        <Search onSearch={onSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='filterTime'>
                        <DatePicker picker="date" placeholder="Thời gian bình luận toàn bộ" />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>Thêm mới</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" icon={<RedoOutlined />} onClick={onRefresh} size="middle" />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={onExport} >Export</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <input accept=".xlsx, .csv" type="file" onChange={onImport} />
                    </Form.Item>
                </Space>
            </Form>
        </div >


    )
}

export default HeaderComment
