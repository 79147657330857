import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

import { Space, Tabs, Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css';

import './postUpdate.css'
import { update } from '../postSlice'
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Array2Tree, treeOption, Array2Option } from "../../../util/helper"
import { list as listCategory } from '../../category/categorySlice';
import { list as listKeyword } from '../../keyword/keywordSlice'
import type { TabsProps } from 'antd';
import { notification } from 'antd';
import { GetByID } from '../../../services/post'
import FormArticle from "./form";
import dayjs from 'dayjs'
import { useParams, useNavigate } from 'react-router-dom';
import { MediaProvider } from "../../../hooks/useMediaContext";
import { IPost as DataTypeUpdate } from "../../../types/post"

interface Props {
}


const PostUpdate: React.FC<Props> = () => {
    const post = useAppSelector(state => state.post)
    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const category = useAppSelector(state => state.category)
    const { t, i18n } = useTranslation()
    const keyword = useAppSelector(state => state.keyword)

    const dispatch = useAppDispatch()

    const [options, setOptions] = useState<any[]>([]);

    const [updateItem, setUpdateItem] = useState<DataTypeUpdate | null>(null)

    const [formUpdate] = Form.useForm()

    const [keyWordOptions, setKeyWordOptions] = useState<any[]>([])


    const onChangeLang = (key: string) => {
        i18n.changeLanguage(key)
    };
    const { record } = useParams()

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        let pagination = {
            page: 0,
            size: 100,
        }
        dispatch(listCategory(pagination)).unwrap().then(() => {
        }).catch((error) => {
            console.error("[Error] tableList: ", error);
        })
    }, []);
    const fetchData = async () => {
        console.log("post-update: ", record)
        let id = Number(record)
        if (id) {
            try {
                const item = await GetByID(id);
                if (item) {
                    console.log(">> debug item: ", item)
                    setUpdateItem(item);
                }
            } catch (error) {
                console.error('[Erorr]', error)
            }
        }

    }

    useEffect(() => {
        if (updateItem) {
            let {
                id,
                title,
                showOnBanner,
                lang,
                slug,
                excerpt,
                content,
                commentStatus,
                status,
                publicDate,
                finishDate,
                thumbnail,
                thumbnailUrl,
                categories,
                keywords
            } = updateItem;
            const category = categories.map((item) => item.id)
            const keyword = keywords.map((item) => item.id)
            formUpdate.setFieldsValue({
                id,
                title,
                lang,
                showOnBanner,
                slug,
                excerpt,
                content,
                commentStatus,
                status,
                publicDate: dayjs(Number(publicDate) * 1000),
                finishDate: dayjs(Number(finishDate) * 1000),
                thumbnail,
                keyword,
                category,
                thumbnailUrl
            });
            console.log(">> debug form update: ", formUpdate.getFieldsValue())
        };
    }, [updateItem]);

    useEffect(() => {
        if (category?.items) {
            const data = Array2Tree(category?.items)
            const options = data.map((item) => treeOption(item))
            setOptions(options)
        };
    }, [category]);
    useEffect(() => {
        if (keyword?.items) {
            const option_keyword = Array2Option("vi", keyword?.items)
            setKeyWordOptions(option_keyword)
        }
    }, [keyword])

    useEffect(() => {
        let pagination = {
            page: 0,
            size: 100,
        }
        dispatch(listKeyword(pagination)).unwrap().then(() => {
            console.log('list keyword 1')
        }).catch((error: any) => {
            console.log(error)
        })
    }, []);
    const handleSave = () => {
        const btn = (
            <Space>
                <Button type="primary" onClick={() => navigate("/post")}>
                    Chuyển về trang danh sách các bài viết
                </Button>
            </Space>
        )
        if (updateItem) {
            formUpdate.validateFields()
                .then((values) => {
                    const updatedRecord = { ...updateItem, ...values };
                    console.log('update updatedRecord', updatedRecord)

                    let categories: any[] | undefined = []
                    if (updatedRecord["category"]) {
                        categories = category?.items?.filter((item) => updatedRecord["category"].includes("" + item.id))
                    }
                    let keywords: any[] | undefined = []
                    if (updatedRecord["keyword"]) {
                        keywords = keyword?.items?.filter((item) => updatedRecord["keyword"].includes("" + item.id))
                    }
                    console.log('update categories', categories)
                    console.log('update keywords', keywords)

                    const updateValues = {
                        id: updatedRecord.id,
                        priority: updatedRecord.priority,
                        slug: updatedRecord.slug,
                        title: updatedRecord.title,
                        excerpt: updatedRecord.excerpt,
                        content: updatedRecord.content,
                        status: updatedRecord.status,
                        showOnBanner: updatedRecord.showOnBanner,
                        commentStatus: updatedRecord.commentStatus,
                        isPublished: true,
                        lang: updatedRecord.lang,
                        publicDate: updatedRecord.publicDate.unix(),
                        finishDate: updatedRecord.finishDate.unix(),
                        createdTime: updatedRecord.createdTime,
                        updatedTime: updatedRecord.publicDate.unix(),
                        thumbnail: updatedRecord.thumbnail,
                        thumbnailUrl: updatedRecord.thumbnailUrl,
                        categories: categories,
                        keywords: keywords,
                    }
                    console.log('update values:', updateValues)
                    dispatch(update(updateValues)).unwrap().then(() => {
                        api.open({
                            message: `Cập nhật bài viết ${updateValues.title} thành công`,
                            description: 'Thành công',
                            duration: 0,
                            type: 'success',
                            btn
                        });
                    }).catch(error => {
                        api.open({
                            message: 'Thất bại',
                            description: `Cập nhật thất bại: ${error}`,
                            duration: 0,
                            type: 'error'
                        })
                    })
                })
        }
    };

    const items: TabsProps['items'] = [
        {
            key: 'vi',
            label: 'Tiếng Việt',
            children: <FormArticle
                lang="vi"
                handleUpdate={handleSave}
                options={options}
                form={formUpdate}
                opt_keyword={keyWordOptions}
                thumbnails={post?.thumbnails}
            />,
        },
        // {
        //     key: 'en',
        //     label: 'English',
        //     children: <FormArticle
        //         lang="en"
        //         handleUpdate={handleSave}
        //         options={options}
        //         form={formUpdateEn}
        //         opt_keyword={keyWordOptionsEn} />,
        // }
    ];

    return (
        <MediaProvider >
            <div className="wrapper">
                <div className="titleBox">
                    <div className="title">
                        <Space>
                            <>
                                <EditOutlined />
                                <span>Chỉnh sửa bài báo </span>
                            </>
                        </Space>
                    </div>
                </div>
                {contextHolder}

                <Tabs defaultActiveKey="1" items={items} onChange={onChangeLang} />
            </div>
        </MediaProvider>
    )
}

export default PostUpdate;