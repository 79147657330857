import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Card, Space } from 'antd';
import Password from "antd/es/input/Password";
import { SyncOutlined } from '@ant-design/icons';

import { Spin, message } from 'antd';

import { ISignIn, ICaptchaInfo } from "../../types/auth";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchCaptchar, signIn } from "./authSlice"

import "./signin.css"

const CaptcharImage = (props: any) => {
    const { captchaData } = props

    if (captchaData && captchaData != "") {
        return <img src={captchaData} />
    }
    return <div></div>
}

const CaptcharButton = (props: any) => {
    const { fetchNewCaptchar, isLoading } = props

    if (isLoading) {
        return <Spin />
    }
    return <Button type="text" className="vnp-min-width-0" onClick={fetchNewCaptchar}><SyncOutlined /></Button>
}

const SignIn = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const auth = useAppSelector(state => state.auth)
    const { isLoggedIn, status, account, captchaId, captchaData, isLoading, error } = auth;

    const fetchNewCaptchar = () => {
        var date = new Date;
        var milliSeconds = date.getMilliseconds();
        dispatch(fetchCaptchar(milliSeconds))
    }

    useEffect(() => {
        fetchNewCaptchar()
    }, [])

    useEffect(() => {
        if (captchaId) {
            console.log("capcha: update  captchaId...", captchaId);
            form.setFieldValue("captchaId", captchaId)
        }
    }, [captchaId]);

    const onFinish = (values: any) => {
        console.log("submit data", values);
        let { username, pwd, captcha, captchaId, remember } = values;

        let capcharInfo = {
            captcha_id: captchaId,
            captcha_data: captcha,
        } as ICaptchaInfo

        let account: ISignIn = {
            username: username,
            password: pwd,
            token: "",
            captcha: capcharInfo,
        }
        dispatch(signIn(account)).
            unwrap().
            then((data: any) => {
                if (data) {
                    console.log("SignIn: navigate /", data);
                    navigate("/")
                }
            }).catch((ex: any) => {
                message.error("Đăng nhập lỗi: " + ex)
            })
    };

    return (
        <div className="signin-container" >
            <div id="components-form-demo-normal-login" >
                <Card title="Cổng thông tin EPR" bordered={false} style={{ width: 300, margin: "0 auto" }}>
                    <Form
                        form={form}
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Vui lòng nhập tài khoản' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="pwd"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                        >
                            <Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item name="captchaId" hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="" name="captcha" rules={[{ required: true, message: "Bạn cần nhập mã capcha" }]}>
                            <Space >
                                <Input />
                                <CaptcharImage captchaData={captchaData} />
                                <CaptcharButton captchaData={captchaData} fetchNewCaptchar={fetchNewCaptchar} />
                            </Space>
                        </Form.Item>

                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Ghi nhớ</Checkbox>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Đăng nhập
                            </Button>
                            Hoặc <a href="/reset">Quên mật khẩu</a>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
        </div>
    )
}

export default SignIn;

