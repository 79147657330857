import { Button, Space } from "antd";
import { SnippetsOutlined, SettingOutlined } from "@ant-design/icons";
import React from "react";
import './about.css'


const HeaderAbout: React.FC = () => {

    return (
        <>
            <div className="titlebox_about">
                <Space >
                    <SettingOutlined />
                    <span>Cài đặt trang giới thiệu
                    </span>
                </Space>
                {/* <Button type="primary" icon={<RedoOutlined />}></Button> */}
            </div>
        </>
    );
};

export default HeaderAbout;