import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ModalSection from "../../../component/modal_section";
import { useMediaContext } from "../../../hooks/useMediaContext";
import { FormInstance } from 'antd/lib/form';
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import MediaFormItem from "./item_media"
import type { RadioChangeEvent } from 'antd';
import { Space, Divider, Form, Button, Input, Radio, Image } from 'antd';
import FormItemImageUrl from "./item_image"

interface MediaSectionProps {
    form?: FormInstance<any>;
    lang?: string;
    thumbnails?: any[];
}

const MediaSection: React.FC<MediaSectionProps> = ({ form, lang, thumbnails }) => {
    const [imageChoice, setImageChoice] = useState("url");

    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });

    const onImageChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setImageChoice(e.target.value);
    };


    return (
        <div className="option-wrapper" title={t('thumbnail')}>
            <Space className="title-post-content" align="start" size={"small"}>
                <span>{t('thumbnail')}</span>
            </Space>
            <Divider />

            <Radio.Group onChange={onImageChange} value={imageChoice} defaultValue={imageChoice} buttonStyle="solid">
                <Radio.Button value="url">Đường dẫn</Radio.Button>
                <Radio.Button value="image">Thư viện</Radio.Button>
            </Radio.Group>
            {
                (imageChoice == "url") && (
                    <Form.Item
                        className="vertical-item"
                        label={t('thumbnail')}
                        name='thumbnailUrl'
                    >
                        <FormItemImageUrl />
                    </Form.Item>
                )
            }
            {
                (imageChoice != "url") && (<Form.Item
                    name='thumbnail'
                    className="post-option"
                >

                    <MediaFormItem
                        thumbnails={thumbnails}
                    />
                </Form.Item>
                )
            }
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <Button onClick={onClickShowImage} icon={<EyeOutlined />} type="primary"></Button> */}
                {/* <Button onClick={onClickUpdateImg} type="primary" icon={<EditOutlined />}>Lưu ảnh</Button> */}
            </Space>
        </div>
    );
};

export default MediaSection;
