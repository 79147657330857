import history from '../history'

import { ISignIn, IActivateRequest, IAccount, IDeclaratorRequest, IChangePasswordRequest, IResetPasswordRequest } from "../types/auth";

export const signinRequest = async (info: IAccount): Promise<IAccount> => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
    })
        .then((response) => new Promise((resolve, reject) => {
            if (response.status == 200) {
                resolve(response.json())
            }
            reject(`response status ${response.status}`)
        }))
        .then((responseData: any) => {
            console.log("responseData: ", responseData, typeof (responseData))
            if (!responseData) throw ("empty response")

            const { code, message, data } = responseData
            if (code == 16 ) {
                history.push("/cms/login");
            }
            if (code != 200) {
                throw (message)
            }
            var res = data as IAccount
            localStorage.setItem("cjwt", res.token);
            return res
        })
        .catch(error => {
            console.log("CATCH error: ", error)
            throw (error)
        });
}

export const signupRequest = async (info: IDeclaratorRequest): Promise<string> => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
    })
        .then((response) => response.json())
        .then((responseData) => {
            const { code, message } = responseData
            if (code == '200') {
                return message
            }
            throw (message)
        })
        .catch(error => {
            throw (error)

        });
}

export const changePasswordRequest = async (info: IChangePasswordRequest): Promise<string> => {
    let token = localStorage.getItem('cjwt') || ''

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bear ${token}`,
        },
        body: JSON.stringify(info)
    })
        .then((response) => response.json())
        .then((responseData) => {
            const { code, message } = responseData
            if (code == '200') {
                return message
            }
            throw (message)
        })
        .catch(error => {
            throw (error)

        });
}

export const activateRequest = async (info: IActivateRequest): Promise<string> => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/activate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
    })
        .then((response) => response.json())
        .then((responseData) => {
            const { code, message } = responseData
            if (code == '200') {
                return message
            }
            throw (message)
        })
        .catch(error => {
            throw (error)

        });
}


export const resetPasswordRequest = async (info: IResetPasswordRequest): Promise<string> => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reset_password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
    })
        .then((response) => response.json())
        .then((responseData) => {
            const { code, message } = responseData
            if (code == '200') {
                return message
            }
            throw (message)
        })
        .catch(error => {
            throw (error)

        });
}


export const refreshRequest = async (): Promise<IAccount> => {
    let token = localStorage.getItem('cjwt') || ''
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/refresh`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bear ${token}`,
        }
    })
        .then((response) => response.json())
        .then((responseData) => {
            console.log("refreshRequest: ", responseData)
            const { code, message, data } = responseData
            // if (code == 16 ) {
            //     history.push("/cms/login");
            // }
            if (code == 200) {
                var res = data as IAccount
                localStorage.setItem("cjwt", res.token);
                return res
            }
            console.log("refreshRequest: error => remove cjwt ", responseData)

            // localStorage.removeItem("cjwt");
            throw (message)
        })
        .catch(error => {
            console.log("refreshRequest: error => remove cjwt ", error)

            // localStorage.removeItem("cjwt");
            throw (error)
        });
}