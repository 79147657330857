import { Card, Input, Form, Select, Button, Row, Col, Image } from "antd";
import React, { useEffect, useState } from "react";
import './about.css'
import { DeleteOutlined } from "@ant-design/icons";
import CustomFormItem from "../../../post/comp/item_media";

interface Props {

}


const SettingImageCard: React.FC = () => {
    const [form] = Form.useForm();

    return (
        <>
            <Card
                title="Ảnh chính trang giới thiệu"
                className="content_about"
                // actions={[<Button type="primary" danger icon={<DeleteOutlined />}>Hủy chọn</Button>]}
                headStyle={{ textAlign: "left" }}
            >
                <p style={{ textAlign: 'left', fontWeight: 'lighter', fontSize: 'small' }}>(Lưu ý: Cần nhấn nút "Lưu lại" để lưu các cài đặt bạn đã thêm)</p>

                <Form
                    style={{ width: '100%' }}
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="box_image"
                                initialValue='KÊ KHAI, NỘP TIỀN XỬ LÝ CHẤT THẢI'
                            >
                                <CustomFormItem value="http://epr.vn//upload/anh-bai-viet/16457009546217675a3ca869.55996035.jpg" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
};

export default SettingImageCard;