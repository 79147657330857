import React, { useState } from "react";
import { Input, Form, Col, Select, Row, DatePicker, Radio } from 'antd';

import { option_lang, option_doc, option_effectStatus } from "../../data/data";
import type { FormInstance, RadioChangeEvent } from "antd";
import Editor from "../post/comp/editor"
import CustomFormItem from "../post/comp/item_media";

// import ModalSection from "../../component/modal_section";
import './document.css'
import CreateDetailDoc from "./formCreateDetailDoc";
const { Search, TextArea } = Input

interface DocCreate {
    form: FormInstance,
    // option_category: any[],
    handleSubmitDoc: (values: any) => void
}
const FormDocument: React.FC<DocCreate> = (props) => {
    const { form, handleSubmitDoc } = props
    const [status, setStatus] = useState("private")

    const onFinish = (values: any) => {
        console.log("Form.onFinish: ", values)
        if (handleSubmitDoc) {
            handleSubmitDoc(values)
        }
    }
    const onChangeRadio = (e: RadioChangeEvent) => {
        setStatus(e.target.value)
    }
    const [selectTypeDoc, setSelectTypeDoc] = useState("all")
    const handleSelectedType = (value: any) => {
        setSelectTypeDoc(value)
    }

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='title'
                label="Tiêu đề"
            >
                <Input placeholder="Nhập tiêu đề tài liệu của bạn" />
            </Form.Item>
            <Form.Item
                name='type'
                label="Loại tài liệu"
                rules={[{ required: true, message: 'Loại tài liệu không được để trống.' }]}
                initialValue="all"
            >
                <Select options={option_doc} onChange={handleSelectedType} />
            </Form.Item>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="lang"
                        label="Ngôn ngữ"
                        initialValue="vi">
                        <Select options={option_lang} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="publicDate"
                        label="Ngày lên website">
                        <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Form.Item
                    name="status"
                    label="Trạng thái bài"
                    initialValue={'draft'}>
                    <Radio.Group
                        onChange={onChangeRadio}
                        value={status}
                    >
                        <Radio
                            value={"draft"}>
                            Bản nháp
                        </Radio>
                        <Radio value={"public"}>
                            Công khai
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </Row>
            {
                selectTypeDoc === "van-ban-phap-luat" && (
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="symbol"
                                label="Số ký hiệu"
                                initialValue="">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="effectStatus"
                                label="Trạng thái hiệu lực">
                                <Select options={option_effectStatus} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="agencyIssued"
                                label="Cơ quan ban hành">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}
                        >
                            <Form.Item
                                name="issuedDate"
                                label="Ngày ban hành"
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                )
            }
            {
                selectTypeDoc === "hoi-thao-epr" && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="excerpt"
                                label="Mô tả ngắn"
                                help="Mô tả ngắn là một đoạn mô tả về nội dung mà bạn tự nhập bằng tay, có thể được sử dụng để hiển thị trong giao diện của bạn"
                            >
                                <TextArea />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="content"
                                label=" Nội dung">
                                <Editor />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="fileId"
                                label="Ảnh đại diện">
                                <CustomFormItem onChange={(value) => form?.setFieldsValue({ fileId: value })} />
                            </Form.Item>
                        </Col>
                    </Row>
                )
            }
            {
                selectTypeDoc === "nghien-cuu-bao-cao" && (
                    <Form.Item
                        name="fileId"
                        label="Ảnh đại diện">
                        <CustomFormItem onChange={(value) => form?.setFieldsValue({ fileId: value })} />
                    </Form.Item>
                )
            }
        </Form >
    )
}

export default FormDocument 
