import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Divider, Select } from 'antd';
import { PlusOutlined, FileImageFilled, RedoOutlined, SearchOutlined, CopyFilled } from '@ant-design/icons';
import './image.css'
import { useNavigate } from "react-router-dom";

interface HeaderVideo {
    handleSubmitVideo: (values: any) => void
    handleSearch: (values: any) => void
    refreshData: (values: any) => void

}
const { Search, TextArea } = Input

const HeaderImage: React.FC<HeaderVideo> = (props) => {
    const { handleSubmitVideo, handleSearch, refreshData } = props

    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onFinish = (values: any) => {
        handleSubmitVideo(values)
        closeModal()
    }
    const onFilter = (values: any) => {
        handleSearch(values)
    }
    const showModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }
    const onSearch = (value: any) => {
        form.submit()
    }

    return (
        <div>
            <Form
                form={form}
                name="control-hooks"
                style={{ width: ' 100 % ', display: 'flex', justifyContent: 'space-between' }}
            >
                <Space className="title">
                    <FileImageFilled />
                    <span>Hình ảnh</span>
                </Space>
                <Space className="option-box">
                    <Form.Item
                        noStyle
                        name='all'>
                        <Button type="primary" size="middle">Tất cả (0)</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='waiting'>
                        <Button type="default" size="middle">Hoạt động (0)</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='accept'>
                        <Button type="default" size="middle">Tạm dừng (0)</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='searchVideo'>
                        <Search onSearch={onFilter} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>Thêm mới</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" icon={<RedoOutlined />} onClick={refreshData} size="middle" />
                    </Form.Item>
                </Space>
            </Form>
            <Modal title="Thêm mới"
                open={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button onClick={closeModal}>Đóng</Button>,
                    <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />} onClick={form.submit}>Lưu lại</Button>
                ]}>
                <Divider />
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                >
                    <Form.Item
                        name='title'
                        label="Tiêu đề "
                        rules={[{ required: true, message: 'Tiêu đề không được để trống.' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='slug'
                        label="Đường dẫn Youtube "
                        rules={[{ required: true, message: 'Đường dẫn Youtube không được để trống.' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='excerpt'
                        label="Mô tả ngắn">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Thứ tự hiển thị"
                        name='index'>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>


    )
}

export default HeaderImage
