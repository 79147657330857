import { configureStore } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { TypedUseSelectorHook, useDispatch } from 'react-redux'

import authReducer from '../page/auth/authSlice'
import categoryReducer from '../page/category/categorySlice'
import postReducer from '../page/post/postSlice'
import articleReducer from '../page/article/articleSlice'
import commentReducer from '../page/comment/commentSlice'
import keywordReducer from '../page/keyword/keywordSlice'
import videoReducer from '../page/video/videoSlice'
import imageReducer from '../page/image/imageSlice'

import docReducer from '../page/document/documentSlice'
import faqReducer from '../page/faq/faqSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer, 
    post: postReducer,
    category: categoryReducer,
    comment: commentReducer,
    keyword: keywordReducer,
    video: videoReducer,
    doc: docReducer,  
    faq: faqReducer,
    image: imageReducer,
    article: articleReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector