import { Button, Divider, Modal, Row, Image, Space } from "antd";
import { useState, useEffect } from "react";
import FolderList from "./FolderList";
import DetailsInfomation from "./DetailsInfomation";
import "./styles.scss"
import { DeleteFilled } from '@ant-design/icons';
import ImageList from "./ImageList";
import FileList from "./FileList";
import type { DataNode } from 'antd/es/tree';
import { useMediaContext } from "../../hooks/useMediaContext";
import { convertData } from "./helper"
import { File, Folder } from "../../types/folder";
import type { UploadFile } from 'antd/es/upload/interface';

const IMAGE_ASPECT = 1.2

const ModalSection = (props: any) => {
    const { url, handleSelectedImage, handleSelectedFileDocs, value } = props
    const {
        loading,
        data,
        loadData,
        files,
        uploadFile,
        selectedFile,
        setSelectedFile,
        createFolder,
        editFolder,
        deleteFolder,
        selectedFolder,
        setSelectedFolder,
    } = useMediaContext()
    const [open, setOpen] = useState(false);
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [images, setImages] = useState<UploadFile[]>([]);
    const [fileDocs, setFileDocs] = useState<UploadFile[]>([]);
    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
    const [selectedFileDocs, setSelectedFileDocs] = useState<File | undefined>(undefined)

    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
    const documentType = (selectedFolder?.id === 4 || selectedFolder?.id === 5) ? "FILE" : "IMAGE";
    useEffect(() => {
        setImageUrl(value)
    }, [value])

    useEffect(() => {
        try {
            loadData()
        } catch (ex) {
            // message.error("Error in loading data", ex)
            console.log("Error in loadData", ex)
        }
    }, [])
    useEffect(() => {
        let treeData = convertData(data)
        console.log("Render:treeData  ", treeData)
        setTreeData(treeData)
    }, [data])

    useEffect(() => {
        console.log("model-section: files ", files)

        const images = files.map((file: File) => {
            // console.log("file url: ", file.url)
            return {
                id: file.id,
                uid: "" + file.id,
                name: file.name,
                status: 'done',
                url: file.url,
            } as UploadFile
        })
        // console.log("model-section: images ", images)
        const fileDocs = files.map((file: any) => { // Not: File Object does not include createdAt and updatedAt fields 
            return {
                id: file.id,
                uid: "" + file.id,
                name: file.name,
                status: 'done',
                url: file.url,
                ext: file.ext,
                size: file.size,
                createdAt: file.createdAt,
                updateAt: file.updateAt,
            } as UploadFile
        })

        setImages(images)
        setFileDocs(fileDocs)
    }, [files])

    const onSelectedImage = (file: any) => {
        setSelectedImage(file)
        // setSelectedFile(file)
        setImageUrl(file.url)
        if (handleSelectedImage) {
            handleSelectedImage(file)
            // setSelectedFile(file.id)
        }
    }

    const onSelectedFileDocs = (file: any) => {
        setSelectedFileDocs(file)
        // setSelectedFile(file)
        setImageUrl(file.url)
        if (handleSelectedFileDocs) {
            handleSelectedFileDocs(file)
            // setSelectedFile(file.id)
        }
    }

    const handleCreateFolder = (name: string) => {
        console.log("handleCreateFolder: ", name)
        createFolder(name)
    }

    const handleEditFolder = (id: number, name: string) => {
        console.log("handleEditFolder: ", name)
        editFolder(id, name)
    }

    const handleDeleteNode = (id: number) => {
        console.log("handleDeleteNode: ", id)
        deleteFolder(id)
    }

    const handleSelectNode = (id: string) => {
        setSelectedFolder(Number(id))
        setImageUrl(undefined)
    }

    const onSubmit = () => {
        setOpen(false)
    }

    const handleUploadFile = async (options: any) => {
        console.log("model_section.handleUploadFile: options = ", options)
        await uploadFile(options)
        const { onError } = options
        setTimeout(() => {
            const error = new Error("Some error");
            onError({ error });
        }, 100);
    };
    const handleUploadFileDocs = async (options: any) => {
        console.log("model_section.handleUploadFileDocs: options = ", options)
        await uploadFile(options)
        const { onError } = options
        setTimeout(() => {
            const error = new Error("Some error");
            onError({ error });
        }, 100);
    };
    const onClickCancel = () => {
        console.log("[check selectedFile]", selectedFile)
        setImageUrl(undefined)
    }
    const onRemoveSelectedFile = () => {
        setSelectedFileDocs(undefined)
    }
    return (
        <>
            <Button type="dashed" onClick={() => setOpen(true)}
                style={{
                    height: 'auto',
                    width: '100%',
                    textAlign: 'center',
                    lineHeight: '200px',
                }}
            >
                {documentType === "IMAGE" && <>
                    {!imageUrl &&
                        <div style={{
                            background: '#a5a5a5',
                            height: 'auto',
                            width: '100%',
                            textAlign: 'center',
                            lineHeight: '200px',
                        }}>
                            Chọn hình ảnh
                        </div>
                    }
                    {
                        imageUrl &&
                        <Image
                            preview={false}
                            style={{
                                background: '#a5a5a5',
                                height: 'auto',
                                width: '100%',
                                textAlign: 'center',
                                lineHeight: '200px',
                            }}
                            src={imageUrl}
                            className='image'
                        />
                    }
                </>}
                {documentType === "FILE" && <div>
                    {!selectedFileDocs &&
                        <div style={{
                            background: '#a5a5a5',
                            height: 'auto',
                            width: '100%',
                            textAlign: 'center',
                            lineHeight: '200px',
                        }}>
                            Chọn tài liệu
                        </div>
                    }
                    {
                        selectedFileDocs &&
                        <div className="selected-file">
                            <Image
                                preview={false}
                                style={{
                                    height: 'auto',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                                src="pdf.png"
                                className='image'
                            />
                            <span>{selectedFileDocs.name}</span>
                        </div>

                    }
                </div>}

            </Button>
            <Modal
                title="Thêm media"
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={"100%"}
                footer={null}
                style={{ minHeight: "100vh" }}
            >
                <Divider></Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ minHeight: "100vh" }}>
                    <FolderList
                        treeData={treeData}
                        nfile={data.length}
                        selectedFolder={selectedFolder}
                        handleCreateFolder={handleCreateFolder}
                        handleEditFolder={handleEditFolder}
                        handleDeleteFolder={handleDeleteNode}
                        handleSelectNode={handleSelectNode}
                    />

                    {documentType === "FILE" ? <FileList
                        multiple={true}
                        loading={loading}
                        uploadFile={handleUploadFileDocs}
                        fileDocs={fileDocs}
                        selectedFileDocs={selectedFileDocs}
                        setSelectedFileDocs={onSelectedFileDocs}
                        aspect={IMAGE_ASPECT}
                    /> :
                        <ImageList
                            loading={loading}
                            uploadFile={handleUploadFile}
                            images={images}
                            selectedFolder={selectedFolder}
                            selectedImage={selectedImage}
                            setSelectedImage={onSelectedImage}
                            crop={true}
                            aspect={IMAGE_ASPECT}
                        />
                    }

                    {selectedImage &&
                        <DetailsInfomation
                            documentType={documentType}
                            image={selectedImage}
                            onSubmit={onSubmit}
                        />}
                    {selectedFileDocs &&
                        <DetailsInfomation
                            documentType={documentType}
                            image={selectedFileDocs}
                            onSubmit={onSubmit}
                        />}
                </Row>
            </Modal >
            {documentType === "IMAGE" && <Space style={{ marginTop: '10px' }} >
                {imageUrl &&
                    <Space>
                        <Button icon={<DeleteFilled />} type="primary" danger onClick={onClickCancel} >Hủy chọn</Button>
                    </Space>
                }
                {!imageUrl &&
                    <></>}

            </Space>}
            {documentType === "FILE" && <Space style={{ marginTop: '10px' }} >
                {selectedFileDocs ?
                    <Space>
                        <Button icon={<DeleteFilled />} type="primary" danger onClick={onRemoveSelectedFile} >Hủy chọn</Button>
                    </Space>
                    : <></>}

            </Space>}
        </>
    );
};

export default ModalSection;