import React, { useEffect, useState } from "react";
import { Select, Button, Space, Input, DatePicker, Form, Modal, Divider, Radio } from 'antd';
import { PlusOutlined, CopyOutlined, RedoOutlined, SearchOutlined, CopyFilled } from '@ant-design/icons';
import './document.css'
import { option_lang, option_doc, option_status } from "../../data/data";

interface HeaderDoc {
    onNew: (values: any) => void
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
    onExport: (values: any) => void
    onImport: (values: any) => void

}
const { Search, TextArea } = Input

const HeaderDoc: React.FC<HeaderDoc> = (props) => {
    const { onNew, onSearch, onRefresh, onExport, onImport } = props
    const [form] = Form.useForm();
    return (
        <div>
            <div >
                <Form
                    // {...layout}
                    form={form}
                    name="control-hooks"
                    style={{ width: ' 100% ', display: 'flex', justifyContent: 'space-between' }}
                >
                    <Space className="title">
                        <CopyOutlined />
                        <span>Tài liệu</span>
                    </Space>
                    <Space className="option-box" style={{ width: '80%' }}>
                        <Form.Item
                            noStyle
                            name='keyword'>
                            <Search onSearch={onSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            name='doc'>
                            <Select placeholder="Chọn loại tài liệu" options={option_doc} style={{ width: 220 }} />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            name='status' initialValue={'all'}>
                            <Select options={option_status} style={{ width: 170 }} />

                        </Form.Item>
                        <Form.Item
                            noStyle
                            name='lang' initialValue={'all'}>
                            <Select options={option_lang} style={{ width: 170 }} />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            name='filterTime'>
                            <DatePicker picker="date" placeholder="Chọn thời điểm" />
                        </Form.Item>
                        <Form.Item
                            noStyle>
                            <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>Thêm mới</Button>
                        </Form.Item>
                        <Form.Item
                            noStyle>
                            <Button type="primary" icon={<RedoOutlined />} size="middle" onClick={onRefresh} />
                        </Form.Item>
                        <Form.Item
                            noStyle>
                            <Button type="primary" size="middle" onClick={onExport}>Export</Button>
                        </Form.Item>
                        <Form.Item noStyle>
                            <input type="file" accept=".xlsx, .csv" onChange={onImport} />
                        </Form.Item>
                    </Space>
                </Form>
            </div>
        </div>


    )
}

export default HeaderDoc
