
import { useContext, useEffect, useState } from "react"
import { Layout } from 'antd';
import { Outlet, useNavigate } from "react-router-dom"

import HeaderErp from './header';
import SiderErp from './sider';

import menuData from "./menu.main.json"
import {
    matchPath,
    useLocation
} from "react-router-dom";
import { ToMenuItem, ToSideMenuItem, findParent } from "./menu"
import { useAppDispatch, useAppSelector } from "../redux/store";
import { refreshAccount } from "../page/auth/authSlice"

import './layout.css';

const { Content } = Layout;

type LayoutProps = {};

const ErpLayout: React.FC<LayoutProps> = () => {
    const [menuItems, setMenuItems] = useState<any[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const dispatch = useAppDispatch()

    const navigate = useNavigate();
    const { pathname } = useLocation();

    //Check login layout 
    const mainMenuItems = ToMenuItem(menuData, 0)
    const auth = useAppSelector(state => state.auth)
    const { isLoggedIn, status, account, captchaId, captchaData, isLoading, error } = auth;

    useEffect(() => {
        console.log("refresh.... ")
        dispatch(refreshAccount({})).unwrap().catch((err: any) => {
            console.log("Cannot get accountInfo", err)
            navigate("login")
        })
    }, [])

    useEffect(() => {
        console.log("Pathname: ", pathname)
        let parent = findParent(menuData, pathname)
        if (parent && parent?.children && parent?.children.length > 0) {
            let mItems = ToSideMenuItem(parent?.children, 0)
            setMenuItems(mItems)
            parent?.children?.map((item: any) => {
                if (item?.link == pathname) {
                    console.log("set key : ", "" + item.id)
                    setSelectedKeys(["" + item.id])
                }
            })

        }
    }, [pathname])
    return (
        <Layout hasSider className="layout" >
            <HeaderErp menuData={mainMenuItems} />
            <div className="wrapper_content">
                {menuItems && (menuItems.length > 0) && <SiderErp items={menuItems} selectedKeys={selectedKeys} />}
                <Content >
                    <div className="content">
                        <Outlet />
                    </div>
                </Content>
            </div>
        </Layout>
    )
}

export default ErpLayout