import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IComment } from "../../types/comment";
import {List, Create, Delete, GetTargetID} from '../../services/comment'
import { RootState } from '../../redux/store';
import { IPagination } from "../../types/pagination";


export interface CommentState {
    status : 'idle' | 'loading' | 'succeeded' | 'failed';
    items ?: IComment[];
    pagination?: IPagination;
    error?: string ;
    itemTargetIds?: IComment[]
}   

const initialState: CommentState = {
    status: 'idle',
    items: [],
    itemTargetIds: []
}

export const commentSlice = createSlice ({
    name: 'comment',
    initialState,
    reducers: { 
        importComment: (state, action) => {
            state.items = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(list.pending, (state,action) => {
                state.status = 'loading'
            })
            .addCase(list.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const { data, extra } = action.payload
                state.items = data
                state.pagination = extra
            })
            .addCase(list.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message + " with error: " + action.payload
            })
            .addCase(get_target_id.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(get_target_id.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message + " with error: " + action.payload
            })
            .addCase(get_target_id.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.items = action.payload
              })
            .addCase(create.fulfilled, (state, action) => {
                state.status = 'succeeded'
                let item = action.payload
                state.items?.unshift(item)
              })
            
            .addCase(del.fulfilled, (state,action) => {
                state.status = 'succeeded'
                let item = action.payload
                state.items = state.items?.filter((el)=> el.id != item.id)
            })
    }
})

export const list = createAsyncThunk('comment/list', async (info: any, { rejectWithValue }) => {
    try {
      const response = await List(info)
      return response
    } catch (ex) {
      return rejectWithValue(ex)
    }
  })

export const create  = createAsyncThunk('comment/create', async (info:any, {rejectWithValue}) => {
    try {
        const response = await Create(info)
        return response
    } catch (ex) {
        return rejectWithValue(ex)
    }
})

export const del = createAsyncThunk('comment/delete', async (info:any, {rejectWithValue}) => {
    try {
        const response = await Delete (info)
        return response
    } catch (ex) {
        return rejectWithValue(ex)
    }
})
export const get_target_id = createAsyncThunk('post/cmt', async(info:any, {rejectWithValue}) => {
    try {
      const response = await GetTargetID(info)
      return response
    } catch (ex) {
      return rejectWithValue(ex)
    }
  })
export const {importComment} = commentSlice.actions
export const selectComment = (state: RootState) => state.comment

export default commentSlice.reducer