import { redirect } from "react-router-dom";
import {IDoc} from "../types/doc"

import fetch, {queryOne,  queryList } from "./request"

export const List = async (props:any): Promise<any> => {
    const { page, size, word} = props 
    if (word) {
        let keyword = JSON.stringify(word)
        return fetch(`api/doc?page=${page}&size=${size}&keyword=${keyword}`, 'GET', true, {})
    }
    return fetch(`api/doc?page=${page}&size=${size}`, 'GET', true, {})
}


export const Create = async (info:any): Promise<IDoc> => {
    return queryOne(`api/doc`, 'POST', true, info)
}

export const Filter = async (info: any): Promise<any> => {

    await new Promise((resolve) => setTimeout(resolve, 100));

    const responseData = {
        code: '200',
        message: 'Success',
        data: [
            {
                id: 5,
                userName: ' Ngo van hieu',
                userPhone: 376017229,
                userEmail: 'hieungo13../../gmail.com',
                content: 'This is fake data for API filter',
            },
            {
                id: 6,
                userName: ' Ngo van hieu',
                userPhone: 376017229,
                userEmail: 'hieungo13../../gmail.com',
                content: 'This is fake data for API filter',
            },
            {
                id: 7,
                userName: ' Ngo van hieu',
                userPhone: 376017229,
                userEmail: 'hieungo13../../gmail.com',
                content: 'This is fake data for API filter',
            },
        ],

    };

    if (responseData.code === '200') {
        return responseData.data;
    } else {
        throw new Error(responseData.message);
    }
};

export const Delete = async (info: any): Promise<IDoc> => {
    return queryOne(`api/doc` , 'DELETE', true, info)
}

export const Update = async (info: any): Promise<IDoc> => {
    return queryOne(`api/doc`, 'PUT', true, info)
}
