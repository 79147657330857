import React, { useEffect, useState } from 'react';
import { Divider, Tag, Table, Button, Space, Popconfirm, Spin, Alert, Input } from 'antd';
import dayjs from 'dayjs';
import { EditFilled, DeleteFilled, CommentOutlined, CopyOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { DataType } from "./type"
import EditableCell from "./cell"

export const columns = (
    confirm: (id: number) => void,
    update: (record: number) => void,
    onModalCmtPost: (record: DataType) => void,
    findUrlbyThumbnail: (id: number, url?: string) => React.ReactNode,
    copy: (slug: string) => void,
    updatePriority: (id: number, value: number) => void,
) => [
        {
            title: 'Thứ tự',
            dataIndex: 'id',
            width: '2%',
            key: 'id',
        },
        {
            title: 'Thứ tự hiển thị',
            dataIndex: 'priority',
            width: '8%',
            key: 'priority',
            sorter: (a: any, b: any) => a.priority - b.priority,
            render: (text: string, record: any, index: number) => {
                // console.log("record: ", record)
                return <EditableCell text={text} record={record} index={index} updatePriority={updatePriority} />
            }
        },
        {
            title: 'Hình ảnh',
            dataIndex: 'thumbnail',
            width: '5%',
            key: 'thumbnail',
            render: (_: any, record: DataType) => findUrlbyThumbnail(parseInt(record.thumbnail, 10), record?.thumbnailUrl),
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            width: '60%',
            key: 'title',
            sorter: (a: any, b: any) => a.title.length - b.title.length,
            // filters: [
            //     { text: 'Công khai', value: 'public' },
            //     { text: 'Bản nháp', value: 'private' },
            // ],
            // onFilter: (value: any, record: any) => record.status == value,
            render: (_: any, record: DataType) => {
                let statusValue;
                if (record.status === 'public') {
                    statusValue = <Tag color="green">Công khai </Tag>;
                } else {
                    statusValue = <Tag color="gray"> Bản nháp </Tag>;
                }
                return (
                    <Space direction="vertical" size="small">
                        <p>Tiêu đề: {record.title}</p>
                        <span>Trạng thái: {statusValue}</span>
                    </Space>
                );
            },
        },
        {
            title: 'Ngôn ngữ',
            dataIndex: 'lang',
            key: 'lang',
            width: '10%',
            // filters: [
            //     { text: 'Tiếng Việt', value: 'vi' },
            //     { text: 'Tiếng Anh', value: 'en' },
            // ],
            // onFilter: (value: any, record: any) => record.name.includes(value),
            sorter: (a: any, b: any) => a.lang.length - b.lang.length,
            // sortOrder: "desc",
            // ellipsis: true,
            render: (_: any, record: DataType) => {
                if (record.lang === 'vi') {
                    return <Tag color="red">Tiếng Việt</Tag>;
                } else {
                    return <Tag color="blue">Tiếng Anh</Tag>;
                }
            },
        },
        {
            title: 'Thời gian',
            dataIndex: 'publicDate',
            key: 'publicDate',
            width: '10%',
            sorter: (a: any, b: any) => a.publicDate - b.publicDate,
            render: (_: any, record: DataType) => (
                <>
                    <p>
                        <span style={{ fontWeight: 'lighter', fontSize: '12px' }}>Ngày lên website:</span>
                        {dayjs(Number(record.publicDate) * 1000).format('HH:MM DD/MM/YYYY')}
                    </p>
                    <p>
                        <span style={{ fontWeight: 'lighter', fontSize: '12px' }}>Ngày tạo:</span>
                        {dayjs(Number(record.publicDate) * 1000).format('HH:MM DD/MM/YYYY')}
                    </p>
                    <p>
                        <span style={{ fontWeight: 'lighter', fontSize: '12px' }}>Ngày cập nhật:</span>
                        {dayjs(Number(record.updatedTime) * 1000).format('HH:MM DD/MM/YYYY')}
                    </p>
                </>
            ),
        },
        {
            title: 'Thống kê',
            dataIndex: 'viewCount',
            key: 'viewCount',
            render: (_: any, record: DataType) => <p>Lượt xem: {record.viewCount}</p>,
        },
        {
            title: 'Hành động',
            width: '12%',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle" direction="horizontal" wrap align="center">
                    <Button onClick={() => update(record.id)} icon={<EditOutlined />} />
                    <Popconfirm
                        title="Xoá bản ghi"
                        description="Bạn có chắc chắn muốn xoá bản ghi này?"
                        onConfirm={() => confirm(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button danger icon={<DeleteFilled />} />
                    </Popconfirm>
                    <Button onClick={() => onModalCmtPost(record)} icon={<CommentOutlined />} />
                    <Button onClick={() => copy(record.slug)} icon={<CopyOutlined />} />
                    <a href={`/vi/bai-viet/${record.slug}`} target="_blank" rel="noopener noreferrer">
                        <Button icon={<EyeOutlined />} />
                    </a>
                </Space>
            ),
        },
    ];
