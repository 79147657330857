import React from 'react';
import { Space, Table, Tag, Card, Button, Col } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, SettingOutlined, GroupOutlined, EditFilled } from '@ant-design/icons';
import dayjs from 'dayjs'

interface DataType {
    id: any;
    name: string;
    email: string;
    tel: any;
    created_time: any;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Họ và tên',
        dataIndex: 'name',
        key: 'name',
        width: '20%'
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '20%'
    },
    {
        title: 'Số điện thoại',
        dataIndex: 'tel',
        key: 'tel',
        width: '20%',
        render: (_, { tel }) => (
            <span>0{tel}</span>
        )
    },
    {
        title: 'Thời gian tạo',
        dataIndex: 'created_time',
        key: 'created_time',
        width: '20%'
    },
    {
        title: 'Hành động',
        key: 'action',
        dataIndex: 'action',
        width: '20%',
        render: () => (
            <>
                <Button icon={<EditFilled />}></Button>
                <Button icon={<DeleteOutlined />} danger></Button >
            </>
        ),
    }
];

const data: DataType[] = [
    {
        id: '1',
        name: 'Trần Tâm',
        email: 'Tam../../plasticpeople.vn',
        tel: 786904542,
        created_time: 222222
    },
    {
        id: '1',
        name: 'Nguyen Thi Tron',
        email: 'tron.nguyen../../siamcitycement.com',
        tel: 84918814819,
        created_time: 222222
    }
];

export const TableContact: React.FC = () => {

    return (
        <Card
            title="Danh sách 6 đối tác"
            headStyle={{ textAlign: 'left' }}>
            <Table columns={columns} dataSource={data} />
        </Card>
    )
}
export default TableContact;