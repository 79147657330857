import React from "react";
import { useTranslation } from 'react-i18next'

import { Space } from 'antd';
import { Layout, Form, Select, FormInstance, Divider } from 'antd';



import { option_keywordVN, option_keywordEN } from "../../../data/data";


const { Header, Content, Sider } = Layout;

interface KeywordSectionProps {
    form?: FormInstance<any>
    lang?: string,
    opt_keyword: any[],
}
const KeywordSection: React.FC<KeywordSectionProps> = ({ lang, opt_keyword }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });

    return (
        <Space className="post-content" direction="vertical" size={"small"}>
            <Space className="title-post-content" align="start" size={"small"}>
                <span>{t('keyword')}</span>
            </Space>
            <div className="keyword" >
                <Form.Item
                    label={t('vnKeyword')}
                    name='keyword'
                    rules={[{ required: false }]}>
                    <Select
                        mode="multiple"
                        options={opt_keyword}
                        style={{ width: '100%' }}
                        placeholder={t('selectKeyword')} />
                </Form.Item>
            </div>
        </Space>
    )
}

export default KeywordSection;