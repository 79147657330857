import { matchRoutes, useLocation, useNavigate, Outlet, Link } from "react-router-dom"
import {
    RightOutlined,
    CopyOutlined,
    UndoOutlined,
    StopOutlined,
    BookFilled,
    PoweroffOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    UserOutlined,
    PlusCircleOutlined,
    CommentOutlined,
    DropboxOutlined,
    TagsOutlined,
    VideoCameraOutlined,
    FileImageOutlined,
    AppstoreOutlined,
    WindowsOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';

const getIcon = (icon: string) => {
    switch (icon) {
        case "right-outlined":
            return <RightOutlined />
        case "copy-outlined":
            return <CopyOutlined />
        case "undo-outlined":
            return <UndoOutlined />
        case "stop-outlined":
            return <StopOutlined />
        case "book-filled":
            return <BookFilled />
        case "poweroff-outlined":
            return <PoweroffOutlined />
        case "question-circle-outlined":
            return <QuestionCircleOutlined />
        case "setting-outlined":
            return <SettingOutlined />
        case "user-outlined":
            return <UserOutlined />
        case "plus-circle-outlined":
            return <PlusCircleOutlined />
        case "comment-outlined":
            return <CommentOutlined />
        case "dropbox-outlined":
            return <DropboxOutlined />
        case "tags-outlined":
            return <TagsOutlined />
        case "video-camera-outlined":
            return <VideoCameraOutlined />
        case "file-image-outlined":
            return <FileImageOutlined />
        case "app-store-outlined":
            return <AppstoreOutlined />
        case "window-outlined":
            return <WindowsOutlined />
    }

    return null
}

export const flattenMenuItem = (items: any, parent_id: string | undefined): any[] => {
    let ret = []
    for (var item of items) {
        const { id, label, icon, sideIcon, link, hidden, children } = item
        ret.push({
            "id": id,
            "hidden": hidden,
            "label": label,
            "icon": icon,
            "sideIcon": sideIcon,
            "link": link,
            "parent_id": parent_id,
            "node": item
        })
        if (children && children.length > 0) {
            let rret = flattenMenuItem(children, id)
            ret.push(...rret)
        }
    }
    return ret
}
export const findParent = (items: any, pathname: string) => {
    let fitems = flattenMenuItem(items, undefined)
    let nodes = fitems.filter((item: any) => item.link == pathname)
    if (nodes && nodes.length > 0) {
        // console.log("Current Node: ", nodes[0])
        let parents = fitems.filter((item: any) => item.id == nodes[0].parent_id)
        if (parents && parents.length > 0) {
            // console.log("Current parent: ", parents[0])
            return parents[0].node
        }
        return nodes[0].node
    }
    return undefined
}
export const ToMenuItem = (items: any, pid: number) => {
    return items.filter((item: any) => !item?.hidden).map((item: any, index: number) => {
        const { id, label, icon, link, hidden, children } = item
        // let id = index + 1
        // let key = pid == 0 ? "" + `${id}` : "" + `${pid}.${id}`
        // console.log("ToMenuItem.key: ", key)
        const LinkElement = () => {
            if (link) {
                return <Link to={link}>{label} </Link>
            }
            return label
        }
        if (children && children.length > 0) {
            return {
                key: "" + id,
                label: <LinkElement />,
                icon: getIcon(icon),
                children: ToMenuItem(children, index + 1)
            }
        }
        return {
            key: "" + id,
            label: <LinkElement />,
            icon: getIcon(icon),
        }
    })
}

export const ToSideMenuItem = (items: any, pid: number) => {
    return items.filter((item: any) => item?.id).map((item: any, index: number) => {
        const { id, label, icon, sideIcon, link, children } = item

        // let id = index + 1
        // let key = pid == 0 ? "" + `${id}` : "" + `${pid}.${id}`
        // console.log("ToMenuItem.key: ", key)
        const LinkElement = () => {
            if (link) {
                return <Link to={link}>{label} </Link>
            }
            return label
        }
        if (children && children.length > 0) {
            return {
                key: "" + id,
                label: <LinkElement />,
                icon: getIcon(sideIcon ? sideIcon : icon),
                children: ToMenuItem(children, index + 1)
            }
        }
        return {
            key: "" + id,
            label: <LinkElement />,
            icon: getIcon(sideIcon ? sideIcon : icon),
        }
    })
}
