import { useState, useEffect } from "react"
import { Col, List, Typography, Space, Button, Modal, Tree, Form, Input, Popconfirm } from 'antd';
import { FolderFilled } from '@ant-design/icons';
import Icon, { FolderAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { DataNode, DirectoryTreeProps } from 'antd/es/tree';


const { DirectoryTree } = Tree;

const FolderList = (props: any) => {
    const {
        treeData,
        nfile,
        selectedFolder,
        handleCreateFolder,
        handleSelectNode,
        handleEditFolder,
        handleDeleteFolder,
    } = props
    console.log("treeData: ", treeData)
    const [edit, setEdit] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldValue("name", selectedFolder?.name)
    }, [selectedFolder])

    const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
        console.log('Trigger Select', keys, info);
        if (handleSelectNode) {
            handleSelectNode(keys[0])
        }
    };

    const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
        // console.log('Trigger Expand', keys, info);
    };

    const onCancel = () => {
        setIsModalOpen(false)
    }

    const onFinish = (values: any) => {
        const { name } = values

        if (edit) {
            if (handleEditFolder) {
                handleEditFolder(selectedFolder?.id, name)
            }
        } else {
            if (handleCreateFolder) {
                handleCreateFolder(name)
            }
        }

        setIsModalOpen(false)
    };

    const addFolder = () => {
        setEdit(false)
        setIsModalOpen(true)
    }
    const editFolder = () => {
        setEdit(true)
        setIsModalOpen(true)
    }
    const deleteFolder = () => {
        console.log("Delete folder")
        if (handleDeleteFolder) {
            handleDeleteFolder(selectedFolder.id)
        }
    }

    const description = `Are you sure to delete ${selectedFolder?.name} ?`

    return (
        <Col className="gutter-row folder-list" span={5} style={{ borderRightStyle: "inset", borderRightWidth: 1 }} >
            <Space style={{ display: "flex", justifyContent: "space-between", paddingTop: 10, paddingBottom: 10 }}>
                <Typography.Text >
                    <FolderFilled />
                    <span style={{ fontWeight: "bold" }}> Media  ({nfile})</span>
                </Typography.Text>
                <Space wrap size="small">
                    <Button onClick={addFolder} icon={<FolderAddOutlined />} type="text" size="small" />
                    {selectedFolder && <Button onClick={editFolder} icon={<EditOutlined />} type="text" size="small" />}
                    {selectedFolder && <Popconfirm
                        title="Delete the folder"
                        description={description}
                        onConfirm={() => deleteFolder()}
                        // onCancel={cancel}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button icon={<DeleteOutlined />} type="text" size="small" />
                    </Popconfirm>}
                </Space>
            </Space>
            <DirectoryTree
                multiple
                defaultExpandAll
                onSelect={onSelect}
                onExpand={onExpand}
                treeData={treeData}
            />
            <Modal title={edit ? "Chỉnh Sửa Thư Mục" : "Thêm Thư Mục"} onCancel={onCancel} open={isModalOpen} footer={null}>
                <Form
                    name="create-folder"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Không được để trống...' }]}
                    >
                        <Input placeholder='Tên thư mục' />
                    </Form.Item>
                    <Form.Item>
                        <Button style={{ "float": "right" }} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Col >
    );
};

export default FolderList;