import React, { useEffect } from "react";
import { Select, Button, Space, Input, DatePicker, Form, Tree } from 'antd';
import { PlusOutlined, CopyOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import type { SearchProps } from "antd/es/input";
import { TreeSelect } from 'antd';

import { option_lang, option_status } from "../../../data/data";
import { useNavigate } from "react-router-dom";
import './index.css'

interface HeaderList {
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
    options?: any[];
}
const { Search } = Input

const HeaderList: React.FC<HeaderList> = (props) => {
    const { options, onRefresh, onSearch } = props

    const [form] = Form.useForm();
    const navigate = useNavigate();

    // const onFinish = (values: any) => {
    //     handleSearch(values)
    // }
    const handleSearch = () => {
        let values = form.getFieldsValue()
        console.log("Article.Search: ", values)
        if (onSearch) {
            onSearch(values)
        }
    }
    const handleRefresh = () => {
        let values = form.getFieldsValue()
        console.log("Article.Refresh: ", values)
        if (onRefresh) {
            onRefresh(values)
        }
    }
    const onChange = (value: string) => {
        console.log(`selected ${value}`);
        let values = form.getFieldsValue()
        console.log("Article.Refresh: ", values)
        if (onSearch) {
            onSearch(values)
        }
    };
    const onCreate = () => {
        navigate("/article/create")
    }

    return (
        <div className="wrapper">
            <div className="title-option" >
                <Form
                    form={form}
                    name="control-hooks"
                    // onFinish={onFinish}
                    style={{ width: ' 100% ', display: 'flex', justifyContent: 'space-between' }}
                >
                    <Space className="title">
                        <CopyOutlined />
                        <span>Bài báo</span>
                    </Space>
                    <Space className="option-box" align="center" wrap style={{ width: '80%' }}>
                        <Form.Item
                            noStyle
                            name='keyword'>
                            <Search onSearch={handleSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            name='status' initialValue={'all'}>
                            <Select style={{ width: 160 }} options={option_status} onChange={onChange} />
                        </Form.Item>
                        {/* <Form.Item
                            noStyle

                            name='show_on_banner' initialValue={'showOnBanner'}>
                            <Select options={option_display} style={{ width: 200 }} />
                        </Form.Item> */}
                        <Form.Item
                            noStyle
                            name='lang' initialValue={'all'}>
                            <Select options={option_lang} style={{ width: 150 }} onChange={onChange} />
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button onClick={onCreate} type="primary" icon={<PlusOutlined />}>Thêm mới</Button>
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button type="primary"
                                icon={<RedoOutlined />}
                                size="middle"
                                onClick={handleRefresh} />
                        </Form.Item>
                    </Space>
                </Form>
            </div>
        </div >


    )
}

export default HeaderList
