import React from "react";
import { Input, Form, Typography, Divider } from 'antd';
import './comment.css'
import HeaderAdminReply from "./headerAdminReply";
import TableRepComment from "./tableReply";

interface AdminReplyComment {
    form?: any,
    initialValues?: any,
    onNew: (values: any) => void
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
}

const { TextArea } = Input
const FormAnswer: React.FC<AdminReplyComment> = (props) => {
    const { form, onNew, onSearch, onRefresh, } = props

    return (<>
        <Form layout="vertical"
            form={form}
            style={{ padding: " 15px 15px" }}
        >
            <Form.Item
                name='content'
                label="Danh sách trả lời bình luận của: "
            >
                <TextArea disabled autoSize={{ minRows: 3, maxRows: 5 }}
                />
            </Form.Item>
            <Divider />
            <Form.Item
                name="headerReply"
            >
                <HeaderAdminReply onNew={onNew} onSearch={onSearch} onRefresh={onRefresh} />
            </Form.Item>
        </Form>
        <TableRepComment /> 

    </>
    )
}

export default FormAnswer 