import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import HeaderDoc from "./header";
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Layout, Modal, Button, Form, Divider, Tabs } from 'antd';
import './document.css'
import { list, create, del, update, importDoc } from "./documentSlice";
import { CopyFilled } from '@ant-design/icons';
import { TabsProps } from "antd/lib";
import TableList from "./table";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import dayjs from 'dayjs'
import FormDocument from "./formDocument";
import FormUpdateDoc from "./formUpdate";
import * as XLSX from 'xlsx'
import { notification } from "antd";
import { MediaProvider } from "../../hooks/useMediaContext";
import FormDetailDoc from "./formDetailDoc";
import FormUpdateDetailDoc from "./formUpdateDetailDoc";
import CreateDetailDoc from "./formCreateDetailDoc";
const { Header, Content, Sider } = Layout;

interface DataType {
    id: number;
    fileId: number;
    slug: string;
    title: string;
    excerpt: string;
    content: string;
    status: string;
    effectStatus: string;
    viewCount: number;
    downloadCount: number;
    lang: string;
    type: string;
    symbol: string;
    agencyIssued: string;
    issuedDate: dayjs.Dayjs;
    publicDate: dayjs.Dayjs;
    deleted: boolean;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}

interface LayoutDocument {

}
const Document: React.FC<LayoutDocument> = ({ }) => {
    const dispatch = useAppDispatch()
    const doc = useAppSelector(state => state.doc)
    const [api, contextHolder] = notification.useNotification()

    const [data, setData] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false)
    const [isModalCreateDetailDoc, setIsModalCreateDetailDoc] = useState(false)
    const [selectedItem, setSelectedItem] = useState<DataType | null>(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [loadingData, setLoadingData] = useState(false);
    const [word, setWord] = useState<string>("");
    const [disabled, setDisabled] = useState(true)
    const now = dayjs();
    const nowExport = dayjs().format('DD/MM/YYYY')
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const [activeTabKeyUpdate, setActiveTabKeyUpdate] = useState<string>('1');
    const [form] = Form.useForm()
    const [formUpdate] = Form.useForm();

    useEffect(() => {
        fetchData(page, pageSize);
    }, [word]);

    useEffect(() => {
        const docItems = doc?.items;
        if (docItems) {

            if (doc?.pagination?.total) {
                setTotal(doc?.pagination?.total)
                setPage(doc?.pagination?.page)
                setPageSize(doc?.pagination?.size)

            } else {
                setTotal(docItems.length)
            }

            setData(docItems)
        }
    }, [doc?.items])

    useEffect(() => {
        if (selectedItem) {
            let { id,
                slug,
                fileId,
                title,
                excerpt,
                content,
                status,
                viewCount,
                downloadCount,
                lang,
                type,
                symbol,
                agencyIssued,
                issuedDate,
                publicDate,
                deleted,
                effectStatus
            } = selectedItem;
            formUpdate.setFieldsValue({
                id,
                slug,
                title,
                fileId,
                excerpt,
                content,
                status,
                viewCount,
                downloadCount,
                lang,
                type,
                symbol,
                agencyIssued,
                issuedDate: dayjs(Number(issuedDate) * 1000),
                publicDate: dayjs(Number(publicDate) * 1000),
                deleted,
                effectStatus
            });
        }
    }, [selectedItem]);


    const fetchData = (page: number, pageSize: number) => {
        let pagination = {
            page: page,
            size: pageSize,
            word: { word }
        }
        setLoadingData(true);
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false);
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Lấy dữ liệu thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)

        })
    }
    const closeModal = () => {
        setIsModalOpen(false)
        setDisabled(true)
        setActiveTabKey('1')
        form.resetFields();
    }
    const onRefresh = (value: any) => {
        console.log("Refresh data")
        fetchData(page, pageSize)
    }

    const handleSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setWord(values)
    };

    const onNew = (value: any) => {
        setIsModalOpen(true)
        setDisabled(true)
    }

    const closeModalUpdate = () => {
        setIsModalUpdateOpen(false)
        formUpdate.resetFields()
    }

    const handleSubmitDoc = (values: any) => {
        const formValues = {
            id: values.id,
            fileId: values.fileId,
            slug: values.slug,
            title: values.title,
            excerpt: values.excerpt,
            content: values.content,
            status: values.status,
            effectStatus: values.effectStatus,
            viewCount: values.viewCount,
            downloadCount: values.downloadCount,
            lang: values.lang,
            type: values.type,
            symbol: values.symbol,
            agencyIssued: values.agencyIssued,
            publicDate: values.publicDate.unix(),
            issuedDate: values.issuedDate ? values.issuedDate.unix() : 0,
            deleted: values.deleted,
            createdBy: values.createdBy,
            updatedBy: values.updatedBy,
            createdTime: now.unix(),
            updatedTime: now.unix(),
        }
        setLoadingData(true);
        console.log("[values create thumnail]", formValues)
        dispatch(create(formValues)).unwrap().then(() => {
            setLoadingData(false)
            api.open({
                message: 'Thành công',
                description: 'Tạo thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Tạo thất bại: ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
        setDisabled(false)
        setActiveTabKey('2');

    }
    // const handleSearch = async (values: any) => {
    //     try {
    //         setData([]);
    //         const searchData = await Filter(values);
    //         setData(searchData);
    //     } catch (error) {
    //         console.log("[ERROR] handleSearch: ", error);
    //     }
    // };
    const handleDeleteDoc = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap().then(() => {
            api.open({
                message: 'Thành công',
                description: 'Xóa thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Xóa thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
    };

    const handleSave = () => {
        if (selectedItem) {
            formUpdate.validateFields()
                .then((values) => {
                    const updatedRecord = { ...selectedItem, ...values };
                    const updateValues = {
                        id: updatedRecord.id,
                        fileId: updatedRecord.fileId,
                        slug: updatedRecord.slug,
                        title: updatedRecord.title,
                        excerpt: updatedRecord.excerpt,
                        content: updatedRecord.content,
                        status: updatedRecord.status,
                        effectStatus: updatedRecord.effectStatus,
                        viewCount: updatedRecord.viewCount,
                        downloadCount: updatedRecord.downloadCount,
                        lang: updatedRecord.lang,
                        type: updatedRecord.type,
                        symbol: updatedRecord.symbol,
                        agencyIssued: updatedRecord.agencyIssued,
                        issuedDate: updatedRecord.issuedDate ? dayjs(updatedRecord.issuedDate).unix() : 0,
                        publicDate: updatedRecord.publicDate.unix(),
                        deleted: updatedRecord.deleted,
                        createdBy: updatedRecord.createdBy,
                        updatedBy: updatedRecord.updatedBy,
                        createdTime: now.unix(),
                        updatedTime: now.unix(),
                    }
                    dispatch(update(updateValues)).unwrap().then(() => {
                        api.open({
                            message: 'Thành công',
                            description: 'Cập nhật thành công',
                            duration: 0,
                            type: 'success'
                        })
                        closeModalUpdate()
                        fetchData(page, pageSize);
                    }).catch((error) => {
                        api.open({
                            message: 'Thất bại',
                            description: `Cập nhật thất bại: ${error}`,
                            duration: 0,
                            type: 'error'
                        })
                        closeModalUpdate()
                    });
                })

        }
    };
    // const getMaxWidth = (property: keyof DataType, rows: DataType[], defaultValue = 10): number => {
    //     return rows.reduce((maxWidth, row) => Math.max(maxWidth, String(row[property]).length), defaultValue);
    // };
    // const exportData = () => {
    //     console.log('dataexport', data)
    //     const rows = data.map(row => ({
    //         id: row.id,
    //         slug: row.slug,
    //         title: row.title,
    //         excerpt: row.excerpt,
    //         content: row.content,
    //         status: row.status === "public" ? "Công khai" : "Bản nháp",
    //         createdTime: dayjs((row.createdTime) * 1000).format("HH:MM DD/MM/YYYY").toString(),
    //         updatedTime: dayjs((row.updatedTime) * 1000).format("HH:MM DD/MM/YYYY"),
    //         viewCount: row.viewCount,
    //         downloadCount: row.downloadCount,
    //         lang: row.lang === "vi" ? "Tiếng Việt" : "Tiếng Anh",
    //         type: row.type,
    //         symbol: row.symbol,
    //         agencyIssued: row.agencyIssued
    //     }))
    //     const ws = XLSX.utils.json_to_sheet(rows);
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Data');
    //     const header = ["ID", "Đường dẫn", "Tiêu đề", "Tóm tắt", "Nội dung", "Trạng thái", "Ngày tạo", "Ngày cập nhật", "Lượt xem", "Lượt tải", "Ngôn ngữ", "Thể loại", "Ký hiệu", "Cơ quan ban hành"]
    //     XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });
    //     if (!ws["!cols"]) ws["!cols"] = []
    //     ws["!cols"][1] = { wch: getMaxWidth('slug', data) }
    //     ws["!cols"][2] = { wch: getMaxWidth('title', data) }
    //     ws["!cols"][3] = { wch: getMaxWidth('excerpt', data) }
    //     ws["!cols"][4] = { wch: getMaxWidth('content', data) }
    //     ws["!cols"][5] = { wch: getMaxWidth('status', data) }
    //     ws["!cols"][6] = { wch: getMaxWidth('createdTime', data) }
    //     ws["!cols"][7] = { wch: getMaxWidth('updatedTime', data) }
    //     ws["!cols"][8] = { wch: getMaxWidth('viewCount', data) }
    //     ws["!cols"][9] = { wch: getMaxWidth('downloadCount', data) }
    //     ws["!cols"][10] = { wch: getMaxWidth('lang', data) }
    //     ws["!cols"][11] = { wch: getMaxWidth('type', data) }
    //     ws["!cols"][12] = { wch: getMaxWidth('symbol', data) }
    //     ws["!cols"][13] = { wch: getMaxWidth('agencyIssued', data) }
    //     XLSX.writeFile(wb, `Doc ${nowExport}.xlsx`);
    // };

    const dataDoc = [
        {
            key: '1',
            title: 'Q&A xu ly chat thai'
        },
        {
            key: '2',
            title: 'Q&A xu ly chat thai 2 '

        }
    ]

    const getMaxWidth = (property: keyof DataType, rows: DataType[], defaultValue = 10): number =>
        rows.reduce((maxWidth, row) => Math.max(maxWidth, String(row[property]).length), defaultValue);

    const header: (keyof DataType)[] = ["id", "fileId", "slug", "title", "excerpt", "content", "status", "effectStatus",
        "viewCount", "downloadCount", "lang", "type", "symbol", "agencyIssued", "issuedDate", "publicDate",
        "deleted", "createdBy", "updatedBy", "createdTime", "updatedTime"];

    const exportData = () => {

        const rows: DataType[] = data.map(row =>
            header.reduce((acc: any, key) => {
                acc[key] = row[key];
                return acc;
            }, {} as DataType)
        );

        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');

        XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });

        if (!ws["!cols"]) {
            ws["!cols"] = [];
        }

        header.forEach((property, index) => {
            if (ws["!cols"]) {
                ws["!cols"][index] = { wch: getMaxWidth(property, rows) };
            }
        });

        XLSX.writeFile(wb, `Doc ${nowExport}.xlsx`);
    };
    const importData = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const dataImport = new Uint8Array(e.target.result);
            const wb = XLSX.read(dataImport, { type: 'array' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const jsonData: any[][] = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const convertExcelDataToDataType = (excelData: any[][]): any[] => {

                const arrayOfObjects = excelData.slice(1).map(array => {
                    return array.reduce((obj: any, value, index) => {
                        obj[header[index]] = value;
                        return obj;
                    }, {});
                });

                return arrayOfObjects;
            };
            const arrayOfObject = convertExcelDataToDataType(jsonData);
            dispatch(importDoc(arrayOfObject))
        };
        reader.readAsArrayBuffer(file);
    };
    const onImport = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            importData(file)
        }
    }

    const initialValues = (record: DataType) => {
        setSelectedItem(record);
        setActiveTabKeyUpdate('1')
        setIsModalUpdateOpen(true);
    };

    const onNewCreateDetailDoc = () => {
        setIsModalCreateDetailDoc(true)
    }

    const onClickDetailDoc = (record: DataType) => {
        setActiveTabKeyUpdate('2')
        setIsModalUpdateOpen(true);
    }
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chung',
            children: <FormDocument form={form} handleSubmitDoc={handleSubmitDoc} />
        },
        {
            key: '2',
            label: "Tài liệu",
            children: <FormDetailDoc data={dataDoc} loadingData={loadingData} onNew={onNewCreateDetailDoc} />,
            // disabled: disabled // UNCOMMENT ME
        }
    ]

    const itemsUpdate: TabsProps['items'] = [
        {
            key: '1',
            label: 'Thông tin chung',
            children: <FormUpdateDoc form={formUpdate} handleUpdate={handleSave} />

        },
        {
            key: '2',
            label: "Tài liệu",
            children: <FormUpdateDetailDoc data={dataDoc} loadingData={loadingData} onNew={onNewCreateDetailDoc} />
        }
    ]
    const onChangeTab = (key: string) => {
        setActiveTabKey(key);
    }

    return (
        <MediaProvider>
            <div className="wrapper">
                {contextHolder}
                <div className="titleBox">
                    <div >
                        <HeaderDoc
                            onExport={exportData}
                            onNew={onNew}
                            onSearch={handleSearch}
                            onRefresh={onRefresh}
                            onImport={onImport} />
                    </div>
                </div>
                <div className="main-content">
                    <div className="title">
                        <span>
                            Danh sách {total} tài liệu
                        </span>
                    </div>
                    <div className="data">
                        <TableList
                            page={page}
                            pageSize={pageSize}
                            total={total}
                            fetchData={fetchData}
                            initialValues={initialValues}
                            loadingData={loadingData}
                            data={data}
                            handleDelete={handleDeleteDoc}
                            createDoc={onClickDetailDoc}
                        />
                    </div>
                </div>
                <Modal title="Thông tin chung"
                    open={isModalOpen}
                    onCancel={closeModal}
                    footer={[
                        <Button key="close" onClick={closeModal}>Đóng</Button>,
                        <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />} onClick={form.submit}>Luu và tiếp tục</Button>
                    ]}
                    mask={false}
                    width={1000}
                >
                    <Tabs 
                    defaultActiveKey="1" 
                    activeKey={activeTabKey} 
                    items={items}
                    onChange={onChangeTab} // COMMENT ME 
                    tabPosition="left" />
                </Modal>
                <Modal
                    title={`Tài liệu ${selectedItem?.title}`}
                    open={isModalUpdateOpen}
                    onCancel={() => { setIsModalUpdateOpen(false) }}
                    width={1000}
                    footer={[
                        <Button key="close" onClick={() => { setIsModalUpdateOpen(false) }}>
                            Đóng
                        </Button>,
                        <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />} onClick={formUpdate.submit}>Lưu lại</Button>
                    ]}
                >
                    <Tabs items={itemsUpdate} tabPosition="left" activeKey={activeTabKeyUpdate} />
                </Modal>
                <Modal
                    title="Thêm mới"
                    open={isModalCreateDetailDoc}
                    onCancel={() => setIsModalCreateDetailDoc(false)}
                    footer={[
                        <Button key="close" onClick={() => { setIsModalCreateDetailDoc(false) }}>Đóng</Button>,
                        <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />} onClick={formUpdate.submit}>Lưu lại</Button>
                    ]}>
                    <CreateDetailDoc />
                </Modal>
            </div>
        </MediaProvider>


    )
}

export default Document
