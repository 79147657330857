import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

import { Space, Tabs } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css';

import { create } from '../articleSlice'
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Array2Tree, treeOption, Array2Option } from "../../../util/helper"
import dayjs from 'dayjs'
import type { TabsProps } from 'antd';
import { notification } from 'antd';
import { MediaProvider } from "../../../hooks/useMediaContext";

import Form from "./form"
import './index.css'
import { useNavigate } from "react-router-dom";

interface Props {
}

const ArticleCreate: React.FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const now = dayjs()

    const { t, i18n } = useTranslation()
    const navigate = useNavigate();

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<any[]>([]);

    const onChangeLang = (key: string) => {
        i18n.changeLanguage(key)
    };

    // useEffect(() => {

    // }, []);

    const handlePublish = async (values: any) => {
        values["status"] = "public"
        handleSave(values)
    };
    const handleSave = async (values: any) => {
        const formValues = {
            showOnBanner: values.showOnBanner,
            id: values.id,
            slug: values.slug,
            title: values.title,
            excerpt: values.excerpt,
            content: values.content,
            status: values.status,
            commentStatus: values.commentStatus,
            lang: values.lang,
            source: values.source,
            sourceUrl: values.sourceUrl,
            publicDate: values.publicDate.unix(),
            finishDate: values.finishDate.unix(),
            createdTime: now.unix(),
            updatedTime: now.unix(),
            thumbnail: values.thumbnail,
            thumbnailUrl: values.thumbnailUrl,
        }
        setLoading(true);
        console.log('formValues', formValues)
        dispatch(create(formValues)).unwrap().then(() => {
            setLoading(false)
            api.open({
                message: 'Create successfully',
                description: 'Create article successfully',
                duration: 0,
                type: 'success'
            });
        }).catch((error) => {
            api.open({
                message: 'Failed',
                description: `Create failed ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoading(false)
        })
    };

    const items: TabsProps['items'] = [
        {
            key: 'vi',
            label: 'Tiếng Việt',
            children: <Form
                lang="vi"
                handleSave={handleSave}
                handlePublish={handlePublish}
                options={options}
            />,
        },
        // {
        //     key: 'en',
        //     label: 'English',
        //     children: <Form
        //         lang="en"
        //         handleSave={handleSave}
        //         handlePublish={handlePublish}
        //         options={options}
        //         opt_keyword={keyWordOptionsEn}
        //     />,
        // }
    ]
    return (
        <MediaProvider >
            <div className="wrapper">
                <div className="titleBox">
                    <div className="title">
                        <Space>
                            <>
                                <PlusCircleFilled />
                                <span>Thêm bài báo </span>
                            </>
                        </Space>
                    </div>
                </div>
                {contextHolder}
                <Tabs defaultActiveKey="1" items={items} onChange={onChangeLang} />
            </div >
        </MediaProvider>

    )
}

export default ArticleCreate;