import React from "react";
import HeaderDetailDoc from "./headerDetailDoc";
import ModalSection from "../../component/modal_section";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib";

interface FormDetailDoc {
    form?: FormInstance
    data?: any[]
}

const CreateDetailDoc: React.FC<FormDetailDoc> = ({ form, data }) => {
    return (
        <Form
            layout="vertical"
            form={form}
        >
            <Form.Item
                name="title"
                label="Tên tài liệu">
                <Input />
            </Form.Item>
            <Form.Item
                name="doc_id"
                label="Tài liệu đính kèm "
            >
                <ModalSection />
            </Form.Item>
        </Form>
    )
}

export default CreateDetailDoc