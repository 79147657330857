import axios from "axios";

import fetch, {queryOne,  queryList } from "./request"

// const IMAGE_BASE_URL="http://epr.vn"


export const getFileInfos = async (ids: string[]) : Promise<any> => {
    let idsStr = ids.join(",")
    console.log("getFileInfo: idsStr = ", idsStr)
    return fetch(`api/v1/upload/file-info?ids=${idsStr}`,  'GET', true, {} )
}
export const load_folder_structure = async () : Promise<any> => {
    return fetch(`api/upload/folder-structure`,  'GET', true, {} )
}

export const create_folder = async (name: string, parent_id: number) : Promise<any>  => {
    let info = {
        "name": name,
        "parent": parent_id
    }
    return fetch(`api/upload/folder`, 'POST', true, info)
}
export const edit_folder = async ( id: number,name: string) : Promise<any>  => {
    let info = {
        "name": name,
        "id": id
    }
    return fetch(`api/upload/folder-update`, 'PUT', true, info)
}
export const delete_folder = async (id: number) : Promise<any>  => {
    return fetch(`api/upload/folder-delete?id=${id}`,  'DELETE',true, {})
}
export const get_folder = async (id: number): Promise<any>  => {
    //Load folder detail 
    return fetch(`api/upload/folder-detail?id=${id}`, 'GET', true, {})
}

export  const list_files = async (id: number): Promise<any>  => {
     //Load files in folder
    return fetch(`api/upload/files?folder_id=${id}`, 'GET', true, {})
}

export const upload_file = async (options: any, folderId: string) => {

    const { onSuccess, onError, file, onProgress } = options;

    console.log("uploadFile: file ", file)
    let token = localStorage.getItem('cjwt')

    const fmData = new FormData();
    const config = {
        headers: {
            "content-type": "multipart/form-data",
            'Authorization': `Bearer ${token}`,
        },
        onUploadProgress: (event: any) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            onProgress({ percent: (event.loaded / event.total) * 100 });
        }
    };
    let fileInfo = {
        name: file.name,
        caption: "",
        alternativeText: file.uid,
        folder: folderId,
        size: file.size,
        ext: "",
        mine: file.type,
        url: file.webkitRelativePath
    }
    fmData.append('fileInfo', JSON.stringify(fileInfo));
    fmData.append("files", file);
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/upload`,
            fmData,
            config
        );
        onSuccess("Ok");
        return res.data 
    } catch (err) {
        console.log("Eroor: ", err);
        const error = new Error("Some error");
        onError({ err });
        return {
            code: 400,
            message: "Upload file failed",
        }
    }
}