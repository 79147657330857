import React from "react";
import { Input, Form, Select } from 'antd';
import './comment.css'


interface PreviewComment {
    form?: any,
}

const FormPreviewComment: React.FC<PreviewComment> = (props) => {
    const { form } = props

    const { TextArea } = Input
    return (
        <Form layout="vertical"
            form={form}
        >
            <Form.Item
                name='userName'
                label="Họ và tên"
                rules={[{ required: true, message: 'Tên người bình luận không được để trống.' }]}
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                name='userPhone'
                label="Số điện thoại"
                rules={[{ required: false }]}
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                name='userEmail'
                label="Email"
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                label="Bài viết"
                name='titleByTargetId'
            >
                <Select disabled />
            </Form.Item>
            <Form.Item
                name='content'
                label="Nội dung bình luận"
                rules={[{ required: true, message: 'Nội dung bình luận không được để trống.' }]}>
                <TextArea disabled autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
        </Form>
    )
}

export default FormPreviewComment 