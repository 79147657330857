
import React, { useEffect, useState } from "react";
import { MediaProvider, useMediaContext } from "../../hooks/useMediaContext";
import MediaList from "./list"

import "./media.css"

interface Props {
}


const MediaPage: React.FC<Props> = () => {
    return <>
        <MediaProvider>
            <div className="epr-media-container" >
                <MediaList />
            </div>
        </MediaProvider>
    </>
}

export default MediaPage 