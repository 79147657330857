import { Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import './infoDevice.css'


interface Props {
    ipAddress: any;
    infoDevice: any;
    // signIn: any;
    cardTitle: any;
}


const DeviceInfoCard: React.FC<Props> = ({ cardTitle, ipAddress, infoDevice }) => {

    return (
        <>
            <Card
                title={cardTitle}
                headStyle={{ textAlign: 'left' }}>
                <Space direction="vertical" align="center">
                    <span>Địa chỉ IP: {ipAddress}</span>
                    <span>Thiết bị: {infoDevice}</span>
                    <span>Đăng nhập lúc:</span>
                    <span>Hoạt động cuối lúc: </span>
                </Space>
            </Card>
        </>
    );
};

export default DeviceInfoCard;