import { Card, Input, Form, Select, Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import './home.css'
import { PlusOutlined, FileOutlined } from "@ant-design/icons";
import TableHomeBoxNews from './tableBoxNews'


interface Props {

}


const SettingSupportCard: React.FC = () => {
    const [form] = Form.useForm();
    const { TextArea } = Input
    return (
        <Card
            title="Box hỗ trợ dự án"
            className="content_home"
            actions={[<Button type="primary" icon={<FileOutlined />}>Lưu lại</Button>]}
            headStyle={{ textAlign: "left" }}
        >
            <p style={{ textAlign: 'left', fontWeight: 'lighter', fontSize: 'small' }}>(Lưu ý: Cần nhấn nút "Lưu lại" để lưu các cài đặt bạn đã thêm)</p>

            <Form
                style={{ width: '100%' }}
                form={form}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Mô tả box (Tiếng Việt)"
                            name="box_des"
                            help="Mô tả ngắn là một đoạn mô tả về nội dung mà bạn tự nhập bằng tay, được sử dụng để hiển thị trong giao diện của bạn"
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Mô tả box (Tiếng Anh)"
                            name="box_desEn"
                            help="Mô tả ngắn là một đoạn mô tả về nội dung mà bạn tự nhập bằng tay, được sử dụng để hiển thị trong giao diện của bạn"
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Link bài viết (Tiếng Việt)"
                            name="linkpost"
                            help="Link chi tiết bài viết tiếng Việt"
                            initialValue='https://epr.monre.gov.vn/vi/bai-viet/chi-tiet/ho-tro-tai-chinh-cho-hoat-dong-tai-che-san-pham-bao-bi.html'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Link bài viết (Tiếng Anh)"
                            name="linkpostEn"
                            help="Link chi tiết bài viết tiếng Anh"
                            initialValue='https://epr.monre.gov.vn/vi/bai-viet/chi-tiet/ho-tro-tai-chinh-cho-hoat-dong-tai-che-san-pham-bao-bi.html'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Kết quả 1"
                            name="post"
                            initialValue='0'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Kết quả 3"
                            name="post"
                            initialValue='0'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Kết quả 2"
                            name="post"
                            initialValue='0'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Kết quả 4"
                            name="post"
                            initialValue='0'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default SettingSupportCard;