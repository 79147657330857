import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Radio, Select } from 'antd';

import { option_lang, option_doc, option_status } from "../../data/data";
import type { RadioChangeEvent } from "antd";
import Editor from '../post/comp/editor'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './faq.css'

const { Search, TextArea } = Input

interface HeaderDoc {
    form: any,
    // option_category: any[],
    handleSubmitDoc: (values: any) => void
}

const FormFeedback: React.FC<HeaderDoc> = (props) => {
    const { form, handleSubmitDoc } = props

    const onFinish = (values: any) => {
        console.log("Form.onFinish: ", values)
        if (handleSubmitDoc) {
            handleSubmitDoc(values)
        }
    }



    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
    ];
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='question'
                label="Câu hỏi"
                rules={[{ required: true, message: 'Câu hỏi không được để trống.' }]}
            >
                <Editor />
            </Form.Item>
            <Form.Item
                name='answer'
                label="Câu trả lời "
            >
                <Editor />
            </Form.Item>
            <Form.Item
                name="status"
                label="Trạng thái bài"
                initialValue={'private'}>
                <Radio.Group
                >
                    <Radio
                        value={"private"}>
                        Đã duyệt
                    </Radio>
                    <Radio value={"public"}>
                        Từ chối
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="lang"
                label="Ngôn ngữ"
                initialValue={'vi'}>
                <Radio.Group
                >
                    <Radio
                        value={"vi"}>
                        Tiếng Việt
                    </Radio>
                    <Radio value={"en"}>
                        Tiếng Anh
                    </Radio>
                </Radio.Group>
            </Form.Item>


            <Form.Item
                name='approve_time'
                label="Ngày duyệt"
            >
                <DatePicker />
            </Form.Item>
        </Form>
    )
}

export default FormFeedback 