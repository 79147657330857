import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

import { Space, Tree, Divider } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';

import { Form, FormInstance } from 'antd';

import CategoryFormItem from "./item_category"

interface Props {
    lang?: string
    form?: FormInstance<any>
    options: any[]
}

const CategoySection: React.FC<Props> = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.post-create' });
    const { options } = props
    // console.log("CategoySection: options ", options)
    let msg = t('category_message')

    return (
        <div className="option-wrapper" title={t('category')} >
            <Space className="title-post-content" size={"small"}>
                <span>{t('category')} </span>
            </Space>
            <Divider />
            <Form.Item
                className="category"
                name="category"
                rules={[{ required: false, message: msg }]}  >
                <CategoryFormItem options={options} />
            </Form.Item>
        </div >
    )
}

export default CategoySection;