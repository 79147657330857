import React, { useEffect, useState } from 'react';
import { Divider, Tag, Table, Button, Space, Popconfirm, Spin, Alert, Input } from 'antd';
import dayjs from 'dayjs';

const EditableCell = (props: any) => {
    const { text, record, updatePriority } = props

    const [edit, setEdit] = useState(false)
    const [value, setValue] = useState(text)
    const onClick = () => {
        setEdit(true)
    }
    const onSubmit = () => {
        if (updatePriority) {
            updatePriority(record.id, value)
        }
        setEdit(false)
    }
    const onChange = (e: any) => {
        // console.log(e?.target?.value)
        if (e?.target?.value) {
            setValue(e?.target?.value)
        }
    }
    return <div >
        {!edit && <div onClick={onClick} >{value} </div>}
        {edit &&
            <Space.Compact style={{ width: '100%' }}>
                <Input type="number" min="0"
                    defaultValue={value}
                    onChange={onChange}
                    onMouseLeave={onSubmit}
                    onPressEnter={onSubmit}
                />
            </Space.Compact>}
    </div>
}

export default EditableCell