export const option_status = [
    {
        value: 'all',
        label: 'Trạng thái: Tất cả',
    },
    {
        value: 'public',
        label: 'Công khai',
    },
    {
        value: 'private',
        label: 'Bản nháp',
    },
]
export const option_display = [
    {
        value: 'showOnBanner',
        label: 'Hiển thị banner trang tin',
    },
    {
        value: 'on',
        label: 'Có',
    },
    {
        value: 'off',
        label: 'Không',
    }
]
export const option_lang = [
    {
        value: 'all',
        label: 'Tất cả ngôn ngữ ',
    },
    {
        value: 'en',
        label: 'Tiếng Anh',
    },
    {
        value: 'vi',
        label: 'Tiếng Việt ',
    }
]

export const option_staff = [
    {
        value: 'EPRAdmin',
        label: 'EPR Admin',

    },
    {
        value: 'qldl',
        label: 'QLDL',
    },
    {
        value: 'quantrihethong',
        label: 'Quản trị hệ thống',
    },
    {
        value: 'thehoa',
        label: 'Thế Hòa',
    }
]
export const option_keywordVN = [
    {
        value: 'xulychatthai',
        label: 'Xử lý chất thải',
    },
    {
        value: 'hoithao',
        label: 'Hội thảo',
    },
    {
        value: 'taphuan',
        label: 'Tập huấn',
    }
]

export const option_keywordEN = [
    {
        value: 'wastetreatment',
        label: 'Waste treatment',
    },
    {
        value: 'Conference',
        label: 'Conference',
    },
    {
        value: 'training',
        label: 'Training',
    }
]
export const option_filter_category = [
    {
        value: 'all',
        label: 'Tất cả chuyên mục ',
        // children: 'Tất cả chuyên mục '
    },
    {
        value: 'blank',
        label: 'Trống',
        // children: 'Trống'
    },
    {
        value: 'taichechatthai',
        label: 'Tái chế chất thải',
        // children: 'Tái chế chất thải'
    },
    {
        value: 'xulychatthai',
        label: 'Xử lý chất thải',
        // children: 'Xử lý chất thải'
    },
    {
        value: 'gioithieu',
        label: 'Giới thiệu ',
        // children: 'Giới thiệu'
    },
    {
        value: 'epr',
        label: '-- Về EPR',
        // children: '-- Về EPR'
    },
    {
        value: 'thietche',
        label: '-- Các thiết chế EPR',
        // children: '-- Các thiết chế EPR'
    },
    {
        value: 'tintucsukien',
        label: ' Tin tức - sự kiện',
        // children: ' Tin tức - sự kiện'
    },
    {
        value: 'tintuc',
        label: '--Tin tức',
        // children: '--Tin tức'
    },
    {
        value: 'phantichbinhluan',
        label: '-- Phân tích bình luận',
        // children: '-- Phân tích bình luận'
    },
    {
        value: 'sukien',
        label: '-- Sự kiện ',
        // children: '-- Sự kiện '
    },
]
export const option_category = [
    {
        key: 'trong',
        title: 'Trống',
    },
    {
        key: 'taichechatthai',
        title: 'Tái chế chất thải'
    },
    {
        key: 'xulychatthai',
        title: 'Xử lý chất thải'
    },
    {
        key: 'gioithieu',
        title: 'Giới thiệu',
        children: [
            {
                key: 'epr',
                title: 'Về EPR'
            },
            {
                key: 'thietche',
                title: 'Các thiết chế EPR'
            }
        ]
    },
    {
        key: 'tintucsukien',
        title: 'Tin tức - Sự kiện',
        children: [
            {
                key: 'tintuc',
                title: 'Tin tức'
            },
            {
                key: 'phantichbinhluan',
                title: 'Phân tích - Bình luận'
            },
            {
                key: 'sukien',
                title: 'Sự kiện'
            }
        ]
    }

]
export const option_doc = [
    {
        value: 'all',
        label: 'Chọn loại tài liệu'
    },
    {
        value: 'van-ban-phap-luat',
        label: 'Văn bản pháp luật'
    },
    {
        value: 'hoi-thao-epr',
        label: 'Hội thảo EPR'
    },
    {
        value: "nghien-cuu-bao-cao",
        label: 'Nghiên cứu, báo cáo EPR'
    }
]

export const option_status_FAQ = [
    {
        value: 'all',
        label: 'Trạng thái: Tất cả'
    },
    {
        value: 'approved',
        label: 'Đã duyệt'
    },
    {
        value: 'deny',
        label: 'Từ chối'
    },
    {
        value: 'waiting',
        label: 'Chờ duyệt'
    },
    {
        value: 'public',
        label: 'Công bố'
    },

]
export const data = [
    {
        id: 1,
        nameFolder: "Thu-muc-root1",
        images: [
            {
                id: 1,
                urlImage:
                    "https://images.unsplash.com/photo-1696385544566-032028025739?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1930&q=80",
                timeUpload: "123",
                extension: "jpg",
            },
            {
                id: 2,
                urlImage:
                    "https://images.unsplash.com/photo-1696610101250-f5933d2f616c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                timeUpload: "123",
                extension: "jpg",
            },
            {
                id: 3,
                urlImage:
                    "https://images.unsplash.com/photo-1682685797168-613fd0cae41d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwxMXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                timeUpload: "123",
                extension: "jpg",
            },
        ],
    },
    {
        id: 2,
        nameFolder: "Thu-muc-root2",
        images: [
            {
                id: 1,
                urlImage:
                    "https://plus.unsplash.com/premium_photo-1694884742403-9ea13e6cfd64?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                timeUpload: "123",
                extension: "jpg",
            },
        ],
    },
    {
        id: 3,
        nameFolder: "Thu-muc-root3",
        images: [],
    },
];

export const option_effectStatus = [
    {
        value: "0",
        label: 'Chọn trạng thái hiệu lực'
    },
    {
        value: "1",
        label: 'Còn hiệu lực'
    },
    {
        value: '2',
        label: "Hết hiệu lực"
    },
    {
        value: '3',
        label: 'Hết hiệu lực môt phần'
    },
    {
        value: '4',
        label: 'Chưa có hiệu lực'
    },
    {
        value: '5',
        label: 'Chưa xác định'
    },
    {
        value: '4',
        label: 'Ngưng hiệu lực toàn bộ'
    },
    {
        value: '5',
        label: 'Ngưng hiệu lực một phần'
    }

]