import { createAsyncThunk, createSlice, isAction, PayloadAction } from '@reduxjs/toolkit'
import { IPagination  } from '../../types/pagination';
import { IPost  } from '../../types/post';
import {  IThumbnail  } from '../../types/image';

import { List, Create, Delete, Update } from "../../services/post"
import { getFileInfos } from "../../services/media"

import { RootState } from '../../redux/store';

export interface PostState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  items?: IPost[];
  thumbnails?: IThumbnail[]; 
  pagination?: IPagination;
  error?: string;
}

const initialState: PostState = {
  status: 'idle',
  items:[],

}

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(list.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(list.fulfilled, (state, action) => {
        state.status = 'succeeded'
        console.log("loadPost: payload: ", action.payload)
        const { data, extra } = action.payload
        state.items = data
        state.pagination = extra 
      })
      .addCase(list.rejected, (state, action) => {
        state.status = 'failed'
        console.log("loadPost: payload rejected: ", action.payload)
        state.error = action.error.message + " with error: " + action.payload
      })
      
      .addCase(create.fulfilled, (state,action) => {
        state.status = 'succeeded'
        let item = action.payload
        console.log("create add: ", item)
        state.items?.unshift(item)
      })
      .addCase(del.fulfilled, (state,action) => {
        state.status = 'succeeded'
        let item = action.payload
        state.items = state.items?.filter((el) => el.id != item.id)
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'succeeded';
        let updatedItem = action.payload;
        let updatedItemId = updatedItem.id;
        state.items = state.items?.map((item: any) => {
            if (item.id === updatedItemId) {
                  return updatedItem
              } 
            else {
                  return item;
              }
        })
      })
      .addCase(fetchThumbnails.fulfilled, (state, action) => {
          state.status = 'succeeded';
          let items = action.payload;
          if (items && items.length> 0) {
            if (state.thumbnails) {
               //Update items in items if existed in tmp 
              let newThumbnails = state.thumbnails.map((el:any)=> {
                let foundIndex = items.findIndex((item:any) => el.id === item.id)
                if (foundIndex >= 0) {
                      return {
                        ...el,
                        ...items[foundIndex]
                      }   
                }
                return el 
              })
              // Get remains 
              let remains = items.filter((item:any)=> {
                let foundIndex = newThumbnails.findIndex((el:any) => el.id === item.id)
                if (foundIndex >= 0) {
                  return true 
                } 
                return true 
              })
              state.thumbnails = [...newThumbnails, ...remains] 
            } else {
              state.thumbnails = items 
            }
          }
          console.log("Finished update thumbnails: ", state.thumbnails)
      })
  }
})

export const list = createAsyncThunk('post/list', async (info: any, { rejectWithValue }) => {
  try {
    const response = await List(info)
    console.log('[Response], ', response)
    return response
  } catch (ex) {
    return rejectWithValue(ex)
  }
})

export const create = createAsyncThunk('post/create', async (info:any, {rejectWithValue}) => {
  try {
    const response = await Create(info)
    return response
  } catch (ex) {
    return rejectWithValue(ex)
  }
})

export const del = createAsyncThunk('post/del', async(info: any, {rejectWithValue}) => {
  try {
    const response  = await Delete(info)
    return response
  } catch (ex) {
    return rejectWithValue(ex)
  }
})

export const update = createAsyncThunk('post/update', async(info:any, {rejectWithValue}) => {
  try {
      const response = await Update(info)
      return response 
  } catch (ex) {
      return rejectWithValue(ex)
  }
})

export const fetchThumbnails = createAsyncThunk('post/thumbnail', async(ids: string[], {rejectWithValue}) => {
  try {
      const response = await getFileInfos(ids)
      return response 
  } catch (ex) {
      return rejectWithValue(ex)
  }
})
// export const { loadPost } = authSlice.actions

export default postSlice.reducer
