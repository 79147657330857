import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Divider, Select } from 'antd';
import { PlusOutlined, DropboxOutlined, RedoOutlined, SearchOutlined, CopyFilled } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import localeValues from "antd/locale/vi_VN";
import { option_lang } from "../../data/data";
import './keyword.css'
interface HeaderKeywords {
    onNew: (values: any) => void
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
    onExport: (values: any) => void
    onImport: (values: any) => void

}
const { Search } = Input

const HeaderKeywords: React.FC<HeaderKeywords> = (props) => {
    const { onNew, onSearch, onRefresh, onExport, onImport } = props

    const [form] = Form.useForm();

    return (
        <div >
            <Form
                form={form}
                name="control-hooks"
                style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            >
                <Space className="title">
                    <DropboxOutlined />
                    <span>Thẻ cho bài viết</span>
                </Space>
                <Space className="option-box" style={{ width: "80%" }}>
                    <Form.Item
                        noStyle
                        name='all'>
                        <Button type="primary" size="middle">Tiếng Việt</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='waiting'>
                        <Button type="default" size="middle">Tiếng Anh</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        name='searchComment'>
                        <Search onSearch={onSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>Thêm mới</Button>
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" icon={<RedoOutlined />} onClick={onRefresh} size="middle" />
                    </Form.Item>
                    <Form.Item
                        noStyle>
                        <Button type="primary" onClick={onExport} >Export</Button>
                    </Form.Item>
                    <Form.Item noStyle>
                        <input type="file" accept=".xlsx, .csv" onChange={onImport} />
                    </Form.Item>
                </Space>
            </Form>
        </div>


    )
}

export default HeaderKeywords
