import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { Space, Tabs, Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { update } from '../articleSlice'
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import type { TabsProps } from 'antd';
import { notification } from 'antd';
import { GetByID } from '../../../services/article'
import FormArticle from "./form";
import { useParams, useNavigate } from 'react-router-dom';
import { MediaProvider } from "../../../hooks/useMediaContext";

import { IArticle } from "../../../types/article"

import './index.css'


interface Props {
}


const ArticleUpdate: React.FC<Props> = () => {
    const article = useAppSelector(state => state.article)
    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const category = useAppSelector(state => state.category)
    const { t, i18n } = useTranslation()
    const keyword = useAppSelector(state => state.keyword)

    const dispatch = useAppDispatch()

    const [options, setOptions] = useState<any[]>([]);

    const [updateItem, setUpdateItem] = useState<IArticle | null>(null)

    const [formUpdate] = Form.useForm()

    const onChangeLang = (key: string) => {
        i18n.changeLanguage(key)
    };
    const { record } = useParams()

    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {
        console.log("article-update: ", record)
        let id = Number(record)
        if (id) {
            try {
                const item = await GetByID(id);
                if (item) {
                    setUpdateItem(item);
                }
            } catch (error) {
                console.error('[Erorr]', error)
            }
        }

    }

    useEffect(() => {
        if (updateItem) {
            let {
                id,
                title,
                showOnBanner,
                lang,
                slug,
                excerpt,
                content,
                commentStatus,
                status,
                publicDate,
                finishDate,
                thumbnail,
                source,
                sourceUrl,
                thumbnailUrl,
            } = updateItem;

            formUpdate.setFieldsValue({
                id,
                title,
                lang,
                showOnBanner,
                slug,
                excerpt,
                content,
                commentStatus,
                status,
                publicDate: dayjs(Number(publicDate) * 1000),
                finishDate: dayjs(Number(finishDate) * 1000),
                thumbnail,
                source,
                sourceUrl,
                thumbnailUrl
            });

        };
    }, [updateItem]);

    const handleSave = () => {
        const btn = (
            <Space>
                <Button type="primary" onClick={() => navigate("/article")}>
                    Chuyển về trang danh sách các bài báo
                </Button>
            </Space>
        )
        if (updateItem) {
            formUpdate.validateFields()
                .then((values) => {
                    const updatedRecord = { ...updateItem, ...values };
                    console.log('update updatedRecord', updatedRecord)

                    let categories: any[] | undefined = []
                    if (updatedRecord["category"]) {
                        categories = category?.items?.filter((item) => updatedRecord["category"].includes("" + item.id))
                    }
                    let keywords: any[] | undefined = []
                    if (updatedRecord["keyword"]) {
                        keywords = keyword?.items?.filter((item) => updatedRecord["keyword"].includes("" + item.id))
                    }
                    console.log('update categories', categories)
                    console.log('update keywords', keywords)

                    const updateValues = {
                        id: updatedRecord.id,
                        slug: updatedRecord.slug,
                        title: updatedRecord.title,
                        excerpt: updatedRecord.excerpt,
                        content: updatedRecord.content,
                        status: updatedRecord.status,
                        showOnBanner: updatedRecord.showOnBanner,
                        commentStatus: updatedRecord.commentStatus,
                        isPublished: true,
                        lang: updatedRecord.lang,
                        publicDate: updatedRecord.publicDate.unix(),
                        finishDate: updatedRecord.finishDate.unix(),
                        createdTime: updatedRecord.createdTime,
                        updatedTime: updatedRecord.publicDate.unix(),
                        thumbnail: updatedRecord.thumbnail,
                        thumbnailUrl: updatedRecord.thumbnailUrl,
                        source: updatedRecord.source,
                        sourceUrl: updatedRecord.sourceUrl,
                        categories: categories,
                        keywords: keywords,
                    }
                    console.log('update values:', updateValues)
                    dispatch(update(updateValues)).unwrap().then(() => {
                        api.open({
                            message: `Cập nhật bài viết ${updateValues.title} thành công`,
                            description: 'Thành công',
                            duration: 0,
                            type: 'success',
                            btn
                        });
                    }).catch(error => {
                        api.open({
                            message: 'Thất bại',
                            description: `Cập nhật thất bại: ${error}`,
                            duration: 0,
                            type: 'error'
                        })
                    })
                })
        }
    };

    const items: TabsProps['items'] = [
        {
            key: 'vi',
            label: 'Tiếng Việt',
            children: <FormArticle
                lang="vi"
                handleUpdate={handleSave}
                options={options}
                form={formUpdate}
                thumbnails={article?.thumbnails}
            />,
        },
        // {
        //     key: 'en',
        //     label: 'English',
        //     children: <FormArticle
        //         lang="en"
        //         handleUpdate={handleSave}
        //         options={options}
        //         form={formUpdateEn}
        //         opt_keyword={keyWordOptionsEn} />,
        // }
    ];

    return (
        <MediaProvider >
            <div className="wrapper">
                <div className="titleBox">
                    <div className="title">
                        <Space>
                            <>
                                <EditOutlined />
                                <span>Chỉnh sửa bài viết </span>
                            </>
                        </Space>
                    </div>
                </div>
                {contextHolder}

                <Tabs defaultActiveKey="1" items={items} onChange={onChangeLang} />
            </div>
        </MediaProvider>
    )
}

export default ArticleUpdate;