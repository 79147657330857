import React, { useEffect, useState } from "react";
import { notification } from 'antd';
import HeaderFeedback from "./header";
import { CopyFilled } from '@ant-design/icons';
import { Modal, Button, Divider, Form } from 'antd';
import './faq.css'
import { list, create, del, update, importFaq } from "./faqSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import TableList from "./table";
import dayjs from 'dayjs'
import { IFAQ } from "../../types/faq"

import FormCreate from "./formCreate";
import FormUpdate from "./formUpdate";
import * as XLSX from "xlsx"


interface LayoutFAQs {

}


const FAQS: React.FC<LayoutFAQs> = () => {
    const dispatch = useAppDispatch()
    const faq = useAppSelector(state => state.faq)

    const [api, contextHolder] = notification.useNotification()
    const [data, setData] = useState<any | any[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<IFAQ | null>(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [filter, setFilter] = useState<any>({});


    const [loadingData, setLoadingData] = useState(false);
    const nowExport = dayjs().format("DD/MM/YYYY")

    const [form] = Form.useForm()
    const [formUpdate] = Form.useForm()

    const onFetchData = (page: number, pageSize: number) => {
        fetchData(page, pageSize, filter)
    }

    const fetchData = (page: number, pageSize: number, filter: any) => {
        let pagination = {
            page: page,
            size: pageSize,
            word: filter
        }
        setLoadingData(true);
        dispatch(list(pagination)).unwrap().then(() => {
            setLoadingData(false);
        }).catch((error) => {
            setLoadingData(false)
            api.open({
                message: 'Thất bại',
                description: `Lấy dữ liệu thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
    };

    useEffect(() => {
        fetchData(page, pageSize, filter)
    }, [filter])

    useEffect(() => {
        const feedbackItems = faq?.items;
        if (feedbackItems) {
            if (faq?.pagination?.total) {
                setTotal(faq?.pagination?.total)
                setPage(faq?.pagination?.page)
                setPageSize(faq?.pagination?.size)

            } else {
                setTotal(feedbackItems.length)
            }
            setData(feedbackItems)
        }
    }, [faq?.items])

    useEffect(() => {
        if (selectedItem) {
            let { question, answer, lang, approvedTime } = selectedItem;
            console.log("Update form: approvedTime ", approvedTime)
            let approve_time = dayjs()
            if (approvedTime > 0) {
                approve_time = dayjs(approvedTime * 1000)
            }
            formUpdate.setFieldsValue({
                question: question,
                answer: answer,
                lang: lang,
                approve_time: approve_time
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    const closeModal = () => {
        setIsModalOpen(false)
        form.resetFields();
    }

    const handleSearch = async (values: any) => {
        console.log("handleSearch: word ", values)
        setFilter(values)
    };

    const onRefresh = (values: any) => {
        console.log("Refresh data")
        setFilter(values)
    }

    const onNew = (value: any) => {
        setIsModalOpen(true)
    }

    const initialValues = (record: IFAQ) => {
        const { approvedTime, createdTime, updatedTime } = record

        setSelectedItem(record);

        setIsModalUpdateOpen(true);
    };

    const closeModalUpdate = () => {
        setIsModalUpdateOpen(false)
        formUpdate.resetFields()
    }


    const handleSubmitFeedbacks = async (values: any) => {
        const formValues = {
            answer: values.answer,
            approvedTime: values.approve_time.unix(),
            cancel_note: values.cancel_note,
            cancelTime: values.cancel_time,
            created_by: values.created_by,
            createdTime: values.approve_time.unix(),
            email: values.email,
            id: values.id,
            lang: values.lang,
            name: values.name,
            question: values.question,
            site_name: values.site_name,
            type: values.type,
            updated_by: values.updated_by,
            updatedTime: values.approve_time.unix()
        };
        setLoadingData(true);
        dispatch(create(formValues)).unwrap().then(() => {
            setLoadingData(false)
            api.open({
                message: 'Thành công',
                description: 'Tạo thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Tạo thất bại: ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoadingData(false)
        })
        closeModal()
    };

    const handleDeleteFeedBack = async (id: number) => {
        dispatch(del({
            id: id,
        })).unwrap().then(() => {
            api.open({
                message: 'Thành công',
                description: 'Xóa thành công',
                duration: 0,
                type: 'success'
            })
        }).catch((error) => {
            api.open({
                message: 'Thất bại',
                description: `Xóa thất bại ${error}`,
                duration: 0,
                type: 'error'
            })
        })
    };


    const handleSave = () => {
        if (selectedItem) {
            formUpdate.validateFields()
                .then((values) => {
                    const updatedRecord = {
                        ...selectedItem,
                        ...values,
                        approvedTime: values.approve_time.unix(),
                    };
                    dispatch(update(updatedRecord)).unwrap().then(() => {
                        api.open({
                            message: 'Thành công',
                            description: 'Cập nhật thành công',
                            duration: 0,
                            type: 'success'
                        })
                        closeModalUpdate()
                        fetchData(page, pageSize, filter);
                    }).catch((error) => {
                        api.open({
                            message: 'Thất bại',
                            description: `Cập nhật thất bại: ${error}`,
                            duration: 0,
                            type: 'error'
                        })
                        closeModalUpdate()
                    });
                })
        };
    }
    const getMaxWidth = (property: keyof IFAQ, rows: IFAQ[], defaultValue = 10): number =>
        rows.reduce((maxWidth, row) => Math.max(maxWidth, String(row[property]).length), defaultValue);

    const header: (keyof IFAQ)[] = [
        "id", "answer",
        "approvedTime",
        "cancel_note",
        "updatedTime",
        "cancelTime",
        "created_by",
        "lang",
        "type",
        "createdTime",
        "email",
        "question",
        "site_name",
        "type"];

    const exportData = () => {
        const rows: IFAQ[] = data.map((row: { [x: string]: any; }) =>
            header.reduce((acc: any, key) => {
                acc[key] = row[key];
                return acc;
            }, {} as IFAQ)
        );

        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');

        XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });

        if (!ws["!cols"]) {
            ws["!cols"] = [];
        }

        header.forEach((property, index) => {
            if (ws["!cols"]) {
                ws["!cols"][index] = { wch: getMaxWidth(property, rows) };
            }
        });

        XLSX.writeFile(wb, `Doc ${nowExport}.xlsx`);
    };
    const importData = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const dataImport = new Uint8Array(e.target.result);
            const wb = XLSX.read(dataImport, { type: 'array' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const jsonData: any[][] = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const convertExcelDataToDataType = (excelData: any[][]): any[] => {

                const arrayOfObjects = excelData.slice(1).map(array => {
                    return array.reduce((obj: any, value, index) => {
                        obj[header[index]] = value;
                        return obj;
                    }, {});
                });
                return arrayOfObjects;
            };
            const arrayOfObject = convertExcelDataToDataType(jsonData);
            dispatch(importFaq(arrayOfObject))
        };
        reader.readAsArrayBuffer(file);
    };

    const onImport = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            importData(file)
        }
    }

    return (

        <div className="wrapper">
            {contextHolder}
            < div className="titleBox">
                <HeaderFeedback
                    onNew={onNew}
                    onRefresh={onRefresh}
                    onSearch={handleSearch}
                    onExport={exportData}
                    onImport={onImport} />
            </div>
            <div className="main-content">
                <div className="title">
                    <span>Danh sách {total} faq</span>
                </div>
                <div className="data">
                    <TableList
                        page={page}
                        pageSize={pageSize}
                        total={total}
                        fetchData={onFetchData}
                        data={data}
                        handleDeleteFeedback={handleDeleteFeedBack}
                        loadingData={loadingData}
                        intialValues={initialValues}
                    />
                </div>
            </div>
            <Modal title="Thêm mới"
                open={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button onClick={closeModal}>Đóng</Button>,
                    <Button key="submit" htmlType="submit" type="primary" icon={<CopyFilled />} onClick={form.submit}>Luu và tiếp tục</Button>
                ]}
                mask={false}
                width={1000}
            >
                <Divider />
                <FormCreate
                    form={form}
                    handleSubmitDoc={handleSubmitFeedbacks} />
            </Modal>
            <Modal
                title="Chỉnh sửa"
                open={isModalUpdateOpen}
                onCancel={() => { setIsModalUpdateOpen(false) }}
                width={1000}
                footer={[
                    <Button key="close" onClick={() => { setIsModalUpdateOpen(false) }}>
                        Đóng
                    </Button>,
                    <Button key="submit"
                        htmlType="submit" type="primary" icon={<CopyFilled />}
                        onClick={formUpdate.submit}>Lưu lại</Button>

                ]}
            >
                <FormUpdate form={formUpdate} handleUpdate={handleSave} />
            </Modal>
        </div>


    )
}

export default FAQS
