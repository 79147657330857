import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Divider, Select } from 'antd';
import { PlusOutlined, DropboxOutlined, RedoOutlined, SearchOutlined, CopyFilled } from '@ant-design/icons';
import { option_filter_category } from "../../data/data";
import './category.css'

interface HeaderComment {
    onNew: (values: any) => void
    onSearch: (values: any) => void
    onRefresh: (values: any) => void
    onExport: (values: any) => void

}
const { Search } = Input

const HeaderCategory: React.FC<HeaderComment> = (props) => {
    const { onNew, onSearch, onRefresh, onExport } = props

    return (
        <div>
            <Space
                style={{ width: ' 100 % ', display: 'flex', justifyContent: 'space-between' }}
            >
                <Space className="title">
                    <DropboxOutlined />
                    <span>Chuyên mục cho bài viết</span>
                </Space>
                <Space className="option-box">
                    <div >
                        <Search
                            onSearch={onSearch}
                            size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                    </div>
                    <div >
                        <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>Thêm mới</Button>
                    </div>
                    <div>
                        <Button type="primary" icon={<RedoOutlined />}
                            onClick={onRefresh}
                            size="middle" />
                    </div>
                    {/* <div >
                        <Button type="primary" onClick={onExport}>Export</Button>
                    </div> */}
                </Space>
            </Space >
        </div >
    )
}

export default HeaderCategory
