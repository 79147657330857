import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import HeaderNews from "./headerNews";
import SettingNewsCard from './formTabNews'
import './news.css'
const SettingNews: React.FC = () => {

    return (
        <>
            <div className="news_wrapper_setting">
                <div className="info_news_setting" >
                    <HeaderNews />
                    <br></br>
                    <Card title="Tab thông tin" headStyle={{ textAlign: "left" }}>
                        <Form>
                            <p style={{ textAlign: 'left', fontWeight: 'lighter', fontSize: 'small' }}>(Lưu ý: Cần nhấn nút "Lưu lại" để lưu các cài đặt bạn đã thêm)</p>
                            <SettingNewsCard />
                        </Form>
                    </Card>

                </div>
            </div>
        </>
    );
};

export default SettingNews;