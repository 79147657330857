import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Divider, Select } from 'antd';
import { TreeSelect } from 'antd';
import { slugify } from "../../util/helper";
import './comment.css'

const { Search, TextArea } = Input

interface FormComment {
    form?: any,
    // option_category: any[],
    handleSubmitComment?: (values: any) => void
    optionTitle?: any[]
}

const FormComment: React.FC<FormComment> = (props) => {
    const { form, handleSubmitComment, optionTitle } = props
    const onFinish = (values: any) => {
        console.log("Form.onFinish: ", values)
        if (handleSubmitComment) {
            handleSubmitComment(values)
        }
    }

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            title="Thêm mới"
        >
            <Form.Item
                name='userName'
                label="Họ và tên"
                rules={[{ required: true, message: 'Tên người bình luận không được để trống.' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name='userPhone'
                label="Số điện thoại"
                rules={[{ required: false }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name='userEmail'
                label="Email">
                <Input />
            </Form.Item>
            <Form.Item
                label="Bài viết"
                name='targetId'>
                <Select options={optionTitle} />
            </Form.Item>
            <Form.Item
                name='content'
                label="Nội dung bình luận"
                rules={[{ required: true, message: 'Nội dung bình luận không được để trống.' }]}>
                <TextArea />
            </Form.Item>
        </Form>
    )
}

export default FormComment 