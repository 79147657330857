import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Settings_layout from "../../siderSetting";
import HeaderContact from "./headerContact";
import TableContact from './table'
import './contact.css'
const SettingContact: React.FC = () => {

    return (
        <>
            <div className="nav_wrapper_setting">
                <div className="info_nav_setting" >
                    <HeaderContact />
                    <br></br>
                    <TableContact />
                </div>
            </div>
        </>
    );
};

export default SettingContact;