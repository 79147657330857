import React from "react";
import { Input, Form, Typography, Space } from 'antd';
import './comment.css'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import HeaderAdminReply from "./headerAdminReply";
import TableRepComment from "./tableReply";

interface FormDeny {
    form: any,

}

const { Title } = Typography

const FormDeny: React.FC<FormDeny> = (props) => {
    const { form } = props

    const { TextArea } = Input
    return (
        <div
        // style={{ display: 'flex', flexDirection: 'row' }}
        >
            <Form form={form} style={{ padding: '10px' }}>
                <ExclamationCircleOutlined style={{ color: '#facea8', fontSize: '50px', marginLeft: '190px' }}></ExclamationCircleOutlined>
                <Typography style={{ marginLeft: '100px' }}>Bạn chắc chắn muốn từ chối bình luận?</Typography>
                <Form.Item style={{ padding: '10px' }}>
                    <Input placeholder='Vui lòng nhập lý do từ chối bình luận' />
                </Form.Item>

            </Form>
        </div>
    )

}

export default FormDeny 