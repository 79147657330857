import { Card, Divider, Form, Select, Button, Row, Col, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import './home.css'
import { PlusOutlined, FileOutlined } from "@ant-design/icons";


interface Props {

}


const SettingBannerCard: React.FC = () => {
    const [form] = Form.useForm();

    return (
        <Card
            title="Banner trang chủ"
            className="content_home"
            actions={[<Button type="primary" icon={<FileOutlined />}>Lưu lại</Button>]}
            headStyle={{ textAlign: "left" }}
        >
            <p style={{ textAlign: 'left', fontWeight: 'lighter', fontSize: 'small' }}>(Lưu ý: Cần nhấn nút "Lưu lại" để lưu các cài đặt bạn đã thêm)</p>
            <Form
                style={{ width: '100%' }}
                form={form}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Danh sách bài viết (Tiếng Việt)"
                            name="post"
                        >
                            <Select placeholder="Chọn bài viết" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Danh sách bài viết (Tiếng Anh)"
                            name="postEn"
                        >
                            <Select placeholder="Chọn bài viết" />

                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button icon={<PlusOutlined />} style={{ marginTop: '30px' }}>Chọn</Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default SettingBannerCard;