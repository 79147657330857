import { Layout, Menu, Button, Divider, Form, Card, Space } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Settings_layout from "../../siderSetting";
import HeaderHome from "./headerHome";
import './home.css'
import SettingBannerCard from './formSettingBanner'
import SettingHomeBoxCard from './formHomeBoxNews'
import SettingSupportCard from './formSupportingBox'
import SettingConsultationCard from './formConsultationBox'
import SettingLinkCard from "./formLinkCard";

const SettingHome: React.FC = () => {

    return (
        <>
            <div className="home_wrapper_setting">
                <div className="info_home_setting" >
                    <HeaderHome />
                    <SettingBannerCard />
                    <SettingHomeBoxCard />
                    <SettingSupportCard />
                    <SettingConsultationCard />
                    <SettingLinkCard />
                </div>
            </div>
        </>
    );
};

export default SettingHome;