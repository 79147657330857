import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Divider, Select } from 'antd';
import { TreeSelect } from 'antd';
import { slugify } from "../../util/helper";
import './category.css'

const { Search, TextArea } = Input

interface HeaderComment {
    form: any,
    option_category: any[],
    handleSubmitCategory: (values: any) => void
}

const FormCategory: React.FC<HeaderComment> = (props) => {
    const { form, option_category, handleSubmitCategory } = props
    const onFinish = (values: any) => {
        if (handleSubmitCategory) {
            handleSubmitCategory(values)
        }
    }
    const onChangeName = (e: any) => {
        console.log("Name value: ", e.target.value)
        let slug = slugify(e.target.value)
        form.setFieldValue("slug", slug)
    }
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='parentId'
                label="Chuyên mục cha"
                help="Chuyên mục khác với thẻ, bạn có thể sử dụng nhiều cấp chuyên mục. Ví dụ: Trong chuyên mục nhạc, bạn có chuyên mục con là nhạc Pop, nhạc Jazz. Việc này hoàn toàn là tùy theo ý bạn. Hỗ trợ tối đa 3 cấp cha-con"
            >
                <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    treeData={option_category}
                />
            </Form.Item>
            <Form.Item
                name='name'
                label="Tên chuyên mục "
                help="Tên riêng sẽ hiển thị trên website                                "
                rules={[{ required: true, message: 'Tên chuyên mục không được để trống.' }]}
            >
                <Input onChange={onChangeName} />
            </Form.Item>
            <Form.Item
                name='nameEn'
                label="Tên chuyên mục tiếng Anh "
                help="Tên riêng sẽ hiển thị trên website"
                rules={[{ required: true, message: 'Tên chuyên mục tiếng Anh không được để trống.' }]}

            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Đường dẫn"
                name='slug'
                help="Chuỗi cho đường dẫn tĩnh là phiên bản của tên hợp chuẩn với Đường dẫn (URL). Chuỗi này bao gồm chữ cái thường, số và dấu gạch ngang (-)."
                rules={[{ required: true, message: 'Đường dẫn không được để trống.' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name='position'
                label="Thứ tự"
            >
                <Input />
            </Form.Item>
            {/* <Form.Item
                name='description'
                label="Mô tả"
                help="Mô tả không được sử dụng trên website"
            >
                <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item> */}
        </Form>
    )
}

export default FormCategory 