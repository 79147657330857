import React from "react";
import { Input, Form, InputNumber, Checkbox } from 'antd';
import './video.css'


interface HeaderVideo {
    form: any,
    handleSubmitVideo: (values: any) => void
}

const FormVideo: React.FC<HeaderVideo> = (props) => {
    const { form, handleSubmitVideo } = props
    const onFinish = (values: any) => {
        console.log("Form.onFinish: ", values)
        if (handleSubmitVideo) {
            handleSubmitVideo(values)
        }
    }

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name='title'
                label="Tiêu đề "
                rules={[{ required: true, message: 'Tiêu đề không được để trống.' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name='slug'
                label="Đường dẫn Youtube "
                rules={[{ required: true, message: 'Đường dẫn Youtube không được để trống.' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name='excerpt'
                label="Mô tả ngắn">
                <Input />
            </Form.Item>
            <Form.Item
                label="Thứ tự hiển thị"
                name='index'>
                <InputNumber />
            </Form.Item>
            <Form.Item
                name='blocked'>
                <Checkbox checked={true}>Tạm khoá</Checkbox>
            </Form.Item>
        </Form>
    )
}

export default FormVideo 