import type { DataNode, DirectoryTreeProps } from 'antd/es/tree';
import dayjs from "dayjs";

export const convertData = (data: any[]) => {
    let dnodes: DataNode[] = []
    for (var item of data) {
        let node: DataNode = {
            title: item.name,
            key: "" + item.id
        }
        if (item.children && item.children.length > 0) {
            node.children = convertData(item.children)
        }
        dnodes.push(node)
    }
    return dnodes
}

export const formatTime = (utcTimeString: string) => {
    return dayjs(utcTimeString).format("HH[h]:mm['] DD-MM-YYYY")
}