import React from 'react';
import { Table, Button, Space } from 'antd';
import dayjs from 'dayjs'
import '../table.css'
import type { ColumnsType } from 'antd/es/table';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IPost } from '../../types/post'
dayjs.extend(relativeTime);

interface DataType {
    id: number;
    type: string;
    targetId: number;
    parentId: number;
    content: string;
    cancelTime: dayjs.Dayjs;
    approvedTime: dayjs.Dayjs;
    cancelNote: string;
    userName: string;
    userId: number;
    userEmail: string;
    userPhone: string;
    adminReply: string;
    deleted: boolean;
    createdBy: number;
    updatedBy: number;
    createdTime: dayjs.Dayjs;
    updatedTime: dayjs.Dayjs;
}

interface Props {
    data?: DataType[];
    // loadingData: boolean;
    // handleDeleteComment: (id: number) => void;
    // onNew: (id: number) => void;
    // onReply: (record: DataType) => void
    // initialValues: (record: DataType) => void
}

const columns: ColumnsType<DataType> = [
    // {
    //     title: '#',
    //     dataIndex: 'id',
    //     width: '5%',
    //     key: 'id',
    // },
    {
        title: "Bình luận",
        dataIndex: "content",
        width: '40%',
        key: 'title',
        render: (_: any, record: DataType) => (
            <Space direction='vertical' size='small'>
                <p>{record.content}</p>
                <p>Bài viết:{record.targetId}</p>
                <p>Tác giả: {record.userName}</p>
                <p>Tổng số trả lời: {record.adminReply}</p>
            </Space>
        )
    },
    {
        title: "Thời gian",
        dataIndex: "createdTime",
        key: "createdTime",
        width: '40%',
        render: (_: any, record: DataType) => (
            <>
                <p>
                    <span style={{ fontWeight: 'lighter', fontSize: '12px' }}>
                        Ngày lên website:
                    </span>
                    {dayjs(Number(record.createdTime) * 1000).format('HH:mm DD/MM/YYYY')}
                </p>
            </>
        )
    },
    {
        title: 'Hành động',
        width: '20%',
        key: 'action',
        dataIndex: 'action',
        render: (_: any, record: DataType) => (
            <Space size="middle" wrap align='center'>
                {/* <Button onClick={() => onReply(record)}>Trả lời </Button> */}
                <Button >Từ chối </Button>
                <Button >Duyệt</Button>
                {/* <Popconfirm
                    title="Xoá bản ghi"
                    description="Bạn có chắc chắn muốn xoá bản ghi này không?"
                    onConfirm={() => confirm(record.id)}
                    okText="Có"
                    cancelText="Không"
                >
                    <Button danger icon={<DeleteFilled />}></Button>
                </Popconfirm> */}
            </Space>
        )
    }
];
const TableRepComment: React.FC<Props> = ({
    data
    //  data, loadingData, handleDeleteComment, onNew, initialValues, onReply
}) => {

    // const confirm = (id: number) => {
    //     if (handleDeleteComment) {
    //         handleDeleteComment(id);
    //     }
    // }

    return (
        <div>
            {/* {loadingData ? (
                <Spin size="large">
                    <Alert
                        message="Thông báo"
                        description="Hệ thống đang tải dữ liệu xin vui lòng chờ ..."
                        type="info"
                    />
                </Spin>
            ) : (
                <> // render: (_: any, record: DataType) => (
            //     <Space size="middle" wrap align='center'>
            //         <Button onClick={() => onReply(record)}>Trả lời </Button>
            //         <Button onClick={() => onNew(record.id)}>Từ chối </Button>
            //         <Button onClick={() => initialValues(record)}>Duyệt</Button>
            //         <Popconfirm
            //             title="Xoá bản ghi"
            //             description="Bạn có chắc chắn muốn xoá bản ghi này không?"
            //             onConfirm={() => confirm(record.id)}
            //             okText="Có"
            //             cancelText="Không"
            //         >
            //             <Button danger icon={<DeleteFilled />}></Button>
            //         </Popconfirm>
            //     </Space>
            // )
                    <Divider />
                    {/* <Button onClick={handleExport}>export </Button> */}
            <Table
                dataSource={data}
                columns={columns}
                // (confirm, initialValues, onNew, onReply)

                rowKey="id"
                rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
            />

        </div >
    );
};

export default TableRepComment;