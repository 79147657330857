import React, { useEffect } from 'react';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import "./i18n"
import './App.css';


import Layout from './layout'
import SignIn from './page/auth/signin'
import ResetPassword from './page/auth/reset-password'



import Category from './page/category';
import Comment from './page/comment';

import Tags from './page/keyword';
import Document from './page/document';
import Image from './page/image';
import Video from './page/video';
import Media from './page/media';

import PostList from './page/post/list';
import PostCreate from './page/post/create';
import PostUpdate from './page/post/update'

import ArticleList from './page/article/list';
import ArticleCreate from './page/article/create';
import ArticleUpdate from './page/article/update'

import FAQ from './page/faq';

import CustomRouter from "./router";
import history from './history'

import SettingProfile from './page/setting/settingPage/profileSetting'
import SettingDevice from './page/setting/settingPage/infoDevice'
import SettingGeneral from './page/setting/settingPage/generalSetting'
import SettingHome from './page/setting/settingPage/homeSetting'
import SettingAbout from './page/setting/settingPage/aboutSetting'
import SettingNav from './page/setting/settingPage/navSetting';
import SettingPartner from './page/setting/settingPage/partnerSetting'
import SettingContact from './page/setting/settingPage/contactSetting';
import SettingNews from './page/setting/settingPage/newsSetting'
import SettingAds from './page/setting/settingPage//adsSetting'

function App() {
  return (
    <>
      <Provider store={store}>
        <CustomRouter history={history} >
          <div id="app" className="App">
            <Routes>
              <Route path='/login' element={<SignIn />} />
              <Route path='/reset' element={<ResetPassword />} />

              <Route element={<Layout />}>
                <Route path='/' element={<PostList />} />

                <Route path='/post' element={<PostList />} />
                <Route path='/post/update/:record' element={<PostUpdate />} />
                <Route path='/post/create' element={<PostCreate />} />

                <Route path='/post/comment' element={<Comment />} />
                <Route path='/post/category' element={<Category />} />
                <Route path='/post/tags' element={<Tags />} />

                <Route path='/article' element={<ArticleList />} />
                <Route path='/article/update/:record' element={<ArticleUpdate />} />
                <Route path='/article/create' element={<ArticleCreate />} />

                <Route path='/document' element={<Document />} />
                <Route path='/document/image' element={<Image />} />
                <Route path='/document/video' element={<Video />} />
                <Route path='/document/media' element={<Media />} />

                <Route path='/question' element={<FAQ />} />

                <Route path='/setting/profile' element={<SettingProfile />} />
                <Route path='/setting/device-token' element={<SettingDevice />} />
                <Route path='/setting/general' element={<SettingGeneral />} />
                <Route path='/setting/homepage' element={<SettingHome />} />
                <Route path='/setting/intro' element={<SettingAbout />} />
                <Route path='/setting/news' element={<SettingNews />} />
                <Route path='/setting/advert' element={<SettingAds />} />
                <Route path='/setting/menu' element={<SettingNav />} />
                <Route path='/setting/partner' element={<SettingPartner />} />
                <Route path='/setting/contact' element={<SettingContact />} />
                <Route path='/setting/staff' />
                <Route path='/setting/roles' />
                <Route path='/setting/action-define' />
              </Route>
            </Routes>
          </div>
        </CustomRouter>
      </Provider >
    </>
  )
}

export default App;
