import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IKeyword } from "../../types/keyword";
import {List, Create, Delete, Update} from '../../services/keyword'
import { RootState } from '../../redux/store';
import { IPagination } from "../../types/pagination";


export interface keywordState {
    status : 'idle' | 'loading' | 'succeeded' | 'failed';
    items ?: IKeyword[];
    pagination?: IPagination;
    error?: string ;
}   

const initialState: keywordState = {
    status: 'idle',
    items: []
}

export const keywordSlice = createSlice ({
    name: 'keyword',
    initialState,
    reducers: {
        importKeyword : (state, action) => {
            state.items = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(list.pending, (state,action) => {
                state.status = 'loading'
            })
            .addCase(list.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const { data, extra } =  action.payload
                state.items =data 
                state.pagination = extra 
            })
            .addCase(list.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message + " with error: " + action.payload
            })
            .addCase(create.fulfilled, (state, action) => {
                state.status = 'succeeded'
                let item = action.payload
                state.items?.unshift(item)
              })
            .addCase(del.fulfilled, (state,action) => {
                state.status = 'succeeded'
                let item = action.payload
                state.items = state.items?.filter((el)=> el.id != item.id)
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                let updatedItem = action.payload;
                let updatedItemId = updatedItem.id;
                state.items = state.items?.map((item: any) => {
                    if (item.id === updatedItemId) {
                        return updatedItem
                    } 
                    else {
                        return item;
                    }
                });
            });
    }
})

export const list = createAsyncThunk('keyword/list', async (info: any, { rejectWithValue }) => {
    try {
      const response = await List(info)
      return response
    } catch (ex) {
      return rejectWithValue(ex)
    }
  })

export const create  = createAsyncThunk('keyword/create', async (info:any, {rejectWithValue}) => {
    try {
        const response = await Create(info)
        return response
    } catch (ex) {
        return rejectWithValue(ex)
    }
})

export const del = createAsyncThunk('keyword/delete', async (info:any, {rejectWithValue}) => {
    try {
        const response = await Delete (info)
        return response
    } catch (ex) {
        return rejectWithValue(ex)
    }
})
export const update = createAsyncThunk('video/update', async(info:any, {rejectWithValue}) => {
    try {
        const response = await Update(info)
        return response 
    } catch (ex) {
        return rejectWithValue(ex)
    }
})
export const { importKeyword } = keywordSlice.actions
export const selectKeyword = (state: RootState) => state.keyword

export default keywordSlice.reducer