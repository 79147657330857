import { InfoCircleOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Typography } from 'antd';
import { useState } from 'react';

const DetailsInfomation = (props: any) => {
    const [ellipsis] = useState(true);
    const { documentType, image, onSubmit } = props;
    const onClick = () => {
        // console.log("DetailsInfomation.onClick: click")
        if (onSubmit) {
            onSubmit()
        }
    }
    return (
        <Col className="gutter-row details-info" span={5}>
            <h2 className="details__header">
                <span style={{fontSize: "1.125em"}}><InfoCircleOutlined className="icon-info" /></span>
                &nbsp;
                Chi tiết đính kèm
            </h2>
            <div className="details__body">
                <div className="info" style={{ display: 'block' }}>
                    {(documentType && documentType === "FILE") ? <img src='pdf.png' alt='PDF' />
                    : <>
                        {image?.url === undefined ?
                            <img src='https://thenounproject.com/api/private/icons/741653/edit/?backgroundShape=SQUARE&backgroundShapeColor=%23000000&backgroundShapeOpacity=0&exportSize=752&flipX=false&flipY=false&foregroundColor=%23000000&foregroundOpacity=1&imageFormat=png&rotation=0'></img> :
                            <img src={image?.url} />
                        }
                    </>}

                    
                    <div>
                        <Typography.Text
                            style={ellipsis ? { width: 'auto' } : undefined}
                            ellipsis={ellipsis ? { tooltip: `${image?.url}` } : false}
                            className='info__name'
                        >
                            {image?.name || "Name of Image1"}
                        </Typography.Text>
                        {/* <p className="info__time">{image?.timeUpload || "Time upload"}</p>
                        <p className="info__format">{image?.extension || "Format image"}</p> */}
                        {/* <ButtonDeleteImage></ButtonDeleteImage> */}
                    </div>
                </div>
                <Divider></Divider>
                <Form layout="vertical">
                    {/* <Form.Item name={"chu-thich"} label="Chú thích">
                        <Input.TextArea></Input.TextArea>
                    </Form.Item> */}
                    <Form.Item name={"duong-dan"} label="Đường dẫn">
                        <Typography.Text
                            style={ellipsis ? { width: "100%" } : undefined}
                            ellipsis={ellipsis ? { tooltip: `${image?.url}` } : false}
                            copyable
                        >
                            {image?.url || "URL of Image"}
                        </Typography.Text>
                    </Form.Item>
                    <Button type="primary" style={{ "float": "right" }} onClick={onClick}>
                        Cập nhật
                    </Button>
                </Form>
            </div>
        </Col>
    );


};

export default DetailsInfomation;