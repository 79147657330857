import { Button, Form, Input, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { resetPasswordRequest } from "../../services/auth";
import { useTranslation } from 'react-i18next';
import { fetchCaptchar, signIn } from "./authSlice"
import { useAppDispatch, useAppSelector } from "../../redux/store";

import "./style.css"

type accountData = {
    mst: string
    email: string
}


const ChangePassword = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    let [form] = Form.useForm();

    const { t } = useTranslation('translation', { keyPrefix: 'pages.change-password' });
    const auth = useAppSelector(state => state.auth)
    const { isLoading, captchaId, captchaData } = auth

    function toRoot() {
        navigate("/")
    }

    const [isPasswordChanged, setIsPasswordChanged] = useState(false);

    const onFinish = (values: any) => {
        // change password
        console.log(values);

        const { captchaId, captcha, mst, representativeId } = values


        let captchaInfo = {
            captcha_id: captchaId,
            captcha_data: captcha
        }
        let resetRequest = {
            mst: mst,
            representativeId: representativeId,
            captcha: captchaInfo
        }
        resetPasswordRequest(resetRequest).then((response: any) => {
            console.log("data:", response)
            // setIsPasswordChanged(true)
        })
            .catch(error => {
                // throw (error)
                message.error(error.toString())
            });

    };
    const onFinishFailed = (errorInfo: any) => { };

    const handleClose = () => {
        toRoot()
    };

    const fetchNewCaptchar = () => {
        var date = new Date;
        var milliSeconds = date.getMilliseconds();
        dispatch(fetchCaptchar(milliSeconds))
    }

    const CaptcharImage = (props: any) => {
        const { captchaData } = props

        if (captchaData && captchaData != "") {
            return <img src={captchaData} />
        }
        return <div></div>
    }
    const CaptcharButton = (props: any) => {
        const { isLoading } = props
        if (isLoading) {
            return <Spin />
        }
        return <Button type="text" className="vnp-min-width-0" onClick={fetchNewCaptchar}><SyncOutlined /></Button>
    }
    useEffect(() => {
        if (captchaId) {
            form.setFieldValue("captchaId", captchaId)
        }
    }, [captchaId]);

    return (
        <div className="signin-wrapper">
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"

                className={"sign-in-form with-noti " + (!isPasswordChanged ? "" : "vnp-display-none")}
                layout="vertical"
                requiredMark={false}
            >
                <div className="sign-in-title">
                    <h1> {t('changePasswordTitle')}</h1>
                </div>
                <Form.Item label={t('username')} name="username" rules={[{ required: true, message: t('usernameRequired') as string }]}>
                    <Input />
                </Form.Item>

                <Form.Item label={t('email')} name="email" rules={[{ required: true, message: t('emailRequired') as string }]}>
                    <Input />
                </Form.Item>

                <Form.Item label={t('captcha')} name="captcha" rules={[{ required: true, message: t('captchaRequired') as string }]}>
                    <Row gutter={8}>
                        <Col span={10}>
                            <Input />
                        </Col>
                        <Col span={8} className="captcha">
                            {/* <span className="epr-red-text">Xzy31</span> */}
                            <CaptcharImage captchaData={captchaData} />
                        </Col>
                        <Col span={3}>
                            <CaptcharButton isLoading={isLoading} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item name="captchaId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button block htmlType="submit" className="epr-button-highlight">
                        {t('confirm')}
                    </Button>
                </Form.Item>
            </Form>

            <div className={"sign-in-form vnp-z-index-1 " + (isPasswordChanged ? "noti-show" : "noti-hide")}>
                <div className="sign-in-title">
                    <h1> {t('passwordChangedNoti')}</h1>
                </div>
                <p className="row-margin" style={{ whiteSpace: 'pre' }}>
                    {t('passwordChangedTemplate', { email: form.getFieldValue("email") })}
                </p>
                <p className="row-margin vnp-text-center">
                    <Button className="epr-button-highlight" onClick={handleClose}>
                        <span>Đóng</span>
                    </Button>
                </p>
            </div>
        </div>
    )
}

export default ChangePassword;