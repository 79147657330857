import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

import { Space, Tabs } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { create } from '../postSlice'
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Array2Tree, treeOption, Array2Option } from "../../../util/helper"
import { list as listCategory } from '../../category/categorySlice';
import { list as listKeyword } from '../../keyword/keywordSlice'
import dayjs from 'dayjs'
import type { TabsProps } from 'antd';
import { notification } from 'antd';
import { MediaProvider } from "../../../hooks/useMediaContext";

import Form from "./form"
import './postcreate.css'

interface Props {
}

const PostCreate: React.FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const now = dayjs()

    const category = useAppSelector(state => state.category)
    const keyword = useAppSelector(state => state.keyword)

    const { t, i18n } = useTranslation()

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [keyWordOptions, setKeyWordOptions] = useState<any[]>([])
    const [keyWordOptionsEn, setKeyWordOptionsEN] = useState<any[]>([])

    const onChangeLang = (key: string) => {
        i18n.changeLanguage(key)
    };

    useEffect(() => {
        let pagination = {
            page: 0,
            size: 10,
        }
        dispatch(listCategory(pagination)).unwrap().then(() => {
        }).catch((error) => {
            console.error("[Error] tableList: ", error);
        })
    }, []);

    useEffect(() => {
        if (category?.items) {
            const data = Array2Tree(category?.items)
            const options = data.map((item) => treeOption(item))
            setOptions(options)
        }
    }, [category]);
    useEffect(() => {
        let pagination = {
            page: 0,
            size: 100,
        }
        dispatch(listKeyword(pagination)).unwrap().then(() => {
            console.log('list keyword 1')
        }).catch((error: any) => {
            console.log(error)
        })
    }, []);

    useEffect(() => {
        if (keyword?.items) {
            const option_keyword = Array2Option("vi", keyword?.items)
            setKeyWordOptions(option_keyword)
            const option_keyword_en = Array2Option("en", keyword?.items)
            setKeyWordOptionsEN(option_keyword_en)
        }
    }, [keyword])

    const handlePublish = async (values: any) => {
        values["status"] = "public"
        handleSave(values)
    };
    const handleSave = async (values: any) => {
        let categories: any[] | undefined = []
        console.log("handleSave: values: ", values)
        if (values["category"]) {
            categories = category?.items?.filter((item) => values["category"].includes("" + item.id))
        }
        let keywords: any[] | undefined = []
        if (values["keyword"]) {
            keywords = keyword?.items?.filter((item) => values["keyword"].includes("" + item.id))
        }
        console.log('update categories', categories)
        console.log('update keywords', keywords)

        const formValues = {
            showOnBanner: values.showOnBanner,
            id: values.id,
            slug: values.slug,
            title: values.title,
            excerpt: values.excerpt,
            content: values.content,
            status: values.status,
            commentStatus: values.commentStatus,
            lang: values.lang,
            publicDate: values.publicDate.unix(),
            finishDate: values.finishDate.unix(),
            createdTime: now.unix(),
            updatedTime: now.unix(),
            thumbnail: values.thumbnail,
            thumbnailUrl: values.thumbnailUrl,
            categories: categories,
            keywords: keywords,
        }
        setLoading(true);
        console.log('formValues', formValues)
        dispatch(create(formValues)).unwrap().then(() => {
            setLoading(false)
            api.open({
                message: 'Create successfully',
                description: 'Create article successfully',
                duration: 0,
                type: 'success'
            });
        }).catch((error) => {
            api.open({
                message: 'Failed',
                description: `Create failed ${error}`,
                duration: 0,
                type: 'error'
            })
            setLoading(false)
        })
    };

    const items: TabsProps['items'] = [
        {
            key: 'vi',
            label: 'Tiếng Việt',
            children: <Form
                lang="vi"
                handleSave={handleSave}
                handlePublish={handlePublish}
                options={options}
                opt_keyword={keyWordOptions}
            />,
        },
        // {
        //     key: 'en',
        //     label: 'English',
        //     children: <Form
        //         lang="en"
        //         handleSave={handleSave}
        //         handlePublish={handlePublish}
        //         options={options}
        //         opt_keyword={keyWordOptionsEn}
        //     />,
        // }
    ]

    return (
        <MediaProvider >
            <div className="wrapper">
                <div className="titleBox">
                    <div className="title">
                        <Space>
                            <>
                                <PlusCircleFilled />
                                <span>Thêm bài viết </span>
                            </>
                        </Space>
                    </div>
                </div>
                {contextHolder}
                <Tabs defaultActiveKey="1" items={items} onChange={onChangeLang} />
            </div >
        </MediaProvider >
    )
}

export default PostCreate;