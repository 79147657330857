export const captchaRequest = async (): Promise<any> => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/captcha`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
          })
          .then((response) => response.json())
          .then((responseData: any) => {
            if (!responseData) throw ("empty response")

            const { code, message, data } = responseData
            if (code != 0 && code != 200) {
                throw (message)
            }
            return data
        })
        .catch(error => {
            console.log("CATCH error: ", error)
            throw (error)
        });
  
}