import React, { useState } from "react";
import { Modal, Button, Space, Input, DatePicker, Form, Divider, Select } from 'antd';
import { PlusOutlined, UsergroupAddOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import localeValues from "antd/locale/vi_VN";
import './partner.css'
interface HeaderComment {
    onNew?: (values: any) => void
    onSearch?: (values: any) => void
    onRefresh?: (values: any) => void

}
const { Search, TextArea } = Input

const HeaderPartner: React.FC<HeaderComment> = (props) => {
    const { onNew, onSearch, onRefresh } = props

    const [form] = Form.useForm();


    return (
        <Form className="titlebox_partner" form={form}>
            <Space className="title">
                <UsergroupAddOutlined />
                <span>Đối tác</span>
            </Space>
            <Space className="option-box">
                <Form.Item
                    noStyle
                    name='all'>
                    <Button type="primary" size="middle">Tất cả </Button>
                </Form.Item >
                <Form.Item
                    noStyle
                    name='active'>
                    <Button type="default" size="middle">Hoạt động </Button>
                </Form.Item >
                <Form.Item
                    noStyle
                    name='blockeds'>
                    <Button type="default" size="middle">Tạm khóa</Button>
                </Form.Item >
                <Form.Item
                    noStyle
                    name='search'>
                    <Search onSearch={onSearch} size="middle" prefix={<SearchOutlined />} placeholder="Từ khóa..." />
                </Form.Item >
                <Form.Item
                    noStyle >
                    <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>Thêm mới</Button>
                </Form.Item >
                <Form.Item
                    noStyle>
                    <Button type="primary" icon={<RedoOutlined />} onClick={onRefresh} size="middle" />
                </Form.Item >
            </Space>
        </Form >


    )
}

export default HeaderPartner
